import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';

import { updateProjectCounsel } from '../../redux/modules/Project/operations';
import { isFetchingWithHooks as isTransactionFetchingWithHooks } from '../../redux/modules/Transaction/selectors';
import { validateSelect } from '../../utils/FeatureTypes';
import Button from '../common/Button';
import { Select } from '../formik';

import './ProjectCounselModal.scss';

// import { Link } from 'react-router-dom';

const customStyles = {
  overlay: {
    zIndex: 9,
    backgroundColor: 'rgba(0,0,0,.66)',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    overflowY: 'auto',
    paddingTop: 20,
    WebkitOverflowScrolling: 'touch',
  },
  content: {
    position: 'static',
    maxWidth: '450px',
    minHeight: '450px',
    margin: 'auto',
    padding: 0,
    border: 'none',
    boxShadow: '3px 3px 3px 3px #33373859',
    display: 'flex',
    flexDirection: 'column',
  },
};

const ReviewersModal = ({
  initReviewers = [],
  isOpen,
  onClose,
  projectCounselQuestions,
  projectId,
}) => {
  initReviewers = initReviewers.map(e => {
    if (!e.value) {
      const name = e.name || `${e.first_name} ${e.last_name}`;
      e.value = name;
      e.label = name;
    }
    return e;
  });
  const isFetchingTransaction = useSelector(isTransactionFetchingWithHooks);

  const dispatch = useDispatch();

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div className="reviewers-modal">
        <FontAwesomeIcon
          className="reviewers-modal__exit"
          onClick={onClose}
          icon={['fal', 'times']}
        />
        <h2>Assign Workflow Role</h2>
        <h3>Role updates won't affect already submitted Workflow Tasks.</h3>
        <Formik
          initialValues={{
            counsel_options: projectCounselQuestions.map(e => e.formikValue),
          }}
          onSubmit={({ counsel_options }, actions) => {
            counsel_options.forEach(e => {
              const optionIndex = projectCounselQuestions.findIndex(
                val =>
                  val.counsel_type === e.counsel_type &&
                  val.formikValue.value !== e.value,
              );
              if (optionIndex !== -1) {
                dispatch(
                  updateProjectCounsel(
                    projectCounselQuestions[optionIndex].formikValue,
                    e,
                    projectId,
                  ),
                );
              }
            });
            onClose();
          }}
        >
          {formikProps => {
            return (
              <Form className="reviewers-modal__form">
                <div className="reviewers-modal__body">
                  {projectCounselQuestions.map(
                    (counselQuestion, counselQuestionIndex) => {
                      return (
                        <Field
                          key={`counsel-options-${counselQuestion.id}`}
                          autoComplete="nope"
                          component={Select}
                          feature_type="options"
                          isCounselOptions
                          isRequired
                          name={`counsel_options[${counselQuestionIndex}]`}
                          options={counselQuestion.options}
                          label={counselQuestion.label}
                          validate={validateSelect}
                        />
                      );
                    },
                  )}
                </div>
                <div className="reviewers-modal__buttons">
                  <Button buttonType="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button type="submit" isFetching={isFetchingTransaction}>
                    Assign Workflow Role
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

ReviewersModal.defaultProps = {
  secondaryActionText: 'Close',
};

export default ReviewersModal;
