import React, { useEffect, useState } from 'react';

import get from 'lodash-es/get';
import ContentExplorer from 'box-ui-elements/es/elements/content-explorer';

import { InputHelp, InputStyledTextField } from '../../inputs';
import savviLogo from '../../../assets/images/savvi_logo.svg';

import { Button, Dropdown } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import './ShareLinks.scss';
import { useDispatch, useSelector } from 'react-redux';
import { BoxToken } from '../../../redux/modules/Cabinet/selectors';
import { fetchBoxLinkById } from '../../../redux/modules/Data/operations';
import { IsFetching } from '../../../redux/modules/Data/selectors';
import { CurrentCompany } from '../../../redux/modules/Company/selectors';

export const parseShareLink = function (str) {
  if (typeof str != 'string') {
    return str;
  }
  let ary = str.split('|');
  return ary.map(str => {
    let label = /\[([^\]]*)\]/.exec(str);
    label = label ? label[1] : '';
    let url = /\(([^)]*)\)/.exec(str);
    url = url ? url[1] : '';
    return { label, url };
  });
};

const stringifyShareLinks = function (ary) {
  if (!Array.isArray(ary)) {
    return ary;
  }
  return ary.map(obj => `[${obj.label}](${obj.url})`).join('|');
};

const ShareLinks = ({
  field: { name, onBlur, value },
  form: { errors, setFieldValue, setFieldTouched, status, touched },
  handleFormBlur,
  isDisabled,
  isFixed,
  isRequired,
  label,
  questionHelp,
  ...props // everything that we should pass goes through here classnames etc
}) => {
  const apiError = get(status, name, false);
  const isTouched = get(touched, name, false);
  const error = apiError || (isTouched && get(errors, name, null));

  const [links, setLinks] = useState(parseShareLink(value));

  useEffect(() => {
    if (links.length === 0) {
      setLinks([{ label: '', url: '' }]);
    }
  }, [links]);

  const handleSetLink = (updatedLink, index) => {
    let updatedLinks = links;
    if (!updatedLink) {
      updatedLinks.splice(index, 1);
    } else {
      updatedLinks[index] = {
        ...updatedLink,
      };
    }
    const stringifiedLinks = stringifyShareLinks(updatedLinks);
    setLinks(updatedLinks);
    handleFormBlur({ target: { value: stringifiedLinks } });
    setFieldValue(name, updatedLinks, false);
    setFieldTouched(name, true);
  };

  return (
    <div className="share-links__container">
      {label && (
        <label className="share-links__label" htmlFor={'share_links'}>
          {label}:{!!isRequired && <span className="workbench-form__required">*</span>}
          {questionHelp && <InputHelp text={questionHelp} />}
        </label>
      )}
      {links.map((e, index) => (
        <ShareLink
          error={error}
          handleSetLink={handleSetLink}
          index={index}
          isDisabled={isDisabled || isFixed}
          isRequired={isRequired}
          key={`shared-link-${index}-${e.label}`}
          link={e}
          name={name}
          questionLabel={label}
        />
      ))}
      <Button
        buttonType="link"
        isDisabled={isDisabled || isFixed}
        className="share-links__add"
        onClick={() => {
          setLinks([...links, { label: '', url: '' }]);
        }}
      >
        + Add Share Link
      </Button>
    </div>
  );
};

export const ShareLink = ({
  accountId,
  error,
  index,
  isDisabled,
  isRequired,
  link,
  name,
  handleSetLink,
  questionLabel,
}) => {
  const dispatch = useDispatch();
  const { label, url } = link;

  const boxToken = useSelector(BoxToken);
  const isFetching = useSelector(IsFetching);
  const currentCompany = useSelector(CurrentCompany);

  const boxRootFolderId = get(currentCompany, 'account.box_root_folder_id', '0');

  const [input, setInput] = useState(link);
  const [isActionsShowing, setIsActionsShowing] = useState(false);
  const [isExplorerShowing, setIsExplorerShowing] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState({});

  return (
    <div className="share-links__link">
      <div className="share-links__link-label">
        <InputStyledTextField
          customClass="share-links__input--first"
          error={error && !label && 'Label Required'}
          isDisabled={isDisabled}
          isRequired={isRequired}
          label={`Link #${index + 1} Label`}
          name={`${name}[${index}].label`}
          onBlur={e => handleSetLink(input, index)}
          onChange={e => setInput({ ...input, label: e.target.value })}
          sublabel="e.g. Go to Site"
          value={input.label}
        />
        <InputStyledTextField
          error={error && !url && 'Url Required'}
          inputClassName="share-links__url"
          isDisabled={isDisabled}
          isRequired={isRequired}
          label={`Link #${index + 1} Url`}
          name={`${name}[${index}].url`}
          onBlur={e => handleSetLink(input, index)}
          onChange={e => setInput({ ...input, url: e.target.value })}
          sublabel="e.g. https://www.somesite.com"
          value={input.url}
        />
        <div className="share-links__actions">
          <Button
            buttonType="icon"
            isDisabled={isDisabled}
            onClick={() => setIsActionsShowing(!isActionsShowing)}
          >
            <FontAwesomeIcon icon="ellipsis-v" />
          </Button>
          {isActionsShowing && (
            <Dropdown
              isOpen={isActionsShowing}
              onClose={e => {
                e.stopPropagation();
                setIsActionsShowing(false);
              }}
            >
              <Button
                buttonType="secondary"
                onClick={e => {
                  setIsExplorerShowing(!isExplorerShowing);
                }}
              >
                Share Document Folder
              </Button>
              <Button
                buttonType="secondary"
                isWarning
                onClick={e => {
                  handleSetLink(null, index);
                }}
              >
                Remove Link
              </Button>
            </Dropdown>
          )}
        </div>
        <Modal
          className="share-links__modal"
          isOpen={isExplorerShowing}
          onRequestClose={e => {
            setIsExplorerShowing(false);
          }}
          overlayClassName="share-links__modalOverlay"
        >
          <FontAwesomeIcon
            className="share-links__modalExit"
            onClick={e => {
              setIsExplorerShowing(false);
            }}
            icon={['fal', 'times']}
          />
          <h2 className="share-links__modalHeader">{questionLabel}</h2>
          <h4>Select a folder that you want to get a link for.</h4>
          {boxToken && (
            <ContentExplorer
              autoFocus
              canDelete={false}
              canDownload={false}
              canPreview={false}
              canRename={false}
              logoUrl={savviLogo}
              maxSelectable={1}
              rootFolderId={boxRootFolderId}
              canShare={false}
              onCancel={e => setIsExplorerShowing(false)}
              onNavigate={e => setSelectedFolder(e)}
              onSelect={(e, f, g) => {
                if (e[0].type === 'file') {
                  return false;
                }
                setSelectedFolder(e[0]);
              }}
              token={boxToken}
            />
          )}
          <p className="share-links__modalText">
            Selected Folder: <span>{selectedFolder.name || 'None Selected'}</span>
          </p>
          <div className="share-links__modalActions">
            <Button buttonType="secondary" onClick={() => setIsExplorerShowing(false)}>
              Cancel
            </Button>
            <Button
              buttonType="primary"
              isFetching={isFetching}
              onClick={() => {
                dispatch(fetchBoxLinkById(selectedFolder.id, accountId)).then(e => {
                  handleSetLink(
                    {
                      label: selectedFolder.name,
                      url: get(e, 'url', ''),
                    },
                    index,
                  );
                  setSelectedFolder({});
                  setInput({ label: selectedFolder.name, url: e.url });
                  setIsExplorerShowing(false);
                });
              }}
            >
              Add Folder Link
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ShareLinks;
