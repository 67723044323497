import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import classnames from 'classnames';
import get from 'lodash-es/get';

import FileUploader from '../../FileUploader';
import { InputFeedback, InputHelp, InputSublabel } from '../../inputs';
import './FormFileUploader.scss';

const bem = elementName => `form-file-uploader${elementName ? '__' + elementName : ''}`;

const FormFileUploader = ({
  accountId,
  feature_type_id,
  field: { name, value },
  filename,
  form: { errors, setFieldValue, status, touched, values },
  handleFormBlur = () => {},
  isDisabled,
  isFixed,
  isLocked,
  isOutsideForm,
  isRequired,
  label,
  multiEntry,
  params: { access_code, companyId, transactionId } = {},
  path,
  questionHelp,
  reports_feature_type_id,
  resource_id,
  resource_name,
  resourceTypeName,
  roomId,
  sublabel,
  tags,
}) => {
  const history = useHistory();
  const apiError = get(status, name, false);
  const isTouched = get(touched, name, false);
  let error = apiError || (isTouched && get(errors, name, null));
  if (
    feature_type_id === 1213 &&
    ((values['Investor (Full Legal Name)'] || [])[0]?.resource ===
      'New Investor (Full Legal Name)' ||
      (values['Investor'] || [])[0]?.resource === 'New Investor')
  ) {
    isDisabled = true;
    sublabel = '';
    error = 'Please update the "Investor" input before uploading.';
  } else if (
    (feature_type_id === 1214 || feature_type_id === 1215) &&
    (values['Investor Signatory (Full Legal Name)'] || [])[0]?.resource ===
      'New Investor Signatory (Full Legal Name)'
  ) {
    isDisabled = true;
    sublabel = '';
    error =
      'Please update the "Investor Signatory (Full Legal Name)" input before uploading.';
  }
  return (
    <div
      className={classnames(bem('container'), {
        [bem('container--error')]: !!error,
        [bem('container--disabled')]: !!isDisabled,
      })}
    >
      <label className={bem('label')}>
        {label}
        {!!isRequired && <span className="workbench-form__required">*</span>}
        {questionHelp && <InputHelp text={questionHelp} />}
      </label>
      {/* {isLocked && (
        <p className={bem('value')}>{!!value ? 'File saved' : 'File not saved'}</p>
      )} */}
      <FileUploader
        accountId={accountId}
        account_transaction_id={transactionId}
        access_code={access_code}
        companyId={companyId}
        feature_type_id={feature_type_id}
        filename={filename}
        fileId={value}
        isDisabled={isDisabled || isFixed}
        isLocked={isLocked}
        isOutsideForm={isOutsideForm}
        label={label}
        multiEntry={multiEntry}
        onUpload={value => {
          handleFormBlur({ target: { value } });
          setFieldValue(name, value);
        }}
        path={path}
        push={history.push}
        reports_feature_type_id={reports_feature_type_id}
        resource_id={resource_id}
        resource_name={resource_name}
        roomId={roomId}
        tags={tags}
      />
      <InputSublabel text={sublabel} />
      <InputFeedback error={error} />
    </div>
  );
};

export default withRouter(FormFileUploader);
