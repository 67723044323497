import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Formik } from 'formik';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';

import { deleteIndividual } from '../../redux/modules/Formation/operations';
import { updateProjectCounsel } from '../../redux/modules/Project/operations';
import {
  createFeature,
  createResourceName,
  finalizeTransaction,
  getTransactionQuestionnaire,
  setResourceIds,
  updateResourceName,
  updateTransaction,
} from '../../redux/modules/Transaction/operations';
import {
  CustomDocuments,
  CustomDocumentsFormikValues,
  WorkflowCounselForQuestionnaire,
  getOrderedQuestions,
  getResourceOptions,
  getReviewValues,
  getTransactionById,
  getTransactionValidators,
} from '../../redux/modules/Transaction/selectors';
import { setNotice } from '../../redux/modules/UI/actions';
import WorkbenchForm from './WorkbenchForm';
import SavviLoading from '../../components/SavviLoading';
import handlePath from '../../utils/handlePath';
import { CompanyNameWithHooks } from '../../redux/modules/Formation/selectors';
import dayjs from 'dayjs/esm';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

class WorkbenchFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValidationErrors: [],
      isFetchingInit: true,
      isSafariMobile: false,
      isSubmitting: false,
      isSubmitWaiting: false,
      isSubmitAttempted: false,
    };
  }

  componentDidMount() {
    let isChrome = !!window.chrome;

    if (window.screen.width <= 768 && !isChrome) {
      this.setState({ isSafariMobile: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.questions, this.props.questions)) {
      const {
        params: { transactionId },
        questions,
      } = this.props;
      questions.forEach(question =>
        question.resourceArr.forEach(resource => {
          resource.innerQuestions.forEach(innerQ => {
            if (innerQ.question_default && !innerQ.value) {
              this.props.createFeature({
                value: innerQ.question_default,
                feature_type_id: innerQ.feature_type.id,
                resource_id: resource.resource_id,
                resourceTypeId: resource.resource_type_id,
                transactionId,
              });
            }
          });
        }),
      );
    }
    if (
      this.state.isSubmitWaiting &&
      !this.props.isFetching &&
      !this.props.isFetchingProject
    ) {
      this.setState({ isSubmitWaiting: false }, () => this.handleSubmit());
    }
  }

  handleCounselBlur = (initialCounsel, chosenCounsel) => {
    const { updateProjectCounsel } = this.props;

    if (initialCounsel === chosenCounsel) {
      return;
    }
    updateProjectCounsel(initialCounsel, chosenCounsel);
  };

  handleDelete = (resourceId, resourceName) => {
    const { deleteIndividual, getTransactionQuestionnaire } = this.props;
    const message = `Are you sure you want to remove ${resourceName} from our system?`;

    if (window.confirm(message)) {
      deleteIndividual(resourceId, resourceName).then(e => getTransactionQuestionnaire());
    }
  };

  handleFormBlur = (value, feature_type_id, feature_id, resource_id, resourceTypeId) => {
    const {
      createFeature,
      params: { transactionId },
    } = this.props;
    const { isSubmitAttempted } = this.state;

    return createFeature({
      value,
      feature_type_id,
      feature_id,
      resource_id,
      resourceTypeId,
      transactionId,
    }).then(e => {
      if (isSubmitAttempted) {
        this.handleFormValidation();
      }
      return e;
    });
  };

  handleFormValidation = isShareForm => {
    const { customDocuments, validators, reviewInfo, setNotice } = this.props;

    this.setState({ formValidationErrors: [] });
    let formValidationErrors = [];
    let isValidated = true;

    if (!isEmpty(validators)) {
      if (!Array.isArray(validators)) {
        console.error('validators is not an array', validators);
        setNotice({
          type: 'error',
          message: 'A validation error has occurred, please contact Tech Support',
        });
        return false;
      }

      validators.forEach(validator => {
        if (validator.type !== 'IsNotRequired') {
          let r = RegExp(validator.validator, 'i');
          if (!r.test(JSON.stringify(reviewInfo))) {
            formValidationErrors.push('- ' + validator.message);
            isValidated = false;
          }
        }
      });
    }

    Object.entries(reviewInfo).forEach(([key, value]) => {
      if (
        value.length === 0 &&
        validators.findIndex(
          e =>
            (e.type === 'IsNotRequired' && e.resource === key) ||
            (e.type === 'RegExp' && e.validator.includes(key)),
        ) === -1
      ) {
        formValidationErrors.push(`- Input required for: ${key}.`);
        isValidated = false;
      }
    });

    if (customDocuments.length > 0) {
      let customDocument = customDocuments[0].custom_document;
      if (!customDocument) {
        formValidationErrors.push(
          "- Make sure you've picked a Custom Document folder and file",
        );
        isValidated = false;
      }
      if (customDocument) {
        if (!customDocument.valid) {
          isValidated = false;
          formValidationErrors.push(
            "- You don't have a valid Custom Document saved. Please pick a different document.",
          );
        } else if (
          customDocument.recipients.length > 0 &&
          customDocument.recipients.findIndex(
            e => Object.values(e).findIndex(innerE => !innerE) !== -1,
          ) !== -1
        ) {
          isValidated = false;
          formValidationErrors.push(
            '- Please fill out all recipient fields for Custom Documents.',
          );
        }
      }
    }
    if (!isValidated) {
      if (isShareForm) {
        // formValidationErrors.unshift(
        //   "- Selecting 'finalize on submission' requires you to handle form validation:",
        // );
        setNotice({
          type: 'error',
          message:
            "Selecting 'finalize on submission' requires you to fill out all required fields.",
        });
      }
      this.setState({ formValidationErrors });
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const {
      isFetching,
      isModal,
      isPortalView,
      finalizeTransaction,
      params: { companyId, moduleId, projectId, transactionId },
      push,
      reviewInfo,
      setNotice,
      transaction,
      updateTransaction,
    } = this.props;

    if (isFetching) {
      return this.setState({ isSubmitWaiting: true });
    }

    if (this.handleFormValidation()) {
      this.setState({ isSubmitAttempted: false, isSubmitting: true });
      if (transaction.template.is_assessment) {
        const body = {
          finalize_snapshot: {
            ...reviewInfo,
            finalized_at: dayjs().format('L'),
          },
        };

        finalizeTransaction(body).then(
          e => {
            this.setState({ isSubmitting: false });
            push(
              handlePath(
                {
                  pathname: `/workbench/${moduleId}/${projectId}/${transactionId}/${
                    !!isPortalView || !!isModal ? 'portal' : 'overview'
                  }`,
                  state: { isFinalized: true },
                },
                companyId,
              ),
            );
          },
          error => this.setState({ isSubmitting: false }),
        );
      } else {
        updateTransaction({ validated_questionnaire: 1 }).then(
          e => {
            setNotice('Form Completed');
            this.setState({ isSubmitting: false });
            if (isPortalView) {
              document
                .querySelector('.portalTaskModal__container')
                .scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }
          },
          error => this.setState({ isSubmitting: false }),
        );
      }
    } else {
      this.setState({ isSubmitAttempted: true });
    }
  };

  setResourceIds = async (resourceTypeName, resourceIds) => {
    return await this.props
      .setResourceIds({ [resourceTypeName]: resourceIds })
      .then(e => {
        if (this.state.isSubmitAttempted) {
          this.handleFormValidation();
        }
        return e;
      });
  };

  handleResourceName = async (body, resourceId) => {
    const { createResourceName, params, updateResourceName } = this.props;
    const { isSubmitAttempted } = this.state;
    body.transactionId = params.transactionId;
    const resourceAction = resourceId ? updateResourceName : createResourceName;
    return await resourceAction(body, resourceId).then(e => {
      if (isSubmitAttempted) {
        this.handleFormValidation();
      }
      return e;
    });
  };

  render() {
    const {
      accountName,
      customDocuments,
      customDocumentsFormikValues,
      formikValues,
      isReopeningTimeout,
      isSharingForm,
      isFetching,
      isModal,
      params,
      project,
      questions,
      resources,
      setIsSharingForm,
      setNotice,
      transaction,
      workflowCounselQuestions,
      projectTemplateId,
    } = this.props;

    const { formValidationErrors, isSafariMobile, isSubmitAttempted, isSubmitting } =
      this.state;

    let sharedFormDescription =
      get(transaction, 'template.description', transaction.description) ||
      `${
        accountName
          ? accountName + ' has requested your input'
          : 'Your input has been requested'
      } for the following questions.`;

    const currentTransactionIndex = get(project, 'transactions', []).findIndex(
      e => e.id + '' === params.transactionId,
    );

    let isInitQuestionnaire = false;
    if (currentTransactionIndex === 0 && !!transaction.open) {
      isInitQuestionnaire = true;
    }
    let initVals = { ...formikValues };
    if (!isEmpty(customDocumentsFormikValues)) {
      initVals = { ...initVals, custom_documents: customDocumentsFormikValues };
    }
    if (!isEmpty(workflowCounselQuestions) && isInitQuestionnaire) {
      initVals = {
        ...initVals,
        counsel_options: workflowCounselQuestions.map(e => e.formikValue),
      };
    }

    return questions.length === 0 ? (
      <div className="workbench__formLoader">
        <span>Loading...</span>
        <SavviLoading />
      </div>
    ) : (
      <Formik
        // enableReinitialize
        initialValues={initVals}
        onSubmit={this.handleSubmit}
      >
        {formikProps => (
          <WorkbenchForm
            {...formikProps}
            customDocuments={customDocuments}
            formValidationErrors={formValidationErrors}
            handleCounselBlur={this.handleCounselBlur}
            handleDelete={this.handleDelete}
            handleFormBlur={this.handleFormBlur}
            handleFormValidation={this.handleFormValidation}
            handleResourceName={this.handleResourceName}
            setResourceIds={this.setResourceIds}
            initVals={initVals}
            isAssessment={!!transaction.template?.is_assessment}
            isFetching={isFetching}
            isReopeningTimeout={isReopeningTimeout}
            isSafariMobile={isSafariMobile}
            isSharingForm={isSharingForm}
            isInitQuestionnaire={isInitQuestionnaire}
            isModal={isModal}
            isSubmitAttempted={isSubmitAttempted}
            isSubmitting={isSubmitting}
            params={params}
            projectTemplateId={projectTemplateId}
            questions={questions}
            resources={resources}
            setNotice={setNotice}
            setSubmitAttempted={val => this.setState({ isSubmitAttempted: val })}
            setIsSharingForm={setIsSharingForm}
            sharedFormDescription={sharedFormDescription}
            transactionLabel={transaction.label}
            workflowCounselQuestions={workflowCounselQuestions}
          />
        )}
      </Formik>
    );
  }
}

WorkbenchFormContainer.propTypes = {
  formValues: PropTypes.object.isRequired,
  formikValues: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  match: PropTypes.object,
};
WorkbenchFormContainer.defaultProps = {
  formValues: {},
  formikValues: {},
  isFetching: false,
  getFormInfo: () => {},
};

const mapStateToProps = (state, ownProps) => {
  const { Project, Transaction } = state;
  const {
    params: { projectId, transactionId },
  } = ownProps;
  return {
    accountName: CompanyNameWithHooks(state),
    customDocuments: CustomDocuments(Transaction, transactionId),
    customDocumentsFormikValues: CustomDocumentsFormikValues(Transaction, transactionId),
    validators: getTransactionValidators(Transaction, transactionId),
    isFetching: Transaction.isFetching,
    isFetchingProject: Project.isFetching,
    questions: getOrderedQuestions(Transaction, transactionId),
    resources: getResourceOptions(Transaction, transactionId),
    reviewInfo: getReviewValues(Transaction, transactionId),
    transaction: getTransactionById(Transaction, transactionId),
    workflowCounselQuestions: WorkflowCounselForQuestionnaire(
      state,
      transactionId,
      projectId,
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    params: { projectId, transactionId },
  } = ownProps;
  return {
    createFeature: meta => dispatch(createFeature(meta)),
    createResourceName: body => dispatch(createResourceName(body)),
    deleteIndividual: (id, resourceName) => dispatch(deleteIndividual(id, resourceName)),
    finalizeTransaction: body =>
      dispatch(finalizeTransaction(transactionId, projectId, body)),
    setNotice: obj => dispatch(setNotice(obj)),
    setResourceIds: body => dispatch(setResourceIds(transactionId, body)),
    getTransactionQuestionnaire: () =>
      dispatch(getTransactionQuestionnaire(transactionId)),
    updateProjectCounsel: (initialCounsel, chosenCounsel) =>
      dispatch(updateProjectCounsel(initialCounsel, chosenCounsel, projectId)),
    updateResourceName: (body, resourceId) =>
      dispatch(updateResourceName(body, resourceId)),
    updateTransaction: body => dispatch(updateTransaction(transactionId, body)),
  };
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(WorkbenchFormContainer);
