import React, { useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';

import './RoomUserHistoryModal.scss';
import ReactModal from 'react-modal';
import SortDropdown from '../SortDropdown';
import { Button, TooltipAction } from '../common';
import { useDispatch, useSelector } from 'react-redux';
import { setNotice } from '../../redux/modules/UI/actions';
import { addRoomCode } from '../../redux/modules/Data/operations';
import dayjs from 'dayjs/esm';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { IsFetching } from '../../redux/modules/Data/selectors';

dayjs.extend(localizedFormat);

const defaultSort = (a, b) => {
  let aDate = new Date(a.created_at);
  let bDate = new Date(b.created_at);

  return bDate - aDate;
};

const historySortOptions = {
  document_views: {
    label: 'Document Views',
    slug: 'document_views',
    iconType: '1-9',
    sortFunc: (a, b) => {
      if (b.history.action !== 'view' && a.history.action === 'view') {
        return -1;
      } else if (b.history.action === 'view' && a.history.action !== 'view') {
        return 1;
      }
      return defaultSort(a, b);
    },
  },
  document_downloads: {
    label: 'Document Downloads',
    slug: 'document_downloads',
    iconType: '1-9',
    sortFunc: (a, b) => {
      if (b.history.action !== 'download' && a.history.action === 'download') {
        return -1;
      } else if (b.history.action === 'download' && a.history.action !== 'download') {
        return 1;
      }
      return defaultSort(a, b);
    },
  },
  room_visits: {
    label: 'Room Visits',
    slug: 'room_visits',
    iconType: '1-9',
    sortFunc: (a, b) => {
      if (b.history.action !== 'visit' && a.history.action === 'visit') {
        return -1;
      } else if (b.history.action === 'visit' && a.history.action !== 'visit') {
        return 1;
      }
      return defaultSort(a, b);
    },
  },
  form_visits: {
    label: 'Form Visits',
    slug: 'form_visits',
    iconType: '1-9',
    sortFunc: (a, b) => {
      if (b.history.action !== 'visit' && a.history.action === 'visit') {
        return -1;
      } else if (b.history.action === 'form_visit' && a.history.action !== 'form_visit') {
        return 1;
      }
      return defaultSort(a, b);
    },
  },
  date: {
    label: 'Date',
    slug: 'date',
    iconType: 'wide-short',
    sortFunc: defaultSort,
  },
  none: {
    label: 'Default (latest)',
    slug: 'none',
    sortFunc: (a, b) => defaultSort(a, b),
  },
};

const RoomUserHistoryModal = ({
  account_id,
  handleClose,
  isOpen,
  params = {},
  roomId,
  roomUserHistory,
}) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(IsFetching);
  const {
    codes = {},
    docInteractions,
    email,
    history = [],
    name,
    timesVisited,
  } = roomUserHistory;
  let header = name || email;

  const [isSortReversed, setSortIsDesc] = useState(true);
  const [sortOption, setSortSlug] = useState(historySortOptions['none']);
  const { slug, sortFunc } = sortOption;

  const sortedHistory = useMemo(() => {
    let updatedHistory = history.sort(sortFunc);
    if (!isSortReversed) {
      updatedHistory = updatedHistory.reverse();
    }
    return updatedHistory;
  }, [history, isSortReversed, sortFunc]);

  const handleSortHistory = selectedOption => {
    let updatedSortIsDesc = true;
    if (selectedOption.slug === slug) {
      updatedSortIsDesc = !isSortReversed;
    }
    setSortIsDesc(updatedSortIsDesc);
    setSortSlug(selectedOption);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      onAfterClose={ev => {
        document.body.classList.remove('ReactModal__Body--open');
      }}
      className="RoomUserHistoryModal"
      overlayClassName="RoomUserHistoryModal__overlay"
    >
      <div className="RoomUserHistoryModal__headerRow">
        <div className="RoomUserHistoryModal__header">
          <h2>{header}</h2>
          {!!name && !!email && <h3>{email}</h3>}
        </div>
        <FontAwesomeIcon
          className="RoomUserHistoryModal__exit"
          onClick={handleClose}
          icon="times"
        />
      </div>
      <div className="RoomUserHistoryModal__sectionRow">
        <div className="RoomUserHistoryModal__rowItem">
          <h4>Total Visits</h4>
          <p>{timesVisited}</p>
        </div>
        <div className="RoomUserHistoryModal__rowItem">
          <h4>
            Document Interactions
            <TooltipAction text="Anytime a user downloads or views a document while in this Room is considered an interaction." />
          </h4>
          <p>{docInteractions}</p>
        </div>
        <div className="RoomUserHistoryModal__rowActions">
          {codes.length > 0 && (
            <Button
              buttonType="icon"
              size="sm"
              disabledTooltip="Share Link before you can copy."
              tooltip="Copy Guest Link"
              onClick={() => {
                const { REACT_APP_BASEURL } = process.env;
                navigator.clipboard
                  .writeText(`${REACT_APP_BASEURL}/data-room/${codes[0].code}`)
                  .then(
                    () => dispatch(setNotice('Link Copied!')),
                    err => console.error('Could not copy text: ', err),
                  );
              }}
            >
              <FontAwesomeIcon icon={['fal', 'copy']} />
            </Button>
          )}
          <Button
            buttonType="primary"
            size="sm"
            isDisabled={isFetching}
            isFetching={isFetching}
            onClick={() =>
              dispatch(
                addRoomCode(
                  { identifier: { type: 'email', value: email } },
                  roomId,
                  account_id,
                ),
              ).then(() => dispatch(setNotice('Link Emailed')))
            }
          >
            Share Room Link
          </Button>
        </div>
      </div>
      <div className="RoomUserHistoryModal__subsectionHeader">
        <p>Room History</p>
        <div className="RoomUserHistoryModal__subsectionDivider" />
        <SortDropdown
          isDisabled={sortedHistory.length === 0}
          isSortReversed={isSortReversed}
          onSelect={handleSortHistory}
          sortOption={sortOption}
          sortOptions={historySortOptions}
        />
      </div>
      <div className="RoomUserHistoryModal__content--inner">
        {isEmpty(sortedHistory) && (
          <h4 className="RoomUserHistoryModal__history-empty">
            No history found for {header}.
          </h4>
        )}
        {sortedHistory.map((item, ind) => {
          const {
            created_at,
            id,
            history: { action, customer_label, file_label = 'Document' } = {},
          } = item;
          let itemIcon =
            (action === 'view' && 'magnifying-glass') ||
            (action === 'download' && 'file-arrow-down') ||
            (action === 'visit_form' && 'file-alt') ||
            'clock';
          const mainLabel = customer_label || file_label;
          const subLabel = !!customer_label ? file_label : '';
          const itemLabel =
            (action === 'view' && `Viewed ${mainLabel}`) ||
            (action === 'download' && `Downloaded ${mainLabel}`) ||
            (action === 'visit_form' && 'Visited Room Standing Form') ||
            'Visited Room';

          return (
            <div
              className="RoomUserHistoryModal__card RoomUserHistoryModal__history"
              key={`history-card-${id}-${ind}`}
            >
              <div className="RoomUserHistoryModal__pre">
                <div className="RoomUserHistoryModal__icon">
                  <FontAwesomeIcon icon={['fal', itemIcon]} />
                </div>
                <div
                  className={classnames('RoomUserHistoryModal__line', {
                    'RoomUserHistoryModal__line--isLast': ind === history.length - 1,
                  })}
                />
              </div>
              <div className="RoomUserHistoryModal__body">
                <h4>
                  {itemLabel}
                  {/* {box_file_id && (
                    <>
                      {`  (`}
                      <Link to={handlePath(box_file_id, params.companyId)}>
                        View Document
                      </Link>
                      )
                    </>
                  )} */}
                </h4>
                {!!customer_label && <p>{subLabel}</p>}
                <p>{dayjs(created_at).format('LLL')}</p>
              </div>
            </div>
          );
        })}
      </div>
    </ReactModal>
  );
};

export default RoomUserHistoryModal;
