import React from 'react';
import { useHistory } from 'react-router';

import classnames from 'classnames';

import Button from '../common/Button';
import UseWindowSize from '../../utils/UseWindowSize';

import './WorkbenchActions.scss';
import handlePath from '../../utils/handlePath';

const WorkbenchActions = props => {
  const windowSize = UseWindowSize();
  const history = useHistory();
  const buttonSize = windowSize.width > 768 ? 'md' : 'sm';

  if (props.isSharingForm) {
    return <SharingFormButtons {...props} buttonSize={buttonSize} />;
  } else if (props.isForm) {
    return <FormButtons {...props} history={history} buttonSize={buttonSize} />;
  } else if (props.isFinalize) {
    return <FinalizeButtons {...props} buttonSize={buttonSize} history={history} />;
  } else {
    return <TrackingButtons {...props} buttonSize={buttonSize} history={history} />;
  }
};

const SharingFormButtons = ({
  buttonSize,
  onPrimaryClick,
  onSecondaryClick,
  isFetching,
}) => (
  <div className={classnames('workbench-actions')}>
    <Button size={buttonSize} buttonType="secondary" onClick={onSecondaryClick}>
      Cancel
    </Button>
    <Button size={buttonSize} onClick={onPrimaryClick} isFetching={isFetching}>
      Create Shared Form
    </Button>
  </div>
);

const FormButtons = ({
  buttonSize,
  history,
  isAssessment,
  isDisabled,
  isFetching,
  isInitQuestionnaire,
  isModal,
  params,
  projectTemplateId,
}) => (
  <div className={classnames('workbench-actions')}>
    {isInitQuestionnaire && (
      <Button
        size={buttonSize}
        buttonType="secondary"
        isDisabled={isDisabled}
        onClick={() => history.push(handlePath('/home', params.companyId))}
      >
        Save & Exit
      </Button>
    )}
    {!isInitQuestionnaire && (
      <Button
        size={buttonSize}
        buttonType="secondary"
        isDisabled={isDisabled}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
    )}
    <Button
      size={buttonSize}
      type="submit"
      isDisabled={isDisabled}
      isFetching={isFetching}
    >
      {(projectTemplateId === 179 && 'Add Trustee(s)') ||
        (isModal && 'Add Investor(s)') ||
        (isAssessment && 'Generate Tasks') ||
        'Review'}
    </Button>
  </div>
);

const TrackingButtons = ({
  buttonSize,
  handleNextTaskAction,
  handleSecondaryAction,
  isFetching,
  isDisabled,
  isOverview,
  isSecondaryHiding,
  primaryActionText,
  secondaryActionText,
}) => (
  // <div className={classnames('workbench-actions workbench-actions--single')}>
  <div
    className={classnames('workbench-actions', {
      'workbench-actions--single': !isOverview || isSecondaryHiding,
    })}
  >
    {isOverview && !isSecondaryHiding && (
      <Button
        size={buttonSize}
        buttonType="secondary"
        onClick={handleSecondaryAction}
        isFetching={isFetching}
        isDisabled={isFetching}
      >
        {secondaryActionText}
      </Button>
    )}
    <Button
      size={buttonSize}
      type="submit"
      onClick={handleNextTaskAction}
      isFetching={isFetching}
      isDisabled={isFetching || isDisabled}
    >
      {primaryActionText}
    </Button>
  </div>
);

const FinalizeButtons = ({
  buttonSize,
  handleFinalize,
  history,
  isAssessment,
  isDev,
  isDisabled,
  isFetching,
  params: { companyId, moduleId, projectId, transactionId },
}) => (
  <div className={classnames('workbench-actions')}>
    <Button
      size={buttonSize}
      buttonType="secondary"
      onClick={() =>
        history.replace(
          handlePath(
            `/workbench/${moduleId}/${projectId}/${transactionId}/questionnaire${
              isDev ? '-dev' : ''
            }`,
            companyId,
          ),
        )
      }
    >
      Previous
    </Button>
    <Button
      size={buttonSize}
      onClick={handleFinalize}
      isFetching={isFetching}
      isDisabled={isFetching || isDisabled}
    >
      {isAssessment ? 'Generate Tasks' : 'Finalize'}
    </Button>
  </div>
);

WorkbenchActions.defaultProps = {
  isAuthenticated: false,
  next: 'loading',
};

export default WorkbenchActions;
