import React, { Component, createRef } from 'react';
import { components } from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import toLower from 'lodash-es/toLower';
import trim from 'lodash-es/trim';

import { InputSelect, InputStyledTextField } from '../../inputs';
import { Button, DropdownAction } from '../../common';

import './ResourceSelect.scss';

class ResourceSelect extends Component {
  constructor(props) {
    super(props);
    this.textInput = createRef();
    this.state = {
      initVal: this.props.field.value,
      changeOnBlur: false,
      isOpen: false,
      isEditable: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      field: { name, value: fieldValue },
      form: { errors, setFieldValue, setFieldError, touched, submitCount },
      isLabel,
      isNewResource,
      handleResourceName,
      options,
      setLabel,
    } = this.props;
    const { changeOnBlur, inputValue } = this.state;
    const isTouched = get(touched, name, false);
    const error = isTouched && get(errors, name, null);
    if (
      !error &&
      !!fieldValue &&
      (fieldValue?.value || fieldValue).includes('New Invest') &&
      submitCount > 0
    ) {
      setFieldError(name, 'Please replace input with full legal name.');
    }
    if (isNewResource && changeOnBlur && !isEmpty(inputValue)) {
      const optionIndex = options.findIndex(
        option => toLower(option.label) === toLower(inputValue),
      );
      let value = this.createOption(inputValue);
      if (optionIndex !== -1) {
        value = options[optionIndex];
      }
      handleResourceName(value);
      setFieldValue(name, value);
      if (isLabel) {
        setLabel(inputValue);
      }
      this.setState({ changeOnBlur: false });
    } else if (changeOnBlur) {
      this.setState({ changeOnBlur: false });
    }
  }

  onToggleClick = e => {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  };

  createOption = (label, __isNew__) => ({
    label,
    value: label.toLowerCase(),
    __isNew__,
  });

  Option = props => {
    return (
      <div
        className={`select-resource__option${
          props.innerProps.isFocused ? ' select-resource__option--focus' : ''
        }`}
      >
        <components.Option {...props} />
        {!!props.data.is_draft && (
          <h4 className="select-resource__option-sublabel">Created in Shared Form</h4>
        )}
        {!props.data.finalized && !props.data.__isNew__ && !props.data.is_state && (
          <Button
            onClick={() =>
              this.props.handleDelete(props.data.resourceId, props.data.label)
            }
            buttonType="icon"
            isWarning
            size="sm"
            tooltip="Remove from List"
            tooltipAlign="right"
            type="button"
          >
            <FontAwesomeIcon icon={['fal', 'trash-alt']} />
          </Button>
        )}
      </div>
    );
  };

  render() {
    const {
      field: { name, value: fieldValue, onChange, onBlur },
      form: { errors, setFieldTouched, setFieldValue, status, touched },
      handleFormBlur,
      handleRemoveResource,
      handleResourceName,
      isCompany,
      isDisabled,
      isDraftResource,
      isLabel,
      isNewResource,
      isOutsideForm,
      multi,
      multiEntry,
      options,
      resourceTypeName,
      resourceTypeCustomLabel,
      setLabel,
      isFixed,
      ...props
    } = this.props; // everything that we should pass goes through here classnames etc
    const isMulti = !!multiEntry || multi;

    const apiError = get(status, name, false);
    const isTouched = get(touched, name, false);
    const error = apiError || (isTouched && get(errors, name, null));

    let updatedOptions = options;
    if (!Array.isArray(options) && !options[0].value) {
      updatedOptions = options.split(',').map(resource => ({
        value: resource,
        label: resource,
      }));
    }

    let updatedVal = fieldValue;
    if (updatedVal && typeof updatedVal === 'string') {
      if (fieldValue.indexOf('|')) {
        updatedVal = updatedVal.split('|').map(e => this.createOption(e));
      } else {
        updatedVal = this.createOption(fieldValue);
      }
    } else if (Array.isArray(updatedVal) && typeof updatedVal[0] === 'string') {
      updatedVal = updatedVal.map(e => this.createOption(e));
      setFieldValue(name, updatedVal);
    }

    const isInputFixed = isFixed || (!isDraftResource && !this.state.isEditable);

    return (
      <>
        {!isNewResource && (
          <div className="select-resource">
            <InputStyledTextField
              error={error}
              ref={this.textInput}
              isDisabled={isDisabled}
              isFixed={isInputFixed}
              name={name}
              onBlur={(e = {}) => {
                const { target: { value } = {} } = e;
                if (isEmpty(trim(value))) {
                  setFieldValue(name, this.state.initVal);
                  this.setState({ isEditable: false });
                  return;
                }
                if (isLabel) {
                  setLabel(value);
                }
                onBlur(e);
                // second param used as 'isEditingName' in AddResourceModal.js
                handleResourceName(value, true);
                this.setState({
                  isEditable: false,
                  initVal: value,
                });
              }}
              onChange={onChange}
              value={get(fieldValue, 'value', fieldValue)}
              {...props}
            />
            {!isFixed && !isDisabled && !isDraftResource && (
              <DropdownAction className="select-resource__dropdown">
                <Button
                  buttonType="secondary"
                  size="sm"
                  isDisabled={isOutsideForm && !isDraftResource}
                  onClick={() =>
                    this.setState({ isEditable: true }, () =>
                      this.textInput.current.focus(),
                    )
                  }
                >
                  Edit Saved Name
                </Button>
                <Button
                  buttonType="secondary"
                  size="sm"
                  isDisabled={isOutsideForm}
                  onClick={handleRemoveResource}
                >
                  Remove {resourceTypeCustomLabel}
                </Button>
              </DropdownAction>
            )}
          </div>
        )}
        {isNewResource && (
          <InputSelect
            components={{ Option: this.Option }}
            error={error}
            isDisabled={isDisabled}
            isFixed={isFixed}
            isMulti={isMulti}
            name={name}
            onBlur={(e, action) => {
              setFieldTouched(name, true);
            }}
            onChange={(value, { action }) => {
              if (action === 'clear' && isEmpty(value) && isEmpty(updatedVal)) {
                return;
              }
              setFieldValue(name, value);
              return handleResourceName(value);
            }}
            onInputChange={(inputValue, { action }) => {
              if (action === 'input-blur') {
                this.setState({ changeOnBlur: true });
              } else if (action !== 'menu-close') {
                this.setState({ inputValue });
              }
            }}
            value={updatedVal}
            options={updatedOptions}
            {...props}
          />
        )}
      </>
    );
  }
}

ResourceSelect.defaultProps = {
  handleChangeResource: () => {},
  handleFormBlur: () => {},
  handleResourceName: () => {},
  options: [],
};

export default ResourceSelect;
