import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import Button from '../../components/common/Button';
import { InputStyledTextField } from '../../components/inputs';
import {
  createDataRoomLink,
  getDataRoomLink,
  updateDataRoomLink,
} from '../../redux/modules/Data/operations';
import { setNotice } from '../../redux/modules/UI/actions';

import './ShareDataRoomModal.scss';

const shouldOpenModal = locationPath => /\bshare-data-room\b/.test(locationPath);

class ShareDataRoomModal extends Component {
  componentDidMount() {
    this.props.getLink();
  }

  copyToClipboard = e => {
    if (document.queryCommandSupported('copy')) {
      const el = document.createElement('textarea');
      el.value = this.props.link;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.props.setNotice('Link Copied!');
    }
  };

  render() {
    const {
      createLink,
      expires,
      isFetching,
      history: { goBack },
      link,
      location: { pathname },
      updateLink,
    } = this.props;
    const customStyles = {
      overlay: {
        zIndex: 9,
        backgroundColor: 'rgba(0,0,0,.66)',
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        display: 'flex',
      },
      content: {
        position: 'static',
        maxWidth: '80%',
        maxHeight: '80%',
        margin: 'auto',
      },
    };
    const isCopyable = document.queryCommandSupported('copy');

    return (
      <Modal
        isOpen={shouldOpenModal(pathname)}
        onRequestClose={goBack}
        style={customStyles}
      >
        <div className="data-room--modal">
          <div className="data-room--card">
            <h2>Generate File Cabinet Link</h2>
            <p>
              Generate a link to share your documents via Box. If you generate a new link
              the previous link will be disabled. Links expire after 3 months.
            </p>
            <p>
              System emails new link to your account email address when new link is
              generated.
            </p>
            {isFetching && (
              <FontAwesomeIcon icon="spinner" spin className="data-room__fetching" />
            )}
            {!link && !isFetching && <Button onClick={createLink}>Generate Link</Button>}
            {link && !isFetching && (
              <div className="data-room__link">
                <InputStyledTextField
                  value={link}
                  icon={['fal', 'clipboard']}
                  isCopyable={isCopyable}
                  handleOnCopyClick={this.copyToClipboard}
                  isDisabled
                  label="File Cabinet link:*"
                  sublabel={`*link expires on ${moment(expires).format('DD MMM YYYY')}`}
                />
                <Button onClick={updateLink} size="sm">
                  Create New Link
                </Button>
                <Button onClick={createLink} size="sm">
                  Extend Link
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ Data }) => ({
  isFetching: Data.isFetching,
  link: Data.dataRoomLink.url,
  expires: Data.dataRoomLink.expires,
});

const mapDispatchToProps = dispatch => ({
  getLink: () => dispatch(getDataRoomLink()),
  createLink: () => dispatch(createDataRoomLink()),
  updateLink: () => dispatch(updateDataRoomLink()),
  setNotice: message => dispatch(setNotice(message)),
});

ShareDataRoomModal.propTypes = {
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string, //TODO: clean up Review PropTypes
            value: PropTypes.string,
          }),
        ),
      ]),
    }),
  ),
  title: PropTypes.string,
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(ShareDataRoomModal);
