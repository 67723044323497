import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getAccountProject } from '../../redux/modules/Project/operations';
import { AccountProject } from '../../redux/modules/Project/selectors';
import {
  createTransaction,
  getTransaction,
  getTransactionQuestionnaire,
} from '../../redux/modules/Transaction/operations';
import { getFormikValues } from '../../redux/modules/Transaction/selectors';
import handlePath from '../../utils/handlePath';
import WorkbenchForm from '../../views/WorkbenchForm';
import SavviLoading from '../SavviLoading';

import './PortalActionFormModal.scss';

const PortalActionForm = ({ params }) => {
  const { companyId, moduleId, projectId, transactionId } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { replace, push } = history;
  const location = useLocation();
  const { pathname } = location;
  const project = useSelector(State => AccountProject(State, projectId));
  const {
    template: { actions = [], id: projectTemplateId },
    transactions,
  } = project;
  const { label, id: templateId } = actions[0] || {};

  const startedActionIndex = transactions.findIndex(
    e => !!e.open && e.template.id === templateId,
  );

  const { id } = transactions[startedActionIndex] || {};
  const isIncorrectTransactionId = Number(id) !== Number(transactionId);
  const isCorrectTransactionId = Number(id) === Number(transactionId);

  const formikValues = useSelector(State =>
    getFormikValues(State.Transaction, transactionId),
  );
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (pathname.includes('/add') && !isMounted) {
      setIsMounted(true);
      if (!id) {
        dispatch(createTransaction(projectId, templateId)).then(
          payload => {
            dispatch(getTransactionQuestionnaire(payload.id));
            // dispatch(getTransaction(payload.id));
            dispatch(getAccountProject(projectId));
            replace(
              handlePath(
                `/workbench/${moduleId}/${projectId}/${payload.id}/add`,
                companyId,
              ),
            );
          },
          error =>
            replace(
              handlePath(`/workbench/${moduleId}/${projectId}/0/initialize`, companyId),
            ),
        );
      } else {
        dispatch(getTransaction(id));
        dispatch(getTransactionQuestionnaire(id));
        if (isIncorrectTransactionId) {
          replace(handlePath(`/workbench/${moduleId}/${projectId}/${id}/add`, companyId));
        }
      }
    }
  }, [
    dispatch,
    pathname,
    id,
    isIncorrectTransactionId,
    isMounted,
    projectId,
    replace,
    templateId,
    moduleId,
    companyId,
  ]);

  const onClose = () => {
    push(
      handlePath(
        `/workbench/${moduleId}/${projectId}/${transactions[0].id}/portal`,
        companyId,
      ),
    );
  };

  return (
    <Modal
      isOpen={pathname.includes('/add')}
      onRequestClose={onClose}
      onAfterClose={ev => {
        document.body.classList.remove('ReactModal__Body--open');
      }}
      contentLabel={'Savvi Terms of Service'}
      className="PortalActionFormModal"
      overlayClassName="PortalActionFormModal__overlay"
    >
      <div className="PortalActionFormModal__header">
        <h1>{label}</h1>
        <FontAwesomeIcon
          className="PortalActionFormModal__exit"
          onClick={onClose}
          icon="times"
        />
      </div>
      {isEmpty(formikValues) && isIncorrectTransactionId && <SavviLoading />}
      {!isEmpty(formikValues) && isCorrectTransactionId && (
        <WorkbenchForm
          formikValues={formikValues}
          params={params}
          push={push}
          isModal
          project={project}
          projectTemplateId={projectTemplateId}
        />
      )}
    </Modal>
  );
};

export default PortalActionForm;
