import { get } from 'lodash-es';
import { defaultMemoize } from 'reselect';

export const getNotices = defaultMemoize(state => get(state, `notices`, []));

export const isUserDropdownOpen = defaultMemoize(state =>
  get(state, 'UI.isUserDropdownOpen', false),
);

export const SearchInput = defaultMemoize(state => get(state, 'UI.search', ''));
export const IsSidebarCollapsed = defaultMemoize(state =>
  get(state, 'UI.isSidebarCollapsed', false),
);

export const BrandMeta = defaultMemoize(state => {
  let brandMeta = get(state, 'UI.brandMeta', {});
  if (
    !brandMeta.web_base_url ||
    brandMeta.web_base_url === 'https://app.example.com' ||
    window.location.href.includes('localhost')
  ) {
    brandMeta.web_base_url = process.env.REACT_APP_BASEURL;
  }
  return brandMeta;
});
