import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { InputStyledTextField } from '../../components/inputs';
import { requestVerificationEmail } from '../../redux/modules/User/operations';
import { Button } from '../../components/common';
import { validateEmail } from '../../utils/FeatureTypes';
import { Link } from 'react-router-dom';
import { setNotice } from '../../redux/modules/UI/actions';

const ForgotPassword = ({ code, location: { state: { email = '' } = {} } }) => {
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const [error, setError] = useState('');
  const [emailInput, setEmailInput] = useState(email);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    if (!validateField(emailInput)) {
      setIsSubmitting(true);
      dispatch(
        requestVerificationEmail({
          identifier: {
            type: 'email',
            value: emailInput,
          },
          template: 'reset-password',
        }),
      ).then(
        e => {
          setIsSubmitting(false);
          dispatch(
            setNotice(
              "If there's a Savvi account linked to this email address, you will receive reset instructions in your inbox.",
            ),
          );
          goBack();
        },
        error => setIsSubmitting(false),
      );
    }
  };

  const validateField = value => {
    const fieldError = validateEmail(value);
    setError(fieldError);
    return fieldError;
  };

  return (
    <div className="page__forgot-password" style={{ paddingTop: 40 }}>
      <div className="inner">
        <form className="form form--forgot-password">
          <h2 className="form__heading">
            Can't remember your password?
            <small className="form__sub-heading">
              Enter your email address below and we'll send you a password reset.
            </small>
          </h2>
          <InputStyledTextField
            autoComplete="username"
            error={error}
            inputClassName="-js-forgot-password__input-email"
            label={'Your Email'}
            name={'email'}
            value={emailInput}
            onChange={e => setEmailInput(e.target.value)}
            onBlur={e => validateField(e.target.value)}
          />
          <div className="login__action">
            <Button
              className="-js-forgot-password__btn-main"
              isFetching={isSubmitting}
              isDisabled={isSubmitting}
              onClick={handleSubmit}
              type="submit"
            >
              Request Reset Link
            </Button>
            <p>
              <Link className="-js-forgot-password__btn-back" to="/login">
                Back to Login
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
