import React from 'react';
import { useLocation } from 'react-router';

import logo from '../../assets/images/savvi_logo.svg';
import oneTapLogo from '../../assets/images/one_tap_logo.png';
// import paperOSLogo from '../../assets/images/paperOS_wordmark_flat.svg';
import { Button } from '../common';

import './Footer.scss';

const Footer = ({ hideBack, hideLogo, hideSettings }) => {
  const location = useLocation();
  const isWhiteLabel = window.location.href.includes('paperos');

  return (
    <div className="footer__main">
      <ul className="footer__list">
        <li className="footer__list-item">
          <img
            className="footer__logo"
            src={isWhiteLabel ? oneTapLogo : logo}
            alt="footer logo"
          />
          {/* <div className="footer__paperOs">
            <h4>powered by</h4>
            <img src={paperOSLogo} alt="paperOS logo" />
          </div> */}
        </li>
        {/* {isWhiteLabel && (
          <li className="footer__list-item">
            <Button
              buttonType="link"
              to={{
                pathname: '/terms',
                state: {
                  background: {
                    ...location,
                    state: { ...(location.state || {}), initialized: true },
                  },
                },
              }}
              size="sm"
            >
              About paperOS
            </Button>
          </li>
        )} */}
        <li className="footer__list-item">
          <Button
            buttonType="link"
            to={{
              pathname: '/terms',
              state: {
                background: {
                  ...location,
                  state: { ...(location.state || {}), initialized: true },
                },
              },
            }}
            size="sm"
          >
            Terms of Use
          </Button>
        </li>
        <li className="footer__list-item">
          <Button
            buttonType="link"
            size="xs"
            to={{
              pathname: '/privacy',
              state: {
                background: {
                  ...location,
                  state: { ...(location.state || {}), initialized: true },
                },
              },
            }}
          >
            Privacy Policy
          </Button>
          {/* <a
            href="http://docs.wixstatic.com/ugd/342422_2fcd1c73409a443d988a73a2fb263716.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__link"
          >
            Privacy Policy
          </a> */}
        </li>
        <li className="footer__list-item">
          <Button
            buttonType="link"
            size="xs"
            href={`mailto:support${isWhiteLabel ? '+onetap' : ''}@savvi.legal`}
            target="_blank"
            rel="noopener noreferrer"
            className="footer__link"
          >
            Contact Support
          </Button>
        </li>
      </ul>
    </div>
  );
};

Footer.defaultProps = {
  isAuthenticated: false,
};

export default Footer;
