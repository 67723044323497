import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BoxToken } from '../../redux/modules/Cabinet/selectors';
import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { Button, Tooltip } from '../common';

import './FilePreviewer.scss';
import {
  getBoxFileForDataRoom,
  getUserDocumentBoxToken,
} from '../../redux/modules/Cabinet/operations';
import pdfThumbnail from '../../assets/images/pdf_thumbnail.png';
import { ReactComponent as DocPreviewSVG } from '../../assets/images/doc_preview.svg';
import ContentPreview from 'box-ui-elements/es/elements/content-preview';
import { requestHeaders } from '../../redux/utils/api';
import { generateBem } from '../../utils/generateBem';
import { Document, Page } from 'react-pdf';
import { createRoomHistory } from '../../redux/modules/Data/operations';
import { BrandMeta } from '../../redux/modules/UI/selectors';

const bem = generateBem('FilePreviewer');

export const FilePreviewer = ({
  accessCode,
  boxToken,
  boxFileId,
  customClass,
  customLabel,
  documentId,
  fileLabel,
  height = 80,
  isDataRoom,
  isLinkOnHover,
  labelActionType = 'link',
  labelPosition = 'below',
  labelText,
  isThumbnailShowing,
  isPlaceholderShowing,
  isUserTable,
  reportsFeatureTypeId,
  resourceId,
  roomId,
  width = 80,
  isDocumentTemplate,
  previewLink,
}) => {
  const dispatch = useDispatch();
  const companyBoxToken = useSelector(BoxToken);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [boxAccessToken, setBoxAccessToken] = useState(null);
  const [isTokenFetched, setIsTokenFetched] = useState(false);

  useEffect(() => {
    const passCheck = isOpen || (isThumbnailShowing && !isPlaceholderShowing);
    if (!isTokenFetched && passCheck) {
      setIsTokenFetched(true);
      if (isDataRoom && resourceId) {
        dispatch(
          getBoxFileForDataRoom(
            boxFileId,
            accessCode,
            `?reports_feature_type_id=${reportsFeatureTypeId}&resource_id=${resourceId}`,
          ),
        ).then(
          payload => setBoxAccessToken(payload.token.accessToken),
          error => setError(error.message),
        );
      } else if (isUserTable && documentId) {
        dispatch(getUserDocumentBoxToken(documentId)).then(
          token => setBoxAccessToken(token.accessToken),
          error => setError(error.message),
        );
      }
    }
  }, [
    dispatch,
    accessCode,
    boxToken,
    boxFileId,
    documentId,
    isDataRoom,
    isOpen,
    isThumbnailShowing,
    isPlaceholderShowing,
    isUserTable,
    isTokenFetched,
    reportsFeatureTypeId,
    resourceId,
  ]);

  const handleModalClose = e => {
    e.preventDefault();
    setIsOpen(false);
  };
  const handleBoxResponseInterceptor = e => {
    if (e.type === 'error' || e instanceof Error) {
      setError('Unable to preview file at this time.');
    } else if (!!accessCode && isOpen && e.data?.type === 'file') {
      dispatch(
        createRoomHistory(
          accessCode,
          {
            box_file_id: boxFileId,
            action: !e.data.download_url ? 'view' : 'download',
            customer_label: !!fileLabel ? customLabel : '',
            file_label: fileLabel || customLabel,
          },
          roomId,
        ),
      );
    }
    return e;
  };

  const LabelAction = () =>
    isLinkOnHover && !error ? (
      <Button
        buttonType={labelActionType}
        size="sm"
        className={bem('action')}
        onClick={e => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        {labelText || pdfLabel}
      </Button>
    ) : (
      <h4 className={bem('label')}>{pdfLabel}</h4>
    );

  const defaultBoxToken = boxAccessToken || boxToken || companyBoxToken;
  const shouldBoxPreviewShow = !!boxFileId && !!defaultBoxToken;
  const pdfLabel = customLabel || 'File Preview';
  const isLinkDisabled =
    !isLinkOnHover || !boxFileId || (isDataRoom && isDocumentTemplate);
  const shouldShowReactPdfThumbnail =
    isDocumentTemplate && !isPlaceholderShowing && !isDataRoom && !!boxFileId;
  let thumbnailType = 'pdfPlaceholder';
  if (shouldShowReactPdfThumbnail) {
    thumbnailType = 'reactPdf';
  } else if (isPlaceholderShowing && !isDocumentTemplate) {
    thumbnailType = 'filesSvg';
  } else if (!isPlaceholderShowing && !isDocumentTemplate) {
    thumbnailType = shouldBoxPreviewShow ? 'boxPreview' : 'filesSvg';
  }

  // Modal hide/show logic.
  // const isDataRoomBoxPreviewVisible =
  //   !!isDataRoom && boxAccessToken && !error && !isDocumentTemplate;
  const isBoxPreviewVisible = !!defaultBoxToken && !error && !isDocumentTemplate;
  const isReactPdfPreviewVisible = !error && isDocumentTemplate;

  return (
    <>
      <div
        className={classNames(bem('wrapper'), customClass, {
          isLinkOnHover: !isLinkDisabled,
          isThumbnailShowing,
        })}
        onClick={e => {
          if (!isLinkDisabled) {
            e.preventDefault();
            setIsOpen(!isOpen);
            setError(false);
          }
        }}
      >
        {labelPosition === 'above' && <LabelAction />}
        {isThumbnailShowing && (
          <div className={bem()} style={{ height, width }}>
            {!error && (
              <>
                {thumbnailType === 'boxPreview' && (
                  <ContentPreview
                    fileId={boxFileId}
                    token={defaultBoxToken}
                    responseInterceptor={handleBoxResponseInterceptor}
                  />
                )}
                {thumbnailType === 'reactPdf' && (
                  <ReactPDFViewer
                    boxFileId={boxFileId}
                    isThumbnail
                    height={70}
                    previewLink={previewLink}
                    setError={setError}
                  />
                )}
                {thumbnailType === 'pdfPlaceholder' && (
                  <img
                    className={bem('previewPNG')}
                    src={pdfThumbnail}
                    alt="doc placeholder"
                  />
                )}
                {thumbnailType === 'filesSvg' && (
                  <DocPreviewSVG className={bem('previewSVG')} />
                )}
              </>
            )}
            {!!error && <DocPreviewSVG className={bem('previewSVG')} />}
          </div>
        )}
        {labelPosition === 'below' && <LabelAction />}
      </div>
      {isLinkOnHover && (
        <Modal
          className={classNames(bem('modal'), { isError: !!error })}
          contentElement={(props, children) => (
            <div
              {...props}
              onClick={e => {
                e.preventDefault();
              }}
            >
              {children}
            </div>
          )}
          isOpen={isOpen}
          onRequestClose={handleModalClose}
          overlayClassName={bem('modalOverlay')}
        >
          <FontAwesomeIcon
            className={bem('modalExit')}
            onClick={handleModalClose}
            icon={['fal', 'times']}
          />
          <h2 className={bem('modalHeader')}>({pdfLabel})</h2>
          {/* {isDataRoomBoxPreviewVisible && (
            <ContentPreview
              fileId={boxFileId}
              responseInterceptor={handleBoxResponseInterceptor}
              token={boxAccessToken}
              hasHeader
            />
          )} */}
          {isBoxPreviewVisible && (
            <ContentPreview
              fileId={boxFileId}
              responseInterceptor={handleBoxResponseInterceptor}
              token={defaultBoxToken}
              hasHeader
            />
          )}
          {isReactPdfPreviewVisible && (
            <ReactPDFViewer
              boxFileId={boxFileId}
              height={740}
              previewLink={previewLink}
              setError={setError}
              showControls
            />
          )}
          {!!error && (
            <h3 className={bem('modalError')}>
              <FontAwesomeIcon icon="exclamation-circle" />
              {error}
            </h3>
          )}
        </Modal>
      )}
    </>
  );
};

const ReactPDFBem = generateBem('ReactPDF');

export const ReactPDFViewer = ({
  boxFileId,
  height = 740,
  isThumbnail,
  previewLink,
  // setError,
  showControls,
}) => {
  const [numPages, setNumPages] = useState('loading');
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(false);
  const brandMeta = useSelector(BrandMeta);

  const onDocumentLoadSuccess = ({ numPages, ...rest }) => {
    setNumPages(numPages);
    setError('');
  };
  const handleLoadError = error => {
    console.error(error.message);
    setError(
      isThumbnail
        ? 'Unable to preview at this time.'
        : 'Unable to preview template at this time. If error persists on refresh, please contact your account admin or support.',
    );
  };

  const filePath = useMemo(
    () => ({
      url: `${brandMeta.web_base_url}/api/account/box/file/${boxFileId}`,
      httpHeaders: requestHeaders(true),
    }),
    [boxFileId, brandMeta.web_base_url],
  );
  return (
    <div className={ReactPDFBem('wrapper')}>
      <Document
        file={filePath}
        error={error}
        loading={
          <div
            className={classNames(ReactPDFBem('loading'), {
              [ReactPDFBem('loading--thumbnail')]: isThumbnail,
            })}
          >
            <FontAwesomeIcon icon="file" />
            <h3>Loading PDF....</h3>
          </div>
        }
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={handleLoadError}
        onSourceError={handleLoadError}
      >
        <Page
          loading={
            <div
              className={classNames(ReactPDFBem('loading'), {
                [ReactPDFBem('loading--thumbnail')]: isThumbnail,
              })}
            >
              <FontAwesomeIcon icon="file" />
              <h3>Loading Page....</h3>
            </div>
          }
          onLoadError={handleLoadError}
          onRenderError={handleLoadError}
          pageNumber={pageNumber}
          height={height}
        />
        {showControls && (
          <div className={ReactPDFBem('pageControls')}>
            {numPages > 1 && (
              <button
                disabled={pageNumber <= 1}
                type="button"
                onClick={e => setPageNumber(pageNumber - 1)}
                aria-label="Previous page"
              >
                <FontAwesomeIcon icon="chevron-left" />
              </button>
            )}
            <span className={ReactPDFBem('pageNumbers')}>
              {pageNumber} of {numPages}
            </span>
            <button
              disabled={!previewLink}
              onClick={e => {
                const win = window.open(previewLink, '_blank');
                win.focus();
              }}
            >
              <FontAwesomeIcon icon={['fal', 'external-link']} />
              <Tooltip text="Open in new tab" align="center" />
            </button>
            {numPages > 1 && (
              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={e => setPageNumber(pageNumber + 1)}
                aria-label="Next page"
              >
                <FontAwesomeIcon icon="chevron-right" />
              </button>
            )}
          </div>
        )}
        {!!error && <h4>{error}</h4>}
      </Document>
    </div>
  );
};
