import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import trim from 'lodash-es/trim';

import { InputSelect, InputStyledTextField } from '../../inputs';

import './CompanyName.scss';
import { validateCompany } from '../../../utils/FeatureTypes';

export const suffixOptions = [
  { label: ', Inc.', value: ', Inc.', type: 'Corporation' },
  { label: ' Inc.', value: ' Inc.', type: 'Corporation' },
  { label: ', Incorporated', value: ', Incorporated', type: 'Corporation' },
  { label: ' Incorporated', value: ' Incorporated', type: 'Corporation' },
  { label: ', Co.', value: ', Co.', type: 'Corporation' },
  { label: ' Co.', value: ' Co.', type: 'Corporation' },
  { label: ', Company', value: ', Company', type: 'Corporation' },
  { label: ' Company', value: ' Company', type: 'Corporation' },
  { label: ', Corp.', value: ', Corp.', type: 'Corporation' },
  { label: ' Corp.', value: ' Corp.', type: 'Corporation' },
  { label: ', Corporation', value: ', Corporation', type: 'Corporation' },
  { label: ' Corporation', value: ' Corporation', type: 'Corporation' },
  { label: ', LLC', value: ', LLC', type: 'LLC' },
  { label: ' LLC', value: ' LLC', type: 'LLC' },
  { label: ', LP', value: ', LP', type: 'LP' },
  { label: ' LP', value: ' LP', type: 'LP' },
  { label: ', L.P.', value: ', L.P.', type: 'LP' },
  { label: ' L.P.', value: ' L.P.', type: 'LP' },
];

const CompanyName = ({
  isEntityFormed,
  entityType,
  field: { value, name, onBlur, onChange },
  form: { errors, setFieldError, setFieldValue, setFieldTouched, status, touched },
  handleResourceName,
  isLabel,
  myType,
  question,
  questionHelp,
  setLabel,
  validateField,
  isFixed,
  isRequired,
  isWorkbench,
  ...props // everything that we should pass goes through here classnames etc
}) => {
  const textInputRef = useRef();
  let filteredOptions = suffixOptions;
  let companyName = trim(value) || '';
  let companyType = '';
  if (!isEntityFormed && !entityType.includes('Series')) {
    if (entityType) {
      filteredOptions = suffixOptions.filter(e => e.type === entityType);
    }
    let suffixIndex = -1;
    let optionIndex = -1;
    for (let i = 0; i < suffixOptions.length; i++) {
      const tempSuffixIndex = companyName.indexOf(suffixOptions[i].value);
      if (tempSuffixIndex !== -1) {
        suffixIndex = tempSuffixIndex;
        optionIndex = i;
        break;
      }
    }
    if (suffixIndex !== -1) {
      companyName = value.substring(0, suffixIndex);
      companyType = suffixOptions[optionIndex];
    }
  }

  const apiError = get(status, name, false);
  const isTouched = get(touched, name, false);
  const error = apiError || (isTouched && get(errors, name, null));
  let updatedQuestionHelp = !!isFixed
    ? 'If your company name is incorrect contact the Savvi support team in the support chat or email us at support@savvi.legal'
    : questionHelp;

  const [companyFullName, setCompanyFullName] = useState(trim(value) || '');
  const [companyNameInput, setCompanyNameInput] = useState(companyName);
  const [companyTypeInput, setCompanyTypeInput] = useState(companyType);
  const [isEditable, setIsEditable] = useState(false);
  const [focused, setFocus] = useState(false);

  useEffect(() => {
    let timer;
    if (!focused && isEditable) {
      timer = setTimeout(() => setIsEditable(false), 500);
    } else {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [isEditable, focused]);

  const handleValidate = useCallback(
    () => !isFixed && validateCompany(value, suffixOptions, entityType, isEntityFormed),
    [value, isFixed, entityType, isEntityFormed],
  );

  useEffect(() => {
    if (isTouched) {
      setFieldError(name, handleValidate());
    }
  }, [entityType, isEntityFormed, setFieldError, handleValidate, name, isTouched]);

  const isCompanyTypeShowing =
    isEditable && !isFixed && !isEntityFormed && !entityType.includes('Series');
  const suffixError =
    (error && companyTypeInput && 'Select different suffix') ||
    (error && !companyTypeInput && 'Select a suffix') ||
    '';
  return (
    <>
      {isCompanyTypeShowing && (
        <div className="company-name__wrapper">
          <InputStyledTextField
            error={error}
            value={companyNameInput}
            isRequired={isRequired}
            ref={textInputRef}
            name={name}
            onChange={e => setCompanyNameInput(e.target.value)}
            onBlur={e => {
              const updatedCompanyName = trim(e.target.value);
              const formattedVal = `${updatedCompanyName}${companyType.value || ''}`;
              if (!isEqual(updatedCompanyName, companyName)) {
                handleResourceName(formattedVal);
              }
              setFocus(false);
              setCompanyFullName(formattedVal);
              setCompanyNameInput(updatedCompanyName);
              setFieldValue(name, formattedVal);
              setFieldTouched(name, true);
            }}
            onFocus={e => setFocus(true)}
            {...props}
            questionHelp={updatedQuestionHelp}
          />
          <InputSelect
            error={suffixError}
            isClearable={false}
            isCreatable={false}
            isSearchable={false}
            isRequired={isRequired}
            name={name}
            label="Company Suffix:"
            onBlur={e => {
              setFocus(false);
              setFieldTouched(name, true);
            }}
            onChange={e => {
              if (!isEqual(e, companyType)) {
                const formattedVal = `${companyName}${e.value}`;
                setCompanyFullName(formattedVal);
                setCompanyTypeInput(e);
                setFieldValue(name, formattedVal);
                setFieldTouched(name, true);
                handleResourceName(formattedVal);
              }
            }}
            onFocus={e => setFocus(true)}
            defaultValue={''}
            value={companyTypeInput}
            options={filteredOptions}
          />
        </div>
      )}
      {!isCompanyTypeShowing && (
        <InputStyledTextField
          error={error}
          isFixed={isFixed}
          isRequired={isRequired}
          name={name}
          onBlur={e => {
            const updatedVal = e.target.value;
            if (trim(updatedVal) !== trim(value)) {
              if (isLabel) {
                setLabel(updatedVal);
              }
              handleResourceName(updatedVal);
            }
            let suffixIndex = -1;
            let optionIndex = -1;
            let updatedCompanyType = '';
            let updatedCompanyName = updatedVal;

            for (let i = 0; i < suffixOptions.length; i++) {
              const tempSuffixIndex = updatedVal.indexOf(suffixOptions[i].value);
              if (tempSuffixIndex !== -1) {
                suffixIndex = tempSuffixIndex;
                optionIndex = i;
                break;
              }
            }
            if (suffixIndex !== -1) {
              updatedCompanyName = trim(updatedVal.substring(0, suffixIndex));
              if (optionIndex !== -1) {
                updatedCompanyType = suffixOptions[optionIndex];
              }
            }
            setCompanyFullName(updatedVal);
            setCompanyNameInput(updatedCompanyName);
            setCompanyTypeInput(updatedCompanyType);
            setFieldTouched(name, true);
            onBlur(updatedVal);
          }}
          onFocus={async e => {
            e.preventDefault();
            if (!isEntityFormed && !entityType.includes('Series') && entityType) {
              await setFocus(true);
              await setIsEditable(true);
              await textInputRef.current.focus();
            } else {
              setFocus(true);
            }
          }}
          value={companyFullName}
          onChange={e => {
            const {
              target: { value },
            } = e;
            setCompanyFullName(value);
            // onChange(e);
          }}
          {...props}
          questionHelp={updatedQuestionHelp}
        />
      )}
    </>
  );
};

CompanyName.propTypes = {
  entityType: PropTypes.string,
  handleResourceName: PropTypes.func,
};

CompanyName.defaultProps = {
  // entityType: 'Corporation',
  handleResourceName: () => {},
  isFixed: false,
};

export default CompanyName;
