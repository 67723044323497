// import decode from 'jwt-decode';

import {
  CLEAR_ERRORS,
  LOGOUT,
  SET_ACCOUNT,
  SET_BOX_TOKEN,
  SET_CHECKPOINTS,
  SET_INIT_LOADING,
  SET_NOTIFICATIONS,
  SET_PARTNER_BY_CODE,
  SET_PARTNER_CODE,
  SET_USER,
} from './types';

export function clearErrors(payload, key) {
  return {
    type: CLEAR_ERRORS,
  };
}

export function setCheckpoints(payload) {
  localStorage.setItem('checkpoints', JSON.stringify(payload));
  return {
    type: SET_CHECKPOINTS,
    payload,
  };
}

export const setBoxToken = token => {
  return {
    type: SET_BOX_TOKEN,
    payload: { token },
  };
};

export const setInitLoading = isLoading => ({
  type: SET_INIT_LOADING,
  isLoading,
});

export const setNotifications = payload => ({
  type: SET_NOTIFICATIONS,
  payload,
});

export const setPartnerByCode = (payload, code) => ({
  type: SET_PARTNER_BY_CODE,
  payload,
  code,
});
export const setPartnerCode = code => ({
  type: SET_PARTNER_CODE,
  code,
});

export const setUserFromToken = decodedToken => {
  let tokens = JSON.parse(localStorage.id_tokens || '{}');

  localStorage.setItem(
    'id_tokens',
    JSON.stringify({
      ...tokens,
      0: decodedToken.id_token,
    }),
  );
  return {
    type: SET_USER,
    payload: decodedToken,
  };
};

export const setAccountFromToken = decodedToken => {
  let tokens = JSON.parse(localStorage.id_tokens || '{}');

  localStorage.setItem(
    'id_tokens',
    JSON.stringify({
      ...tokens,
      [decodedToken.accountId || '0']: decodedToken.id_token,
    }),
  );
  return {
    type: SET_ACCOUNT,
    payload: decodedToken,
  };
};

export const logoutUser = () => {
  localStorage.removeItem('id_tokens');
  localStorage.removeItem('id_token');
  localStorage.removeItem('storedLocation');
  localStorage.removeItem('hide_verify');
  localStorage.removeItem('redirectLoc');
  return {
    type: LOGOUT,
  };
};
