import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './SearchBar.scss';
import { setSearch } from '../../redux/modules/UI/actions';
import { SearchInput } from '../../redux/modules/UI/selectors';
import classNames from 'classnames';

const SearchBar = () => {
  const dispatch = useDispatch();
  const searchInput = useSelector(SearchInput);

  return (
    <label
      className={classNames('searchBar', {
        'searchBar--empty': searchInput.length === 0,
      })}
    >
      <FontAwesomeIcon className="searchBar__icon" icon="magnifying-glass" />
      <input
        autoComplete="off"
        placeholder="Search"
        value={searchInput}
        name="searchBar"
        onChange={ev => dispatch(setSearch(ev.target.value))}
      />
      {searchInput.length > 0 && (
        <FontAwesomeIcon
          className="searchBar__clear"
          icon="times"
          onClick={() => dispatch(setSearch(''))}
        />
      )}
    </label>
  );
};

export default SearchBar;
