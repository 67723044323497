import React from 'react';

import { Field, Form } from 'formik';

import logo from '../../assets/images/wordmark-white.svg';
import oneTapLogo from '../../assets/images/one_tap_wordmark.png';
import Button from '../../components/common/Button';
import { Password, Select, StyledCheckbox, TextField } from '../../components/formik';
import TermsOfService from '../../components/TermsOfServiceModal';

import './Register.scss';
import { businessTypeOptions } from '../NewCompany/NewCompany';
import {
  validateEmail,
  validatePassword,
  validateSelect,
  validateString,
} from '../../utils/FeatureTypes';

const RegisterForm = ({
  isFetching,
  isModalOpen,
  isPasswordPlainText,
  isRoomRedirect,
  isWhiteLabel,
  handleTogglePassword,
  handleOnLoginClick,
  status,
  toggleModal,
}) => (
  <div className="register">
    <div className="register__logo">
      <img
        className="register__savvi"
        src={isWhiteLabel ? oneTapLogo : logo}
        alt="savvi-logo"
      />
      {isWhiteLabel && (
        <div className="login__paperos">
          <h4>powered by PaperOS</h4>
        </div>
      )}
    </div>
    <Form className="card card-body register__form">
      <h2 className="form__heading">
        {isWhiteLabel
          ? 'Please answer a few questions to get started.'
          : 'Create an account to get started.'}
      </h2>
      <div className="grid--flex">
        <div className="grid__column">
          <Field
            component={TextField}
            inputClassName="-js-register__input-first-name"
            name="first_name"
            label="First Name"
            icon="user"
            validate={validateString}
          />
        </div>
        <div className="grid__column">
          <Field
            component={TextField}
            inputClassName="-js-register__input-last-name"
            name="last_name"
            label="Last Name"
            icon="user"
            validate={validateString}
          />
        </div>
      </div>
      <Field
        component={TextField}
        autoComplete="username"
        inputClassName="-js-register__input-email"
        name="email"
        label={'Email'}
        placeholder={'john@savvi.com'}
        icon="envelope"
        type="email"
        validate={validateEmail}
      />
      <Field
        component={Password}
        inputClassName="-js-register__input-password"
        name="password"
        label="Password"
        autoComplete="new-password"
        icon="lock"
        hasToggle={true}
        onToggle={handleTogglePassword}
        isPlainText={isPasswordPlainText}
        validate={validatePassword}
      />
      {!isRoomRedirect && (
        <>
          <Field
            component={TextField}
            inputClassName="-js-register__input-company-name"
            label={'Company Name'}
            name="company_name"
            placeholder={'e.g. My Company Name, Inc.'}
            sublabel="If you currently aren't sure of your company name or suffix, go ahead and give us a placeholder name. You will be able to change it later."
            validate={validateString}
          />
          {!isWhiteLabel && (
            <Field
              component={Select}
              isValidatingOnBlur={false}
              label="What is the purpose of this business entity?"
              name="business_type"
              options={businessTypeOptions}
              validate={validateSelect}
            />
          )}
        </>
      )}
      <Field
        component={StyledCheckbox}
        inputClassName="-js-register__input-tos"
        name="tos"
        label={
          <label htmlFor="tos">
            I agree to {isWhiteLabel ? "PaperOs'" : "Savvi's"}{' '}
            <a
              href="http://docs.wixstatic.com/ugd/342422_fa8385a417404f138a687a08f8c5bc15.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            {' and'}
            <a
              href="http://docs.wixstatic.com/ugd/342422_2fcd1c73409a443d988a73a2fb263716.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__link"
            >
              Privacy Policy
            </a>
          </label>
        }
        validate={val =>
          val !== '1' ? "You must agree to Savvi's Terms of Service" : ''
        }
      />
      {status.form && <div className="alert form__error">{status.form}</div>}
      <div className="register__actions">
        <Button
          className="-js-register__btn-back"
          buttonType="secondary"
          onClick={handleOnLoginClick}
        >
          Back
        </Button>
        <Button
          className="-js-register__btn-main"
          isFetching={isFetching}
          isDisabled={isFetching}
          type="submit"
        >
          Create Account
        </Button>
      </div>
    </Form>
    <TermsOfService
      isOpen={isModalOpen}
      onClose={() => toggleModal(false)}
      label="Terms of Service"
    />
  </div>
);

RegisterForm.defaultProps = {
  values: {},
  status: {},
};

export default RegisterForm;
