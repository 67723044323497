import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Formik } from 'formik';

import { operations as UI } from '../../redux/modules/UI';
import { operations } from '../../redux/modules/User';
// import { registerSchema } from '../../utils/Validations';
import Register from './Register';
import { setUserFromToken } from '../../redux/modules/User/actions';

class RegisterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordPlainText: false,
    };
  }

  handleOnLoginClick = () =>
    this.props.history.replace({
      ...this.props.location,
      pathname: '/login',
    });

  handleTogglePassword = () => {
    return this.setState({
      isPasswordPlainText: !this.state.isPasswordPlainText,
    });
  };

  render() {
    const {
      isFetching,
      isModalOpen,
      location: { state: { email = '', redirectPathname = '' } = {} },
      submitForm,
      toggleModal,
    } = this.props;
    const isRoomRedirect = redirectPathname.includes('data-room');
    const isWhiteLabel = window.location.href.includes('paperos');

    return (
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email,
          company_name: '',
          password: '',
          tos: false,
          business_type: isWhiteLabel ? { label: 'One Tap', value: 'One Tap' } : '',
        }}
        onSubmit={({
          business_type,
          company_name,
          email,
          first_name,
          last_name,
          password,
        }) => {
          const userBody = {
            email,
            password,
            first_name,
            family_name: last_name,
            create: true,
          };
          const companyBody = {
            company_name,
            features: [{ feature_type_id: 881, value: business_type?.value || '' }],
          };
          submitForm(userBody, companyBody, isRoomRedirect);
        }}
      >
        {formikProps => (
          <Register
            {...formikProps}
            isFetching={isFetching}
            isModalOpen={isModalOpen}
            isPasswordPlainText={this.state.isPasswordPlainText}
            isRoomRedirect={isRoomRedirect}
            isWhiteLabel={isWhiteLabel}
            handleTogglePassword={this.handleTogglePassword}
            handleOnLoginClick={this.handleOnLoginClick}
            toggleModal={toggleModal}
          />
        )}
      </Formik>
    );
  }
}

RegisterContainer.propTypes = {
  errors: PropTypes.object,
  isFetching: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
};

RegisterContainer.defaultProps = {
  errors: {},
  isFetching: false,
  isModalOpen: false,
  toggleModal: () => null,
};

const mapStateToProps = ({ Auth, UI }) => {
  const { isFetching } = Auth;
  return {
    isFetching,
    isModalOpen: UI.isTermsModalOpen,
  };
};

const mapDispatchToProps = dispatch => {
  const { createUser } = operations;
  const { openTermsModal, closeTermsModal } = UI;
  return {
    setUser: decodedToken => dispatch(setUserFromToken(decodedToken)),
    submitForm: (userBody, companyBody, isRoomRedirect) =>
      dispatch(createUser(userBody, companyBody, isRoomRedirect)),
    toggleModal: open =>
      !!open ? dispatch(openTermsModal()) : dispatch(closeTermsModal()),
  };
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(RegisterContainer);
