import { get } from 'lodash-es';
import { defaultMemoize } from 'reselect';

// import { SubscriptionsFeaturesDict } from '../../../utils/SubscriptionsDict';

export const getSubscriptionsData = defaultMemoize(state =>
  get(state, 'subscription.subscriptions.data', []),
);

export const IsFetching = defaultMemoize(state =>
  get(state, 'Billing.isFetching', false),
);

export const PlaidToken = defaultMemoize(state => get(state, 'Billing.plaidToken', ''));

export const ApiErrors = defaultMemoize(state => get(state, 'Billing.ApiErrors', false));

export const PlanPreviewTotal = defaultMemoize(state => {
  let total = get(state, 'Billing.subscriptionPreview.amount_due', null);
  if (total) {
    return total / 100;
  }
});

export const Invoices = defaultMemoize(state => get(state, 'Billing.invoices', []));

export const getFetchError = defaultMemoize(state => get(state, 'fetchError', null));

export const PaymentMethods = defaultMemoize(state =>
  get(state, 'Billing.methods', []).map(method => ({ ...method })),
);

export const IsPaymentMethodAvailable = defaultMemoize(
  state => get(state, 'Billing.methods', []).length > 0,
);

export const PaymentMethodsWithDefault = defaultMemoize(state =>
  get(state, 'Billing.methods', []).map(method => ({
    ...method,
    is_default: method.id === state.Billing.defaultMethod,
  })),
);

export const FilteredPaymentMethodsWithDefault = defaultMemoize(state =>
  get(state, 'Billing.methods', [])
    .filter(e => e.status !== 'new' && e.status !== 'verification_failed')
    .map(method => ({
      ...method,
      is_default: method.id === state.Billing.defaultMethod,
    })),
);
