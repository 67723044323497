import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getUser,
  IsAccountSettingsShowing,
  isFetchingProfile,
} from '../../redux/modules/Profile/selectors';
import {
  getAuthUser,
  getAuthAccount,
  IsAuthenticated,
} from '../../redux/modules/User/selectors';
import { onLogout } from '../../redux/modules/User/operations';
import UseWindowSize from '../../utils/UseWindowSize';
import SavviLoading from '../SavviLoading';

import './OrgButton.scss';
import { Button } from '../common';
import handlePath from '../../utils/handlePath';
import { useLocation, useParams } from 'react-router';
import { DropdownAction } from '../common/Dropdown/Dropdown';
import { CurrentCompany } from '../../redux/modules/Company/selectors';

const OrgButton = ({ accountInfo, darkText, isDataRoom, limitedUserEmail }) => {
  const { pathname } = useLocation();
  const { subscriptions = [], owner = {} } = accountInfo || {};
  const { name, impersonator_id } = useSelector(getAuthAccount);
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const { width } = UseWindowSize();
  const isAuthenticated = useSelector(IsAuthenticated);
  const user = useSelector(getUser);
  const AuthUser = useSelector(getAuthUser);
  const isFetching = useSelector(isFetchingProfile);
  const isAccountSettingsShowing = useSelector(IsAccountSettingsShowing);
  const isWhiteLabel = window.location.href.includes('paperos');
  const currentCompany = useSelector(CurrentCompany);
  const isPaymentsHidden = isWhiteLabel && !currentCompany.partner_id;

  const [isInitFetchingTimeout, setIsInitFetchingTimeout] = useState(false);

  useEffect(() => {
    const initLoadingTimeout = setTimeout(() => setIsInitFetchingTimeout(true), 1000);
    return () => {
      clearTimeout(initLoadingTimeout);
    };
  }, []);

  const signedInEmail = user.email || AuthUser.email;
  const menuLabel = limitedUserEmail || signedInEmail || 'menu';
  const isNameShowing = !isFetching || isInitFetchingTimeout;
  const isExclamationShowing =
    !isAuthenticated ||
    (!!limitedUserEmail && signedInEmail !== limitedUserEmail) ||
    !!impersonator_id;
  let possessionLabel = name;
  if (owner?.email) {
    possessionLabel += ` (${owner.email})`;
  }

  return (
    <DropdownAction
      className="orgButton"
      Toggle={toggleProps => (
        <Button
          buttonType="link"
          size="sm"
          className="orgButton__toggle"
          {...toggleProps}
        >
          <FontAwesomeIcon
            className="orgButton__toggleIcon"
            icon={['fal', 'user-circle']}
          />
          {isExclamationShowing && (
            <FontAwesomeIcon
              className="orgButton__exclamation"
              icon="exclamation-circle"
            />
          )}
          {!isNameShowing && <SavviLoading size="sm" />}
          {isNameShowing && width > 678 && (
            <>
              {!!limitedUserEmail && <p>Viewing as</p>}
              <h4>{menuLabel}</h4>
            </>
          )}
          <FontAwesomeIcon
            className="orgButton__toggleChevron"
            icon={width <= 678 ? 'ellipsis-v' : 'chevron-down'}
          />
        </Button>
      )}
    >
      {!isAuthenticated && isDataRoom && (
        <>
          <div className="orgButton__section">
            <h4>
              <FontAwesomeIcon
                className="orgButton__exclamationCircle"
                icon="exclamation-circle"
              />
              Sign in to Savvi to more easily access & interact with your Documents &
              connected Deal Rooms.
            </h4>
          </div>
          <div className="orgButton__separator" />
          <Button
            buttonType="link"
            to={{
              pathname: '/login',
              state: { redirectPathname: pathname, passedEmail: limitedUserEmail },
            }}
            size="sm"
          >
            Sign In
          </Button>
        </>
      )}
      <div className="orgButton__section">
        {!!limitedUserEmail && signedInEmail && signedInEmail !== limitedUserEmail && (
          <h4>
            Signed in as <em>{signedInEmail}</em>
          </h4>
        )}
        {!!impersonator_id && (
          <h4>
            <FontAwesomeIcon
              className="orgButton__exclamationCircle"
              icon="exclamation-circle"
            />
            You are currently accessing this account as <em>{possessionLabel}</em>
          </h4>
        )}
        {user.first_name && user.last_name && (
          <h2>{`${user.first_name || ''} ${user.last_name || ''}`}</h2>
        )}
        {(user.email || AuthUser.email) && <h4>{user.email || AuthUser.email || ''}</h4>}
      </div>
      {companyId !== '0' && !isDataRoom && (
        <div className="orgButton__section">
          <h2>Subscriptions:</h2>
          <h4>
            {subscriptions.join()}
            {subscriptions.length === 0 && 'Free Basic'}
          </h4>
        </div>
      )}
      {isAuthenticated && <div className="orgButton__separator" />}
      {companyId !== '0' && !isDataRoom && !isPaymentsHidden && (
        <Button
          className="dd-item-ignore"
          buttonType="link"
          size="sm"
          to={handlePath('/settings/subscriptions', companyId)}
        >
          View your subscriptions
        </Button>
      )}
      {isAuthenticated && (
        <Button
          buttonType="link"
          size="sm"
          to={handlePath(
            { pathname: '/dashboard', state: { isToggled: true } },
            companyId,
          )}
        >
          View Dashboard
        </Button>
      )}
      {isAccountSettingsShowing && !isDataRoom && (
        <Button
          buttonType="link"
          size="sm"
          to={handlePath('/settings/profile', companyId)}
        >
          Account Settings
        </Button>
      )}
      {isAuthenticated && (
        <Button
          buttonType="link"
          size="sm"
          onClick={e => {
            e.preventDefault();
            dispatch(onLogout());
          }}
        >
          Logout
        </Button>
      )}
    </DropdownAction>
  );
};

OrgButton.defaultProps = {
  darkText: true,
};

export default OrgButton;
