import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';

import { createFeature, getFlatFeatures } from '../../redux/modules/Formation/operations';
import {
  getFlatFeaturesForOptions,
  isFetchingWithHooks,
} from '../../redux/modules/Formation/selectors';
import { getCounsels } from '../../redux/modules/Profile/operations';
import { getAttorneysForReviewers } from '../../redux/modules/Profile/selectors';
import {
  createResourceName,
  updateTransaction,
} from '../../redux/modules/Transaction/operations';
import { isFetchingWithHooks as isTransactionFetchingWithHooks } from '../../redux/modules/Transaction/selectors';
import { validateEmail, validateString } from '../../utils/FeatureTypes';
import { Button } from '../common';
import { TextField } from '../formik';
import { InputSelect } from '../inputs';

import './ReviewersModal.scss';

// import { Link } from 'react-router-dom';

const customStyles = {
  overlay: {
    zIndex: 9,
    backgroundColor: 'rgba(0,0,0,.66)',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    overflowY: 'auto',
    paddingTop: 20,
    WebkitOverflowScrolling: 'touch',
  },
  content: {
    position: 'static',
    maxWidth: '450px',
    minHeight: '450px',
    margin: 'auto',
    padding: 0,
    border: 'none',
    boxShadow: '3px 3px 3px 3px #33373859',
    display: 'flex',
    flexDirection: 'column',
  },
};

const ReviewersModal = ({
  initReviewers = [],
  isOpen,
  onClose,
  open,
  params: { transactionId },
}) => {
  initReviewers = initReviewers.map(e => {
    if (!e.value) {
      const name = e.name || `${e.first_name} ${e.last_name}`;
      e.value = name;
      e.label = name;
    }
    return e;
  });
  const attorneys = useSelector(state => getAttorneysForReviewers(state.Profile));
  const individuals = useSelector(state => getFlatFeaturesForOptions(state, 'reviewers'));
  const isFetchingFormation = useSelector(isFetchingWithHooks);
  const isFetchingTransaction = useSelector(isTransactionFetchingWithHooks);

  const [reviewers, setReviewers] = useState([]);
  const [isSetEmailFetching, setIsSetEmailFetching] = useState(false);
  const [isNewReviewerShowing, setNewReviewerShowing] = useState(false);
  const [emailsNeededArr, setEmailsNeededArr] = useState([]);
  const [isEmailsNeeded, setIsEmailsNeeded] = useState(false);

  const dispatch = useDispatch();
  useEffect(
    () => setReviewers([...attorneys, ...individuals]),
    [attorneys, individuals, setReviewers],
  );
  useEffect(() => {
    dispatch(getCounsels());
    dispatch(
      getFlatFeatures(
        {
          select: [1, 2, 33, 34],
          where: {},
        },
        'reviewers',
      ),
    );
  }, [dispatch]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div className="reviewers-modal">
        <FontAwesomeIcon
          className="reviewers-modal__exit"
          onClick={onClose}
          icon={['fal', 'times']}
        />
        <h2>Set Reviewers</h2>
        <h4>Allow others to view the document(s) being sent on Task Submission</h4>
        <Formik
          initialValues={{
            emailsNeeded: {},
            newReviewer: { email: '', firstName: '', lastName: '' },
            reviewers: initReviewers,
          }}
          enableReinitialize
          onSubmit={({ reviewers }, actions) => {
            let emailsNeededArr = [];
            let isEmailsNeeded = false;
            for (let key in reviewers) {
              if (!reviewers[key].email) {
                emailsNeededArr.push(reviewers[key]);
                isEmailsNeeded = true;
              }
            }
            if (isEmailsNeeded) {
              setEmailsNeededArr([...emailsNeededArr]);
              setIsEmailsNeeded(true);
              actions.setFieldValue(
                'emailsNeeded',
                emailsNeededArr.reduce((dict, e) => ({ ...dict, [e.name]: '' }), {}),
              );
            } else {
              dispatch(updateTransaction(transactionId, { reviewers })).then(e =>
                onClose(),
              );
            }
          }}
        >
          {({
            handleBlur,
            handleChange,
            setFieldError,
            setFieldTouched,
            setFieldValue,
            values: {
              emailsNeeded,
              newReviewer: { email, first_name, last_name },
              reviewers: formikReviewers,
            },
            ...formikProps
          }) => {
            return (
              <Form className="reviewers-modal__form">
                <div className="reviewers-modal__body">
                  <InputSelect
                    isMulti
                    label="Please select what individuals you want to review your
                    deliverables:"
                    onBlur={() => setFieldTouched('reviewers', true)}
                    onChange={value => setFieldValue('reviewers', value)}
                    value={formikReviewers}
                    name="reviewers"
                    options={reviewers}
                  />
                  {!isNewReviewerShowing && (
                    <p>Don't see a reviewer you want as an option?</p>
                  )}
                  {isNewReviewerShowing && (
                    <h4 className="reviewers-modal__h4">New Reviewer Info:</h4>
                  )}
                  <button
                    className="reviewers-modal__action"
                    type="button"
                    onClick={() => setNewReviewerShowing(!isNewReviewerShowing)}
                  >
                    {isNewReviewerShowing ? 'Hide New Reviewer' : 'Add New Reviewer'}
                  </button>
                  {isNewReviewerShowing && (
                    <>
                      <Field
                        component={TextField}
                        label="First Name"
                        name="newReviewer.first_name"
                        validate={validateString}
                      />
                      <Field
                        component={TextField}
                        label="Last Name"
                        name="newReviewer.last_name"
                        validate={validateString}
                      />
                      <Field
                        component={TextField}
                        label="Email"
                        name="newReviewer.email"
                        validate={validateEmail}
                      />
                      <div className="reviewers-modal__buttons">
                        <Button
                          buttonType="secondary"
                          onClick={() => {
                            const name = `${first_name} ${last_name}`;
                            if (!email || !first_name || !last_name) {
                              if (!email) {
                                setFieldTouched('newReviewer.email', true);
                              }
                              if (!first_name) {
                                setFieldTouched('newReviewer.first_name', true);
                              }
                              if (!last_name) {
                                setFieldTouched('newReviewer.last_name', true);
                              }
                              return;
                            }
                            dispatch(
                              createResourceName({ name, resource_type_id: 1 }),
                            ).then(async payload => {
                              await dispatch(
                                createFeature(
                                  {
                                    value: email,
                                    feature_type_id: 2,
                                    resource_id: payload.id,
                                  },
                                  name,
                                  'email',
                                  true,
                                ),
                              );
                              await dispatch(
                                getFlatFeatures(
                                  {
                                    select: [1, 2, 33, 34],
                                    where: {},
                                  },
                                  'reviewers',
                                ),
                              );
                              await setFieldValue('reviewers', [
                                ...formikReviewers,
                                {
                                  email,
                                  first_name,
                                  label: name,
                                  last_name,
                                  name,
                                  resource_id: payload.id,
                                  value: name,
                                },
                              ]);
                              await setNewReviewerShowing(false);
                              await setFieldTouched('newReviewer.first_name', false);
                              await setFieldTouched('newReviewer.last_name', false);
                              await setFieldTouched('newReviewer.email', false);
                              return await setFieldValue('newReviewer', {
                                email: '',
                                first_name: '',
                                last_name: '',
                              });
                            });
                          }}
                        >
                          Save New Reviewer
                        </Button>
                      </div>
                    </>
                  )}
                  {isEmailsNeeded && (
                    <>
                      <p className="reviewers-modal__alert">
                        <strong>Emails needed for:</strong>
                      </p>
                      {emailsNeededArr.map((e, index) => (
                        <Field
                          key={`emails-needed-${index}`}
                          component={TextField}
                          label={`${e.first_name} ${e.last_name || ''}'s email`}
                          name={`emailsNeeded.${e.name}]`}
                          validate={validateString}
                        />
                      ))}
                      <div className="reviewers-modal__buttons">
                        <Button
                          buttonType="secondary"
                          isLoading={isSetEmailFetching}
                          onClick={() => {
                            let isEmailsStillNeeded = false;
                            let counter = 0;

                            Object.entries(emailsNeeded).forEach(
                              ([individualName, email], index) => {
                                if (!email) {
                                  setFieldTouched(
                                    `emailsNeeded.${individualName}`,
                                    email,
                                  );

                                  isEmailsStillNeeded = true;
                                }
                                counter++;
                              },
                            );

                            if (
                              counter === Object.entries(emailsNeeded).length &&
                              !isEmailsStillNeeded
                            ) {
                              let dispatchCounter = 0;
                              setIsSetEmailFetching(true);
                              Object.entries(emailsNeeded).forEach(
                                ([individualName, email], ind) => {
                                  const emailIndex = emailsNeededArr.findIndex(
                                    e => e.name === individualName,
                                  );
                                  dispatch(
                                    createFeature(
                                      {
                                        value: email,
                                        feature_type_id: 2,
                                        resource_id:
                                          emailsNeededArr[emailIndex].resource_id,
                                      },
                                      individualName,
                                      'email',
                                      true,
                                    ),
                                  ).then(e => {
                                    let updatedReviewers = [...formikReviewers];
                                    const updatedIndividualIndex =
                                      updatedReviewers.findIndex(
                                        e => e.name === individualName,
                                      );
                                    setFieldValue(
                                      `reviewers[${updatedIndividualIndex}]`,
                                      { ...emailsNeededArr[emailIndex], email },
                                    );
                                    dispatchCounter++;
                                    if (
                                      dispatchCounter ===
                                      Object.entries(emailsNeeded).length
                                    ) {
                                      setIsSetEmailFetching(false);
                                      setIsEmailsNeeded(false);
                                      setEmailsNeededArr([]);
                                    }
                                  });
                                },
                              );
                            }
                          }}
                        >
                          Save emails
                        </Button>
                      </div>
                    </>
                  )}
                </div>
                <div className="reviewers-modal__buttons">
                  <Button buttonType="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    isFetching={isFetchingFormation || isFetchingTransaction}
                    isDisabled={isNewReviewerShowing || isEmailsNeeded}
                  >
                    Save Reviewers
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

ReviewersModal.defaultProps = {
  secondaryActionText: 'Close',
};

export default ReviewersModal;
