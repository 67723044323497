import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './SiteMaintenanceBanner.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(relativeTime);

const SiteMaintenanceBanner = ({ timeStartUtc, timeEndUtc }) => {
  const [isShowing, setIsShowing] = useState(true);

  const startLabel = dayjs.utc(timeStartUtc).local().format('h:mmA');
  const endLabel = dayjs.utc(timeEndUtc).local().format('h:mmA');
  if (dayjs().isAfter(dayjs.utc(timeEndUtc))) {
    return null;
  }
  return (
    <div
      className={`siteMaintenanceBanner${
        !isShowing ? ' siteMaintenanceBanner--hide' : ''
      }`}
    >
      <FontAwesomeIcon
        className="siteMaintenanceBanner__icon"
        icon="exclamation-circle"
      />
      <h4>
        We will be performing site maintenance between {startLabel} & {endLabel} during
        which time the site or certain site features may be temporarily unavailable.
      </h4>
      <Button
        buttonType="icon"
        size="xs"
        className="siteMaintenanceBanner__hide"
        onClick={() => setIsShowing(false)}
      >
        <FontAwesomeIcon icon="times" />
      </Button>
    </div>
  );
};

SiteMaintenanceBanner.propTypes = {
  timeStartUtc: PropTypes.string,
  timeEndUtc: PropTypes.string,
};

export default SiteMaintenanceBanner;
