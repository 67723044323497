import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { Button } from '../common';

// import Button from '../common/Button';
import './AlertModal.scss';

// import { Link } from 'react-router-dom';

Modal.setAppElement('#root');

const AlertModal = ({
  body,
  children,
  customContentStyles,
  customOverStyles,
  header,
  isOpen,
  isFetching,
  isSecondary,
  isWarning,
  isExclamationShowing,
  onClose,
  onPrimaryClick,
  primaryActionText,
  secondaryActionText,
}) => {
  const customStyles = {
    overlay: {
      zIndex: 9,
      backgroundColor: 'rgba(0,0,0,.66)',
      position: 'fixed',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      overflowY: 'auto',
      paddingTop: 20,
      WebkitOverflowScrolling: 'touch',
      ...customOverStyles,
    },
    content: {
      position: 'static',
      maxWidth: '450px',
      minHeight: '175px',
      margin: 'auto',
      padding: 0,
      border: 'none',
      borderRadius: 16,
      boxShadow: '3px 3px 3px 3px #33373859',
      display: 'flex',
      flexDirection: 'column',
      ...customContentStyles,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterClose={ev => {
        document.body.classList.remove('ReactModal__Body--open');
      }}
      style={customStyles}
    >
      <div className="alert-modal">
        <FontAwesomeIcon className="alert-modal__exit" onClick={onClose} icon="times" />
        <h2>
          {isExclamationShowing && (
            <FontAwesomeIcon
              className={classnames('alert-modal__exclamation', {
                warning: isWarning,
              })}
              icon={['fas', 'exclamation-circle']}
            />
          )}
          {header}
        </h2>
        {children}
        {body && <p>{body}</p>}
        <div className="alert-modal__buttons">
          {/* <Button
            className={classnames('alert-modal__btn workbench__btn btn--flex', {
              primary: !isSecondary,
              secondary: isSecondary,
              warning: isWarning,
            })}
            isDisabled={isFetching}
            isLoading={isFetching}
            onClick={onPrimaryClick}
          >
            {primaryActionText}
          </Button> */}
          <Button
            // className={classnames('alert-modal__btn workbench__btn btn--flex', {
            //   primary: !isSecondary,
            //   secondary: isSecondary,
            //   warning: isWarning,
            // })}

            buttonType={isSecondary ? 'secondary' : 'primary'}
            isWarning={isWarning}
            isDisabled={isFetching}
            isFetching={isFetching}
            onClick={onPrimaryClick}
          >
            {primaryActionText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AlertModal.propTypes = {
  body: PropTypes.string,
  children: PropTypes.node,
  customContentStyles: PropTypes.string,
  customOverStyles: PropTypes.string,
  header: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isWarning: PropTypes.bool,
  isExclamationShowing: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onPrimaryClick: PropTypes.func,
  primaryActionText: PropTypes.string,
  secondaryActionText: PropTypes.string,
};

AlertModal.defaultProps = {
  primaryActionText: 'Close',
  secondaryActionText: 'Close',
  isOpen: false,
  onClose: () => {},
};

export default AlertModal;
