import { combineReducers } from 'redux';

import isObject from 'lodash-es/isObject';

import { createReducer } from '../../utils';
import {
  CLEAR_NOTICE,
  CLEAR_NOTICES,
  CLOSE_TERMS_MODAL,
  CLOSE_USER_DROPDOWN,
  OPEN_TERMS_MODAL,
  OPEN_USER_DROPDOWN,
  SET_NOTICE,
  SET_SEARCH,
  SET_SIDEBAR_COLLAPSED,
  CLEAR_SEARCH,
  SET_BRAND_META,
} from './types';

const isTermsModalOpen = createReducer(false)({
  [OPEN_TERMS_MODAL]: (state, action) => true,
  [CLOSE_TERMS_MODAL]: (state, action) => false,
});

const isUserDropdownOpen = createReducer(false)({
  [OPEN_USER_DROPDOWN]: (state, action) => true,
  [CLOSE_USER_DROPDOWN]: (state, action) => false,
});

const notices = createReducer([])({
  [SET_NOTICE]: (state, action) => {
    const updatedState = state.slice();
    if (state.length > 2) {
      updatedState.pop();
    }
    if (isObject(action.payload)) {
      updatedState.push(action.payload);
    } else {
      updatedState.push({ type: 'success', message: action.payload });
    }
    return updatedState;
  },
  [CLEAR_NOTICE]: (state, action) => {
    let updatedState = state.slice();
    const noticeIndex = updatedState.findIndex(e => e.message === action.payload.message);
    if (noticeIndex !== -1) {
      updatedState.splice(noticeIndex, 1);
    } else {
      updatedState.pop();
    }
    return updatedState;
  },
  [CLEAR_NOTICES]: (state, action) => [],
});

const search = createReducer('')({
  [SET_SEARCH]: (state, action) => action.input,
  [CLEAR_SEARCH]: (state, action) => '',
});

const brandMeta = createReducer({})({
  [SET_BRAND_META]: (state, action) => action.payload,
});

const isSidebarCollapsed = createReducer(false)({
  [SET_SIDEBAR_COLLAPSED]: (state, action) => action.collapsed,
});

export default combineReducers({
  isTermsModalOpen,
  isUserDropdownOpen,
  notices,
  search,
  isSidebarCollapsed,
  brandMeta,
});
