import React, { useState } from 'react';
import Modal from 'react-modal';

import './DocumentPreview.scss';

const bem = elementName => `DocumentPreview${elementName ? '__' + elementName : ''}`;

const customStyles = {
  overlay: {
    zIndex: 9,
    backgroundColor: 'rgba(0,0,0,.66)',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    overflowY: 'auto',
    paddingTop: 20,
    WebkitOverflowScrolling: 'touch',
  },
  content: {
    position: 'static',
    maxWidth: '600px',
    minHeight: '580px',
    margin: 'auto',
    padding: '0 8px 4px',
    border: 'none',
    boxShadow: '3px 3px 3px 3px #33373859',
    display: 'flex',
    flexDirection: 'column',
  },
};

const DocumentPreview = ({
  customClass,
  isThumbnailShowing,
  isLabelShowing,
  label,
  pages,
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div
      className={`${bem()} ${customClass}${!isThumbnailShowing ? ' no-thumbnail' : ' '}`}
    >
      {isLabelShowing && <h4 onClick={() => setOpen(!isOpen)}>{label}</h4>}
      {isThumbnailShowing && (
        <div className={bem('thumbnail')}>
          {pages.map((page, index) => (
            <img
              key={`pages-thumbnail-${index}`}
              className={bem('thumbnailPage')}
              src={page.jpg}
              alt=""
            />
          ))}
        </div>
      )}
      <Modal onRequestClose={() => setOpen(false)} isOpen={isOpen} style={customStyles}>
        {pages.map((page, index) => (
          <img key={`pages-${index}`} className={bem('page')} src={page.jpg} alt="" />
        ))}
      </Modal>
    </div>
  );
};

DocumentPreview.defaultProps = {
  customClass: '',
  label: 'View Document Preview',
  isLabelShowing: true,
  pages: [],
};

export default DocumentPreview;
