import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { AccountInfo } from '../../redux/modules/Formation/selectors';
import { getAccountProject } from '../../redux/modules/Project/operations';
import { AccountProject } from '../../redux/modules/Project/selectors';
import {
  createTransaction,
  getTransactionQuestionnaire,
} from '../../redux/modules/Transaction/operations';
import {
  getFormikValues,
  getTransactionById,
} from '../../redux/modules/Transaction/selectors';
import handlePath from '../../utils/handlePath';
import WorkbenchTaskOverview from '../../views/WorkbenchTaskOverview';
import { Button } from '../common';
import SavviLoading from '../SavviLoading';

import './PortalTaskModal.scss';

const PortalTaskModal = ({ params }) => {
  const { companyId, moduleId, projectId, transactionId } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { push, replace } = history;
  const location = useLocation();
  const { pathname } = location;
  const accountInfo = useSelector(AccountInfo);
  const { credits_info, subscriptions = [] } = accountInfo;
  const project = useSelector(State => AccountProject(State, projectId));
  const transaction = useSelector(State =>
    getTransactionById(State.Transaction, transactionId),
  );
  const {
    template: { actions = [] },
    transactions,
  } = project;
  const { id: templateId } = actions[0] || {};

  // const startedActionIndex = transactions.findIndex(e => e.id === transactionId);

  // const { id } = transactions[startedActionIndex] || {};

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const formikValues = useSelector(State =>
    getFormikValues(State.Transaction, transactionId),
  );
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (pathname.includes('/add') && !isMounted) {
      setIsMounted(true);
      if (!transactionId) {
        dispatch(createTransaction(projectId, templateId)).then(
          payload => {
            dispatch(getTransactionQuestionnaire(payload.id));
            dispatch(getAccountProject(projectId));
            replace(
              handlePath(
                `/workbench/${moduleId}/${projectId}/${payload.id}/add`,
                companyId,
              ),
            );
          },
          error =>
            replace(
              handlePath(`/workbench/${moduleId}/${projectId}/0/initialize`, companyId),
            ),
        );
      } else {
        // dispatch(getTransaction(transactionId));
        // dispatch(getTransactionQuestionnaire(transactionId));
      }
    } else if (!isMounted) {
      setIsMounted(true);
      // dispatch(getTransaction(transactionId));
      // dispatch(getTransactionQuestionnaire(transactionId));
    }
  }, [
    dispatch,
    pathname,
    isMounted,
    projectId,
    replace,
    templateId,
    transactionId,
    moduleId,
    companyId,
  ]);

  const onClose = () => {
    push(
      handlePath(
        `/workbench/${moduleId}/${projectId}/${transactions[0].id}/portal`,
        companyId,
      ),
    );
    // if (action !== 'POP') {
    //   goBack();
    // } else if (background) {
    //   replace(background);
    // } else {
    //   replace(handlePath(`/workbench/${moduleId}/${projectId}/0/initialize`, companyId));
    // }
  };

  let isSubscription = false;
  let mySubscriptions = [];
  let isSubscribed = false;
  let documentCredits = 0;

  !isEmpty(transaction) &&
    transaction.tasks.forEach((e, index) => {
      if (!e.bypassed) {
        documentCredits += e.document_counter || 0;
      }
      const innerSubs = get(e, 'template.prerequisites.subscriptions', []);

      if (innerSubs.length > 0) {
        isSubscription = true;
        mySubscriptions = innerSubs;
        isSubscribed = mySubscriptions.find(
          sub =>
            subscriptions.findIndex(accountSub => {
              // advanced subscription covers starter subscription
              if (accountSub === 'advanced' && sub === 'starter') {
                return true;
              }
              return accountSub === sub;
            }) !== -1,
        );
      }
    });

  const availableCredits = credits_info && credits_info.limit - credits_info.used;

  const isDocumentCreditsNeeded =
    availableCredits < 1 &&
    documentCredits > 0 &&
    !!transaction.open &&
    params.step !== 'overview' &&
    !get(transaction, 'template.is_assessment', false);

  return (
    <Modal
      isOpen={pathname.includes('/portal-task')}
      onRequestClose={onClose}
      onAfterClose={ev => {
        document.body.classList.remove('ReactModal__Body--open');
      }}
      className="portalTaskModal__container"
      overlayClassName="portalTaskModal__overlay"
    >
      <div
        className={classNames('portalTaskModal', {
          'portalTaskModal--sidebarCollapsed': isSidebarCollapsed,
        })}
      >
        <FontAwesomeIcon
          className="portalTaskModal__exit"
          onClick={onClose}
          icon="times"
        />
        {!isMounted && <SavviLoading />}
        {isMounted && (
          <WorkbenchTaskOverview
            documentCredits={documentCredits}
            formikValues={formikValues}
            isDocumentCreditsNeeded={isDocumentCreditsNeeded}
            isSubscriptionNeeded={isSubscription && !isSubscribed}
            isPortalView
            params={params}
          />
        )}
        <Button
          className="portalTaskModal__sidebarToggle"
          buttonType="icon"
          size="sm"
          onClick={ev => setIsSidebarCollapsed(!isSidebarCollapsed)}
        >
          <FontAwesomeIcon
            icon={isSidebarCollapsed ? 'info' : ['fal', 'arrow-from-left']}
          />
        </Button>
      </div>
    </Modal>
  );
};

export default PortalTaskModal;
