import get from 'lodash-es/get';
import { defaultMemoize } from 'reselect';
// import find from 'lodash-es/find';

export const getStoredCheckpoints = defaultMemoize(
  state =>
    JSON.parse(localStorage.getItem('checkpoints')) || get(state, 'checkpoints', []),
);

export const isFetchingAuth = defaultMemoize(state => state.Auth.isFetching || false);

export const shouldCheckpointShow = defaultMemoize(
  (state, slug) => !get(state, 'checkpoints', []).find(e => e === slug),
);

export const partnerMetaByCode = defaultMemoize((state, code) =>
  get(state, `partners.byCode[${code}]`, {}),
);

export const getAuthUser = defaultMemoize((state, code) => get(state, 'Auth.user', {}));
export const getAuthAccount = defaultMemoize((state, code) =>
  get(state, 'Auth.account', {}),
);
export const getIdToken = defaultMemoize(state => get(state, 'Auth.user.id_token', ''));
export const getAccessToken = defaultMemoize(state =>
  get(state, 'Auth.account.id_token', ''),
);
export const getAuthErrors = defaultMemoize(state => get(state, 'Auth.errors', []));

export const storedPartnerCode = defaultMemoize(state => get(state, `partners.code`, ''));

export const IsAuthenticated = defaultMemoize(state =>
  get(state, `Auth.user.isAuthenticated`, false),
);
export const IsInitLoading = defaultMemoize(state =>
  get(state, `Auth.isInitLoading`, true),
);

export const IsAccountAuthenticated = defaultMemoize(state =>
  get(state, `Auth.account.isAuthenticated`, false),
);

export const IsPartner = defaultMemoize(
  state => get(state, `Auth.user.partners`, []).length > 0,
);

export const PartnerIds = defaultMemoize(state => get(state, `Auth.user.partners`, []));

export const isFetchingUser = defaultMemoize(state =>
  get(state, 'Auth.isFetching', false),
);

export const AuthErrors = defaultMemoize(state => get(state, 'Auth.errors', {}));
export const ApiErrors = defaultMemoize(state => get(state, 'Auth.apiErrors', {}));

export const getAccountID = defaultMemoize(state => get(state, 'account.accountId', ''));
