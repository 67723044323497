import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual, omit } from 'lodash-es';

import oneTapLogo from '../../assets/images/one_tap_wordmark.png';
import logo from '../../assets/images/wordmark-white.svg';
import googleIcon from '../../assets/images/google_icon.svg';
import Button from '../../components/common/Button';
import { InputPassword, InputStyledTextField } from '../../components/inputs';
import SavviLoading from '../../components/SavviLoading';
import { clearErrors, setUserFromToken } from '../../redux/modules/User/actions';
import {
  loginUser,
  requestVerificationEmail,
  verifyEmail,
} from '../../redux/modules/User/operations';
import { AuthErrors, isFetchingUser } from '../../redux/modules/User/selectors';
import { validateEmail, validatePassword } from '../../utils/FeatureTypes';

import './Login.scss';
import { decodeToken } from '../../redux/modules/User/utils';
import { useHistory, useLocation } from 'react-router';
import { setNotice } from '../../redux/modules/UI/actions';

const Login = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const location = useLocation();
  const { hash, search, state: { redirectPathname, passedEmail } = {} } = location;

  const authErrors = useSelector(AuthErrors);
  const isFetching = useSelector(isFetchingUser);

  const [email, setEmail] = useState(passedEmail || '');
  const [prevAuthErrors, setPrevAuthErrors] = useState(authErrors);
  const [errors, setErrors] = useState(authErrors);
  const [isEmailVerified, setEmailVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isPasswordAllowed, setIsPasswordAllowed] = useState(true);
  const [isPasswordPlainText, setPasswordPlainText] = useState(false);
  const [password, setPassword] = useState('');
  const idTokenInjector = useRef();

  const isWhiteLabel = window.location.href.includes('paperos');

  const emailRef = useCallback(
    node => {
      if (node !== null && !isEmailVerified) {
        node.focus();
      }
    },
    [isEmailVerified],
  );

  const passwordRef = useCallback(
    node => {
      if (node !== null && isEmailVerified) {
        node.focus();
      }
    },
    [isEmailVerified],
  );

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (passedEmail) {
      dispatch(verifyEmail(passedEmail)).then(
        payload => {
          setEmailVerified(true);
          if (!payload.password_allowed) {
            setIsPasswordAllowed(false);
          }
          setErrors({});
        },
        error => {
          if (error.code === 'NOT_FOUND') {
            push({
              pathname: '/register',
              search,
              state: { email: passedEmail, redirectPathname },
            });
          }
        },
      );
    }
  }, [dispatch, passedEmail, push, redirectPathname, search]);

  useEffect(() => {
    if (!isEqual(authErrors, prevAuthErrors)) {
      setErrors({ ...errors, ...authErrors });
      setPrevAuthErrors(authErrors);
    }
  }, [authErrors, errors, prevAuthErrors]);

  const getCreds = () => ({
    email,
    password,
  });

  const injectGoogleAuth = () => {
    const decodedToken = decodeToken(idTokenInjector.current.value);
    dispatch(setUserFromToken(decodedToken));
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!isEmailVerified && !errors.email) {
      e.stopPropagation();
      setLoading(true);
      dispatch(verifyEmail(email)).then(
        payload => {
          if (!payload.password_allowed) {
            setIsPasswordAllowed(false);
          }
          setEmailVerified(true);
          setErrors({});
        },
        error => {
          if (error.code === 'NOT_FOUND') {
            push({ pathname: '/register', search, state: { email, redirectPathname } });
          }
        },
      );
      setTimeout(() => setLoading(false), 500);
    } else if (isEmailVerified) {
      if (!isPasswordAllowed) {
        dispatch(
          requestVerificationEmail({
            identifier: {
              type: 'email',
              value: email,
            },
            template: 'reset-password',
            state: { email, redirectLocation: redirectPathname || search },
          }),
        ).then(e => {
          dispatch(setNotice('Set new password email sent.'));
        });
      } else if (!errors.email && password && !errors.password) {
        dispatch(clearErrors());
        setErrors({});
        dispatch(loginUser(getCreds())).then(
          e => {
            setIsSubmitted(true);
          },
          error => {
            setIsSubmitted(true);
          },
        );
      }
    }
  };

  const validateLoginPassword = e => {
    const { name, value } = e.target;
    const password = validatePassword(
      value,
      6,
      'Password must be at least 6 characters long',
    );
    setErrors(!password ? omit(errors, name) : { ...errors, password });
  };

  const validateLoginEmail = e => {
    const { name, value } = e.target;
    const email = validateEmail(value);
    setErrors(!email ? omit(errors, name) : { ...errors, email });
  };

  const primaryActionLabel =
    (isEmailVerified && !isPasswordAllowed && 'Send New Password Link') ||
    (isEmailVerified && 'Login') ||
    'Next';
  return (
    <div className="login__container">
      <div className="login__logo">
        <img
          className="login__savvi"
          src={isWhiteLabel ? oneTapLogo : logo}
          alt="savvi-logo"
        />
        {isWhiteLabel && (
          <div className="login__paperos">
            <h4>powered by PaperOS</h4>
          </div>
        )}
      </div>
      <form className="login__form">
        {(isLoading || isFetching) && (
          <div className="login__loading-overlay">
            <SavviLoading size="md" />
          </div>
        )}
        <h2 className="login__heading">
          Welcome
          {isEmailVerified && (
            <>
              {','}
              <div
                className="login__emailLink"
                onClick={() => {
                  setErrors({});
                  setEmailVerified(false);
                }}
              >
                <b>{email}</b>
                <span className="login__link">
                  <FontAwesomeIcon icon={['fal', 'edit']} />
                </span>
              </div>
              <small className="login__sub-heading">
                {!isPasswordAllowed && <FontAwesomeIcon icon="exclamation-circle" />}
                {isPasswordAllowed
                  ? 'Please enter your password below.'
                  : 'You have not yet set a password on this account.'}
              </small>
            </>
          )}
          {!isEmailVerified && (
            <>
              {'!'}
              <small className="login__sub-heading">Please enter your email below.</small>
            </>
          )}
        </h2>
        <InputStyledTextField
          autoComplete="username"
          error={errors.email}
          inputClass="-js-login__input-email"
          isDataHidden={isEmailVerified}
          label={'Email'}
          name={'email'}
          onBlur={validateLoginEmail}
          onChange={e => {
            setEmail(e.target.value);
          }}
          ref={emailRef}
          type="email"
          value={email}
        />
        <InputPassword
          autoComplete="current-password"
          error={errors.password}
          hasToggle={true}
          inputClass="-js-login__input-password"
          isDataHidden={!isEmailVerified || !isPasswordAllowed}
          isDisabled={!isPasswordAllowed}
          isPlainText={isPasswordPlainText}
          label={'Password'}
          name={'password'}
          onBlur={ev => {
            if (!(ev.relatedTarget?.className || '').includes('send-email-link')) {
              validateLoginPassword(ev);
            }
          }}
          onChange={e => {
            setPassword(e.target.value);
          }}
          onToggle={() => setPasswordPlainText(!isPasswordPlainText)}
          ref={passwordRef}
          value={password}
        />
        {isSubmitted && errors.form && <div className="login__error">{errors.form}</div>}
        <div className="login__action">
          <Button
            className="-js-login__btn-main"
            isDisabled={isFetching}
            isFetching={isFetching}
            onClick={handleSubmit}
            type="submit"
          >
            {primaryActionLabel}
          </Button>
        </div>
        {isEmailVerified && (
          <div
            className={`login__linksWrapper ${
              passedEmail ? 'login__linksWrapper--center' : ''
            }`}
          >
            {!passedEmail && (
              <h4>
                {!isPasswordAllowed
                  ? 'Not wanting to use a password?'
                  : 'Forgot password?'}
              </h4>
            )}
            <Button
              buttonType="link"
              className="-js-login__btn-send-email-link"
              onClick={ev =>
                dispatch(
                  requestVerificationEmail({
                    identifier: { type: 'email', value: email },
                    template: 'magic-link',
                    state: { email, redirectLocation: redirectPathname || search },
                  }),
                ).then(e =>
                  push({
                    pathname: '/verify-code/magic-link',
                    state: {
                      email,
                      redirectLocation:
                        redirectPathname || search || '/c/0/company-select',
                    },
                  }),
                )
              }
              size="sm"
            >
              Login By Email Link
            </Button>
            {isPasswordAllowed && (
              <>
                {' | '}
                <Button
                  buttonType="link"
                  className="-js-login__btn-forgot-password"
                  to={{ pathname: '/forgot-password', state: { email } }}
                  size="sm"
                >
                  Reset Password
                </Button>
              </>
            )}
          </div>
        )}
      </form>
      {!isWhiteLabel && (
        <div className="login__action">
          <Button
            className="-js-login__btn-gauth"
            buttonType="secondary"
            isDisabled={isFetching}
            isFetching={isFetching}
            href={'/api/authn/oidc/accounts.google.com/auth'}
            onClick={e => {
              let redirectLoc = redirectPathname;
              if (search) {
                redirectLoc = search;
              }
              if (!!redirectLoc) {
                localStorage.setItem('redirectLoc', redirectLoc);
              }
              if (hash && redirectLoc) {
                let redirectHash = hash;
                if (redirectLoc.includes('%2F')) {
                  redirectHash = hash.replace('#', '%23');
                }
                localStorage.setItem('redirectHash', redirectHash);
              }
              return e;
            }}
          >
            <img src={googleIcon} alt="google-logo" />
            Login with Google
          </Button>
          {window.location.href.match(/localhost/) && (
            <div>
              <input ref={idTokenInjector} id="googleTokenInjector" />
              <button onClick={injectGoogleAuth} className="google-token-injector">
                Inject Google Auth Token
              </button>
            </div>
          )}
        </div>
      )}
      <div className="login__text">
        <p>
          By continuing, you're confirming that you've read our
          <Button
            buttonType="link"
            to={{
              pathname: '/terms',
              state: { background: location },
            }}
            size="sm"
          >
            Terms &amp; Conditions
          </Button>
        </p>
        <p>
          For help with any issues please contact{' '}
          <Button
            buttonType="link"
            size="sm"
            href={`mailto:support${isWhiteLabel ? '+onetap' : ''}@savvi.legal`}
            target="_blank"
            rel="noopener noreferrer"
          >
            our support
          </Button>
        </p>
      </div>
    </div>
  );
};

export default Login;
