import { RSAA } from 'redux-api-middleware';

// import filter from 'lodash-es/filter';
import get from 'lodash-es/get';

import { requestHeaders } from '../../utils/api';
import * as actions from './actions';
import { setNotice } from '../UI/actions';
import * as Types from './types';
import { getOutsideForm, getTransactionQuestionnaire } from '../Transaction/operations';
import { generateErrorNotice } from '../../../utils/generateErrorNotice';

export const getAllDocuments = accountId => {
  const REQUEST = Types[`GET_ALL_DOCUMENTS_REQUEST`];
  const SUCCESS = Types[`GET_ALL_DOCUMENTS_SUCCESS`];
  const FAILED = Types[`GET_ALL_DOCUMENTS_FAILED`];
  return async (dispatch, getState) => {
    dispatch(actions.clearDocumentError());
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/document_history',
        method: 'GET',
        headers: requestHeaders(true, accountId),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(actions.setAllDocuments(actionResponse.payload));
      return await actionResponse.payload;
    } else {
      if (actionResponse.payload.status !== 401) {
        await dispatch(
          setNotice(
            generateErrorNotice(
              actionResponse.payload,
              'An error has occurred loading your documents. If the documents are still not loading correctly after refreshing the page, please contact us.',
            ),
          ),
        );
      }
      throw await get(actionResponse, 'payload.message', 'An error has occurred.');
    }
  };
};

export const getUserDocuments = () => {
  const REQUEST = Types[`GET_USER_DOCUMENTS_REQUEST`];
  const SUCCESS = Types[`GET_USER_DOCUMENTS_SUCCESS`];
  const FAILED = Types[`GET_USER_DOCUMENTS_FAILED`];
  return async (dispatch, getState) => {
    dispatch(actions.clearDocumentError());
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/user/document',
        method: 'GET',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(actions.setUserDocuments(actionResponse.payload));
      return await actionResponse.payload;
    } else {
      if (actionResponse.payload.status !== 401) {
        await dispatch(
          setNotice({
            type: 'error',
            message: get(
              actionResponse,
              'payload.message',
              'An error has occurred loading your documents. Go ahead and refresh your page. If the documents are still not loading correctly, please contact us.',
            ),
          }),
        );
      }
      throw await get(actionResponse, 'payload.message', 'An error has occured.');
    }
  };
};

export const getDocuments = taskId => {
  let url = '/api/account/pandadoc/documents';
  if (taskId) {
    url = url + `?task_id=${taskId}`;
  }
  const REQUEST = Types[`GET_DOCUMENTS_REQUEST`];
  const SUCCESS = Types[`GET_DOCUMENTS_SUCCESS`];
  const FAILED = Types[`GET_DOCUMENTS_FAILED`];
  return async (dispatch, getState) => {
    dispatch(actions.clearDocumentError());
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: url,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(actions.setDocuments(actionResponse.payload, taskId));
      return await actionResponse.payload;
    } else {
      console.warn('Get documents error response', actionResponse.payload.message);
      await dispatch(
        actions.setDocumentError(
          get(
            actionResponse,
            'payload.message',
            'An error has occurred loading your documents. Go ahead and refresh your page. If the documents are still not loading correctly, please contact us.',
          ),
        ),
      );
      throw await get(actionResponse, 'payload.message', 'An error has occurred');
    }
  };
};

export const deleteDocumentUpload = (boxFileId, transactionId, accessCode) => {
  const REQUEST = Types[`DELETE_DOCUMENT_UPLOAD_REQUEST`];
  const SUCCESS = Types[`DELETE_DOCUMENT_UPLOAD_SUCCESS`];
  const FAILED = Types[`DELETE_DOCUMENT_UPLOAD_FAILED`];
  return async (dispatch, getState) => {
    dispatch(actions.clearDocumentError());
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/box/file/${boxFileId}`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice({ type: 'warning', message: 'Upload removed.' }));
      if (transactionId) {
        return await dispatch(getTransactionQuestionnaire(transactionId));
      } else if (accessCode) {
        return await dispatch(getOutsideForm(accessCode));
      }
    } else {
      await dispatch(
        setNotice({
          type: 'error',
          message: get(actionResponse, 'payload.message', 'Error removing upload.'),
        }),
      );
      await dispatch(
        actions.setDocumentError(
          get(
            actionResponse,
            'error',
            'An error has occurred loading your documents. Go ahead and refresh your page. If the documents are still not loading correctly, please contact us.',
          ),
        ),
      );
      throw await get(actionResponse, 'payload.message', 'An error has occurred');
    }
  };
};

export const resendLinkEmail = (docId, email) => {
  const REQUEST = Types[`RESEND_LINK_EMAIL_REQUEST`];
  const SUCCESS = Types[`RESEND_LINK_EMAIL_SUCCESS`];
  const FAILED = Types[`RESEND_LINK_EMAIL_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/pandadoc/documents/${docId}/resend`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify({ email }),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Link Sent'));
    } else {
      dispatch(setNotice({ type: 'error', message: 'Error resending email link.' }));
    }
  };
};

export const getProjectDocuments = projectId => {
  const REQUEST = Types[`GET_PROJECT_DOCUMENTS_REQUEST`];
  const SUCCESS = Types[`GET_PROJECT_DOCUMENTS_SUCCESS`];
  const FAILED = Types[`GET_PROJECT_DOCUMENTS_FAILED`];
  return async (dispatch, getState) => {
    await dispatch(actions.clearDocumentError());
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/project/${projectId}/documents`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(
        actions.setDocumentsByProject(actionResponse.payload, projectId),
      );
      // return actionResponse.payload;
    } else {
      await dispatch(
        actions.setDocumentError(
          get(
            actionResponse,
            'error',
            'An error has occurred loading your documents. Go ahead and refresh your page. If the documents are still not loading correctly, please contact us.',
          ),
        ),
      );
      throw await get(actionResponse, 'payload.message', 'An error has occurred');
    }
  };
};

export const getTransactionDocuments = transactionId => {
  const REQUEST = Types[`GET_TRANSACTION_DOCUMENTS_REQUEST`];
  const SUCCESS = Types[`GET_TRANSACTION_DOCUMENTS_SUCCESS`];
  const FAILED = Types[`GET_TRANSACTION_DOCUMENTS_FAILED`];
  return async (dispatch, getState) => {
    await dispatch(actions.clearDocumentError());
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/transaction/${transactionId}/documents`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(
        actions.setDocumentsByTransaction(actionResponse.payload, transactionId),
      );
      // return actionResponse.payload;
    } else {
      await dispatch(
        actions.setDocumentError(
          get(
            actionResponse,
            'error',
            'An error has occurred loading your documents. Go ahead and refresh your page. If the documents are still not loading correctly, please contact us.',
          ),
        ),
      );
      throw await get(actionResponse, 'payload.message', 'An error has occurred');
    }
  };
};

export const uploadDocument = (id, body, transactionId) => {
  const REQUEST = Types[`UPLOAD_DOCUMENT_REQUEST`];
  const SUCCESS = Types[`UPLOAD_DOCUMENT_SUCCESS`];
  const FAILED = Types[`UPLOAD_DOCUMENT_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/box/file/${id}`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice(get(actionResponse, 'payload.message', 'Document Uploaded')));
      return await actionResponse.payload;
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      throw actionResponse.payload.message;
    }
  };
};

export const setDocumentReviewer = (pandaDocId, body, transactionId) => {
  const REQUEST = Types[`SET_DOCUMENT_REVIEWER_REQUEST`];
  const SUCCESS = Types[`SET_DOCUMENT_REVIEWER_SUCCESS`];
  const FAILED = Types[`SET_DOCUMENT_REVIEWER_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/pandadoc/documents/${pandaDocId}/reviewer`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(
        setNotice(get(actionResponse, 'payload.message', 'Document Reviewer Set')),
      );
      return await dispatch(getTransactionDocuments(transactionId));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      throw actionResponse.payload.message;
    }
  };
};

export const setDocumentRedliningVersion = (
  document_id,
  revision_uuid,
  transactionId,
) => {
  const REQUEST = Types[`POST_REDLINING_VERSION_REQUEST`];
  const SUCCESS = Types[`POST_REDLINING_VERSION_SUCCESS`];
  const FAILED = Types[`POST_REDLINING_VERSION_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/pandadoc/documents/${document_id}/finalize/${revision_uuid}`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(getTransactionDocuments(transactionId));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      throw actionResponse.payload.message;
    }
  };
};
