import React, { useMemo, useState } from 'react';
import Modal from 'react-modal';

import './RoomSettings.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateRoom, addRoomCode } from '../../redux/modules/Data/operations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TooltipAction } from '../../components/common';
import { InputStyledTextField } from '../../components/inputs';
import { setNotice } from '../../redux/modules/UI/actions';
import { DropdownAction } from '../../components/common/Dropdown/Dropdown';
import { getAuthAccount, getAuthUser } from '../../redux/modules/User/selectors';
import { getRoomSharingMeta } from '../../redux/modules/Data/selectors';
import dayjs from 'dayjs/esm';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import RoomUserHistoryModal from '../../components/RoomUserHistoryModal';
import SortDropdown from '../../components/SortDropdown';

dayjs.extend(localizedFormat);

const defaultSort = (a, b) => {
  let aDate = new Date(a.lastVisited);
  let bDate = new Date(b.lastVisited);
  if (a.name === 'Anonymous') {
    return -1;
  } else if (b.name === 'Anonymous') {
    return 1;
  }
  return bDate - aDate;
};

export const sortStrings = (a, b) => a.localeCompare(b);

const usersSortOptions = {
  name: {
    label: 'Name',
    slug: 'name',
    iconType: 'a-z',
    sortFunc: (a, b) => sortStrings(a.name, b.name),
  },
  date_added: {
    label: 'Date Added',
    slug: 'date_added',
    iconType: 'wide-short',
    sortFunc: (a, b) => {
      let aDate = new Date(a.created_at);
      let bDate = new Date(b.created_at);
      return bDate - aDate;
    },
  },
  none: {
    label: 'Default (last visited)',
    slug: 'none',
    sortFunc: (a, b) => defaultSort(a, b),
  },
};

export const RoomSettings = ({
  access_code,
  account_id,
  is_admin_user,
  open,
  require_auth,
  roomId,
  strict,
}) => {
  const dispatch = useDispatch();
  const [isAddAccessCodeShowing, setIsAddAccessCodeShowing] = useState(false);
  const [isStandingFormsModalOpen, setIsStandingFormsModalOpen] = useState(false);
  const [isOtherFormsModalOpen, setIsOtherFormsModalOpen] = useState(false);
  const [isUserHistoryModalOpen, setIsUserHistoryModalOpen] = useState(false);
  const [selectedUserHistory, setSelectedUserHistory] = useState({});
  const [isRoomUsersCollapsed, setIsRoomUsersCollapsed] = useState(true);
  const currentUser = useSelector(getAuthUser);
  const currentAccount = useSelector(getAuthAccount);
  const roomSharingMeta = useSelector(State => getRoomSharingMeta(State, roomId));
  const {
    standingForms,
    standingFormLink,
    standingFormLinkLabel,
    standingFormsCompleted,
    otherForms,
    otherFormsCompleted,
    totalDocInteractions,
    totalTimesVisited,
    usersByEmail,
  } = roomSharingMeta;

  const [isSortReversed, setSortIsDesc] = useState(false);
  const [sortOption, setSortOption] = useState(usersSortOptions.none);
  const { slug, sortFunc } = sortOption;

  const sortedUsersByEmail = useMemo(() => {
    let updatedUsers = usersByEmail.sort(sortFunc);
    if (!isSortReversed) {
      updatedUsers = updatedUsers.reverse();
    }
    return updatedUsers;
  }, [usersByEmail, isSortReversed, sortFunc]);

  const handleSortUsers = selectedOption => {
    let updatedSortIsDesc = false;
    if (selectedOption.slug === slug) {
      updatedSortIsDesc = !isSortReversed;
    }
    setSortIsDesc(updatedSortIsDesc);
    setSortOption(selectedOption);
  };

  const handleUpdateRoom = slug => {
    let body = {};
    let currentSlugValue =
      (slug === 'open' && open) ||
      (slug === 'require_auth' && require_auth) ||
      (slug === 'strict' && strict);
    body[slug] = !currentSlugValue;
    if (slug === 'open' && currentSlugValue) {
      body.init_room = true;
    }
    dispatch(updateRoom(body, roomId, account_id));
  };

  const isRoomUsersCollapsible =
    !standingFormLink && otherForms.length === 0 && usersByEmail.length > 4;
  const toggleLabel = !isRoomUsersCollapsed
    ? 'Show fewer users'
    : `Show ${usersByEmail.length - 4} more users`;

  const canEdit = account_id === currentAccount.accountId && is_admin_user;
  const canInvite =
    canEdit ||
    usersByEmail.findIndex(
      user =>
        user.user_id === currentUser.userId && (user.admin_user || user.invite_rights),
    ) !== -1;
  const roomAccessLabel =
    (!open && 'Private') ||
    (!!strict && 'Invite Only') ||
    (!!require_auth && 'Sign-in Required') ||
    'Open';

  let accessHelpLabel = 'This room can be viewed by anyone.';
  if (!open) {
    accessHelpLabel = 'This room is closed and only the room admin can view it.';
  } else if (strict) {
    accessHelpLabel =
      'This room can be viewed by people whitelisted or emailed an access link.';
  } else if (require_auth) {
    accessHelpLabel = 'This room can be viewed by anyone logged in';
  }

  return (
    <div className="roomSettings__section">
      <div className="roomSettings__settings">
        <div className="roomSettings__subsection roomSettings__subsection--first">
          <div className="roomSettings__titleRow">
            <h2 className="roomSettings__subheader">Room Sharing & Access</h2>
            <div className="roomSettings__titleItems">
              <div className="roomSettings__access">
                <h4>Room Access</h4>
                <DropdownAction
                  className="roomSettings__dropdown"
                  left
                  Toggle={toggleProps => (
                    <Button
                      buttonType="link"
                      className="roomSettings__accessAction"
                      disabledTooltip="Room created after task submission"
                      isDisabled={!roomId}
                      {...toggleProps}
                    >
                      <FontAwesomeIcon
                        className="roomSettings__lock"
                        icon={['fal', 'lock-keyhole']}
                      />
                      <h3>{roomAccessLabel}</h3>
                      <FontAwesomeIcon
                        className="roomSettings__chevron"
                        icon="chevron-down"
                      />
                    </Button>
                  )}
                >
                  <div className="roomSettings__dropdownHeader">
                    <h4>Access Options</h4>
                    <div className="roomSettings__dropdownDivider" />
                  </div>
                  <Button
                    buttonType="secondary"
                    size="sm"
                    onClick={e => handleUpdateRoom('open')}
                  >
                    {open ? 'Close Room' : 'Open Room'}
                  </Button>
                  <Button
                    buttonType="secondary"
                    isDisabled={!open}
                    size="sm"
                    onClick={e => handleUpdateRoom('require_auth')}
                  >
                    {require_auth ? 'Sign-in Optional' : 'Sign-in Required'}
                  </Button>
                  <Button
                    buttonType="secondary"
                    isDisabled
                    disabledTooltip="Not Currently Available"
                    size="sm"
                    onClick={e => handleUpdateRoom('require_auth')}
                  >
                    {strict ? 'Invite Optional' : 'Invite Only'}
                  </Button>
                </DropdownAction>
                <h4>{accessHelpLabel}</h4>
              </div>
              <div className="roomSettings__titleItem">
                <h4>Total Visits</h4>
                <h3>{totalTimesVisited}</h3>
              </div>
              <div className="roomSettings__titleItem">
                <h4>
                  Total Interactions
                  <TooltipAction text="Anytime a user downloads or views a document while on a view in this Room will be recorded as an interaction." />
                </h4>
                <h3>{totalDocInteractions}</h3>
              </div>
            </div>
            <div className="roomSettings__titleActions">
              {canEdit && (
                <Button
                  buttonType="icon"
                  size="sm"
                  tooltip="Copy General Access Link"
                  onClick={() => {
                    const { REACT_APP_BASEURL } = process.env;
                    navigator.clipboard
                      .writeText(`${REACT_APP_BASEURL}/data-room/${access_code}`)
                      .then(
                        () => dispatch(setNotice('Link Copied!')),
                        err => console.error('Could not copy text: ', err),
                      );
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'copy']} />
                </Button>
              )}
              {canInvite && (
                <Button
                  buttonType="primary"
                  size="sm"
                  onClick={() => setIsAddAccessCodeShowing(true)}
                >
                  Share Room Link
                </Button>
              )}
            </div>
          </div>
          <div className="roomSettings__subsectionHeader">
            <p>
              Users and Personal Links
              <TooltipAction text="This section shows any users that have viewed or been shared a personal link to this Room." />
            </p>
            <div className="roomSettings__subsectionDivider" />
            {/* <SortDropdown
              isDisabled
              isSortReversed
              onSelect={() => {}}
              sortOption={'default'}
              sortOptions={{ default: { label: 'date' } }}
            /> */}
            <SortDropdown
              isDisabled={sortedUsersByEmail.length === 0}
              isSortReversed={isSortReversed}
              onSelect={handleSortUsers}
              sortOption={sortOption}
              sortOptions={usersSortOptions}
            />
          </div>
          {sortedUsersByEmail.map(
            (user, index) =>
              (!isRoomUsersCollapsible ||
                !isRoomUsersCollapsed ||
                (isRoomUsersCollapsed && index < 4)) && (
                <div
                  className={`roomSettings__card ${
                    index === 0 ? 'roomSettings__card--first' : ''
                  }`}
                  key={`room-user-${user.email || user.name}`}
                  onClick={ev => {
                    if (!ev.defaultPrevented) {
                      setSelectedUserHistory(user);
                      setIsUserHistoryModalOpen(true);
                    }
                  }}
                >
                  <div className="roomSettings__cardItem first">
                    <h3>
                      {user.name || user.email}
                      {!!user.name && !!user.email && (
                        <>
                          {' - '}
                          <span>{user.email}</span>
                        </>
                      )}
                    </h3>
                    <h4>
                      Last Visited:{' '}
                      {user.lastVisited
                        ? dayjs(user.lastVisited).format('LLL')
                        : 'not visited yet'}
                    </h4>
                  </div>
                  {user.key !== 'anonymous' && (
                    <div className="roomSettings__cardItem">
                      <h4>
                        Personal Link
                        <TooltipAction text="If a user joins this room with their personal link they will be able to view & sign any Room Documents needing their signature." />
                      </h4>
                      <p>{user.codes.length > 0 ? 'Yes' : 'No'}</p>
                    </div>
                  )}
                  <div className="roomSettings__cardItem">
                    <h4>Visits</h4>
                    <p>{user.timesVisited}</p>
                  </div>
                  <div className="roomSettings__cardItem">
                    <h4>Interactions</h4>
                    <p>{user.docInteractions}</p>
                  </div>
                  <div className="roomSettings__actions">
                    <FontAwesomeIcon icon="chevron-right" />
                  </div>
                </div>
              ),
          )}
          {isRoomUsersCollapsible && (
            <div
              className="roomSettings__toggleCollapse"
              onClick={ev => setIsRoomUsersCollapsed(!isRoomUsersCollapsed)}
            >
              <div className="roomSettings__toggleCollapseDivider" />
              <p>{toggleLabel}</p>
              <div className="roomSettings__toggleCollapseDivider" />
            </div>
          )}
        </div>
        {!!standingFormLink && (
          <div className="roomSettings__subsection">
            <div className="roomSettings__titleRow">
              <h2 className="roomSettings__subheader">
                Connected Forms
                <TooltipAction text="When someone visits this standing form link a new form connected to this room is generated." />
              </h2>
              <div className="roomSettings__titleItems">
                <div className="roomSettings__titleItem">
                  <h4>
                    standing form template
                    <TooltipAction text="name of the form template used when a new form is generated" />
                  </h4>
                  <p>{standingFormLinkLabel}</p>
                </div>
                {standingForms.length > 0 && (
                  <div className="roomSettings__titleItem">
                    <h4>Forms Generated</h4>
                    <h3>{standingForms.length}</h3>
                  </div>
                )}
                {standingForms.length > 0 && (
                  <div className="roomSettings__titleItem">
                    <h4>Forms Completed</h4>
                    <h3>{standingFormsCompleted}</h3>
                  </div>
                )}
                {otherForms.length > 0 && (
                  <div className="roomSettings__titleItem">
                    <h4>Other Forms</h4>
                    <h3>{otherForms.length}</h3>
                  </div>
                )}
              </div>
              <div className="roomSettings__titleActions">
                <Button
                  size="sm"
                  onClick={() => {
                    const { REACT_APP_BASEURL } = process.env;
                    navigator.clipboard
                      .writeText(`${REACT_APP_BASEURL}/api/redirect/form/${access_code}`)
                      .then(
                        () => dispatch(setNotice('Link Copied!')),
                        err => console.error('Could not copy text: ', err),
                      );
                  }}
                >
                  Copy New Standing Form Link
                </Button>
              </div>
            </div>
            <div className="roomSettings__card roomSettings__card--flat">
              <div className="roomSettings__cardItem first" />
              <div className="roomSettings__actions">
                <Button
                  className="roomSettings__titleAction--wide"
                  buttonType="secondary"
                  size="sm"
                  onClick={() => setIsStandingFormsModalOpen(true)}
                >
                  View Standing Forms
                </Button>
              </div>
            </div>
          </div>
        )}
        {otherForms.length > 0 && (
          <div className="roomSettings__subsection">
            <div className="roomSettings__titleRow">
              <h2 className="roomSettings__subheader">
                Connected Forms
                <TooltipAction text="Any Shared Forms that are connected to this Room, Standing Forms not included." />
              </h2>
              <div className="roomSettings__titleItems">
                <div className="roomSettings__titleItem">
                  <h4>Forms Connected</h4>
                  <h3>{otherForms.length}</h3>
                </div>
                <div className="roomSettings__titleItem">
                  <h4>Forms Completed</h4>
                  <h3>{otherFormsCompleted}</h3>
                </div>
              </div>
              <div className="roomSettings__titleActions">
                <Button
                  buttonType="secondary"
                  size="sm"
                  onClick={() => setIsOtherFormsModalOpen(true)}
                >
                  View Connected Forms
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <AddUserModal
        access_code={access_code}
        account_id={account_id}
        isOpen={isAddUserShowing}
        onClose={() => setIsAddUserShowing(false)}
        roomId={roomId}
      /> */}
      <RoomUserHistoryModal
        account_id={account_id}
        handleClose={() => setIsUserHistoryModalOpen(false)}
        isOpen={isUserHistoryModalOpen}
        roomId={roomId}
        roomUserHistory={selectedUserHistory || {}}
      />
      <ShareRoomLinkModal
        access_code={access_code}
        account_id={account_id}
        isOpen={isAddAccessCodeShowing}
        onClose={() => setIsAddAccessCodeShowing(false)}
        roomId={roomId}
      />
      <RoomStandingFormsModal
        account_id={account_id}
        canEdit={canEdit}
        currentUser={currentUser}
        is_admin_user={is_admin_user}
        isOpen={isStandingFormsModalOpen}
        isRoomOpen={!!open}
        onClose={() => setIsStandingFormsModalOpen(false)}
        otherForms={otherForms}
        roomId={roomId}
        standingForms={standingForms}
      />
      <RoomOtherFormsModal
        account_id={account_id}
        canEdit={canEdit}
        currentUser={currentUser}
        is_admin_user={is_admin_user}
        isOpen={isOtherFormsModalOpen}
        onClose={() => setIsOtherFormsModalOpen(false)}
        otherForms={otherForms}
        roomId={roomId}
        standingForms={standingForms}
      />
    </div>
  );
};

export const ShareRoomLinkModal = ({
  access_code,
  account_id,
  isOpen,
  onClose,
  roomId,
}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setEmail('');
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="roomSettings__modal roomSettings__modal--sm"
      overlayClassName="roomSettings__modal-overlay"
    >
      <FontAwesomeIcon
        className="roomSettings__modal-exit"
        onClick={handleClose}
        icon={['fal', 'times']}
      />
      <h2>Share Personal Room Link</h2>
      <InputStyledTextField
        onChange={e => setEmail(e.target.value)}
        defaultPlaceholder="ex. myname@gmail.com"
        value={email}
        label="email to share code with"
        name="user_email"
      />
      <h4>
        This shares a link that will automatically log in and direct them to this Room. If
        that email does not already have an account we will automatically create a guest
        account for them.
      </h4>
      <div className="roomSettings__modalActions">
        <Button
          isDisabled={isSubmitting}
          isFetching={isSubmitting}
          onClick={() => {
            setIsSubmitting(true);
            dispatch(
              addRoomCode(
                { identifier: { type: 'email', value: email } },
                roomId,
                account_id,
              ),
            ).then(
              e => {
                setIsSubmitting(false);
                handleClose();
              },
              error => setIsSubmitting(false),
            );
          }}
        >
          Send Email
        </Button>
      </div>
    </Modal>
  );
};

export const RoomStandingFormsModal = ({
  // canEdit,
  // account_id,
  // currentUser,
  isOpen,
  isRoomOpen,
  // is_admin_user,
  onClose,
  // roomId,
  standingForms,
}) => {
  const dispatch = useDispatch();

  const sortedForms = standingForms.sort((a, b) => {
    if (a.outside_form.state === 'new' && b.outside_form.state !== 'new') {
      return 1;
    } else if (a.outside_form.state !== 'new' && b.outside_form.state === 'new') {
      return -1;
    }
    return 0;
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterClose={ev => {
        document.body.classList.remove('ReactModal__Body--open');
      }}
      className="roomSettings__modal roomSettings__modal--sm"
      overlayClassName="roomSettings__modal-overlay"
    >
      <FontAwesomeIcon
        className="roomSettings__modal-exit"
        onClick={onClose}
        icon={['fal', 'times']}
      />
      <h2>View Standing Forms</h2>
      <p>View and Manage Standing Forms Connected to this Room.</p>
      <div className="room__subsection room__subsection--last">
        {sortedForms.map(
          (
            {
              // code,
              id,
              // room_id,
              created_at,
              identifier,
              // identifier_type,
              // isWhitelisted,
              user: { email, name = '', invite_rights, id: userId, room_user_id } = {},
              outside_form: {
                access_code: outside_form_access_code,
                state,
                id: outside_form_id,
                name: outsideFormLabel,
                metadata: { type } = {},
              } = {},
            },
            ind,
          ) => {
            let label =
              name.trim() || email || identifier || outsideFormLabel || 'Unique Link';
            let formUrl = `/data-room/${outside_form_access_code}/outside-form/${outside_form_access_code}`;
            if (!isRoomOpen) {
              formUrl = `/outside-form/${outside_form_access_code}`;
            }
            return (
              <div
                className="roomSettings__modalCard"
                key={`room-code-${id || room_user_id}`}
              >
                <div className="roomSettings__codeRow">
                  <p>{label}</p>
                  <div className="roomSettings__titleActions">
                    <Button
                      buttonType="icon"
                      size="xs"
                      tooltip="Copy link"
                      tooltipAlign="right"
                      onClick={() => {
                        const { REACT_APP_BASEURL } = process.env;
                        navigator.clipboard
                          .writeText(`${REACT_APP_BASEURL}${formUrl}`)
                          .then(
                            () => dispatch(setNotice('Link Copied!')),
                            err => console.error('Could not copy text: ', err),
                          );
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'copy']} />
                    </Button>
                    <Button
                      buttonType="icon"
                      size="xs"
                      tooltip="Go to shared form"
                      tooltipAlign="right"
                      to={formUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={['fal', 'external-link']} />
                    </Button>
                  </div>
                </div>
                <div className="roomSettings__codeRow">
                  <div className="roomSettings__col roomSettings__col--first">
                    <h4>Date Created</h4>
                    <p>{dayjs(created_at).format('LLL')}</p>
                  </div>
                  <div className="roomSettings__col">
                    <h4>status</h4>
                    <p>{state === 'new' ? 'Open' : 'Completed'}</p>
                  </div>
                </div>
              </div>
            );
          },
        )}
      </div>
    </Modal>
  );
};

export const RoomOtherFormsModal = ({
  // canEdit,
  // account_id,
  // currentUser,
  isOpen,
  isRoomOpen,
  // is_admin_user,
  onClose,
  // roomId,
  otherForms,
}) => {
  const dispatch = useDispatch();

  const sortedForms = otherForms.sort((a, b) => {
    if (a.outside_form.state === 'new' && b.outside_form.state !== 'new') {
      return 1;
    } else if (a.outside_form.state !== 'new' && b.outside_form.state === 'new') {
      return -1;
    }
    return 0;
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="roomSettings__modal roomSettings__modal--sm"
      overlayClassName="roomSettings__modal-overlay"
    >
      <FontAwesomeIcon
        className="roomSettings__modal-exit"
        onClick={onClose}
        icon={['fal', 'times']}
      />
      <h2>View Shared Forms</h2>
      <p>View and Manage Shared Forms connected to this Room.</p>
      <div className="room__subsection room__subsection--last">
        {sortedForms.map(
          (
            {
              // code,
              id,
              // room_id,
              created_at,
              identifier,
              // identifier_type,
              // isWhitelisted,
              user: { email, name = '', invite_rights, id: userId, room_user_id } = {},
              outside_form: {
                access_code: outside_form_access_code,
                state,
                name: outsideFormLabel,
                metadata: { finalize } = {},
              } = {},
            },
            ind,
          ) => {
            let label =
              name.trim() || email || identifier || outsideFormLabel || 'Unique Link';
            let formUrl = `/data-room/${outside_form_access_code}/outside-form/${outside_form_access_code}`;
            if (!isRoomOpen) {
              formUrl = `/outside-form/${outside_form_access_code}`;
            }
            return (
              <div
                className="roomSettings__modalCard"
                key={`room-code-${id || room_user_id}`}
              >
                <div className="roomSettings__codeRow">
                  <p>{label}</p>
                  <div className="roomSettings__titleActions">
                    <Button
                      buttonType="icon"
                      size="xs"
                      tooltip="Copy link"
                      tooltipAlign="right"
                      onClick={() => {
                        const { REACT_APP_BASEURL } = process.env;
                        navigator.clipboard
                          .writeText(`${REACT_APP_BASEURL}${formUrl}`)
                          .then(
                            () => dispatch(setNotice('Link Copied!')),
                            err => console.error('Could not copy text: ', err),
                          );
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'copy']} />
                    </Button>
                    <Button
                      buttonType="icon"
                      size="xs"
                      tooltip="Go to shared form"
                      tooltipAlign="right"
                      to={formUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={['fal', 'external-link']} />
                    </Button>
                  </div>
                </div>
                <div className="roomSettings__codeRow">
                  <div className="roomSettings__col roomSettings__col--first">
                    <h4>Date Created</h4>
                    <p>{dayjs(created_at).format('LLL')}</p>
                  </div>
                  <div className="roomSettings__col">
                    <h4>status</h4>
                    <div className="roomSettings__tags">
                      <p className={state === 'new' ? 'low' : ''}>
                        {state === 'new' ? 'Open' : 'Completed'}
                      </p>
                      {finalize && <p className="low">Finalizes task on submission</p>}
                    </div>
                  </div>
                </div>
              </div>
            );
          },
        )}
      </div>
    </Modal>
  );
};

// const AddUserModal = ({ accessCode, account_id, isOpen, onClose, roomId }) => {
//   const dispatch = useDispatch();
//   const [email, setEmail] = useState('');
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const handleClose = () => {
//     setEmail('');
//     onClose();
//   };
//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={handleClose}
//       className="roomSettings__modal roomSettings__modal--sm"
//       overlayClassName="roomSettings__modal-overlay"
//     >
//       <FontAwesomeIcon
//         className="roomSettings__modal-exit"
//         onClick={handleClose}
//         icon={['fal', 'times']}
//       />
//       <h2>Add User to Room</h2>
//       <InputStyledTextField
//         onChange={e => setEmail(e.target.value)}
//         defaultPlaceholder="ex. myname@gmail.com"
//         value={email}
//         label="user email:"
//         name="user_email"
//       />
//       <div className="roomSettings__modalActions">
//         <Button
//           isDisabled={isSubmitting}
//           isFetching={isSubmitting}
//           onClick={() => {
//             setIsSubmitting(true);
//             dispatch(addRoomUser({ email, room_id: roomId }, roomId, account_id, accessCode)).then(
//               e => {
//                 setIsSubmitting(false);
//                 handleClose();
//               },
//               error => setIsSubmitting(false),
//             );
//           }}
//         >
//           Add User
//         </Button>
//       </div>
//     </Modal>
//   );
// };
