export const optionsAbbr = [
  { value: 'AL', label: 'Alabama', disabled: true },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  // { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  // { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Pennsylvania' },
  // { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const options = [
  // { value: '', label: 'Please Select a State', disabled: true},
  { value: 'Utah', label: 'Utah', is_state: true },
  { value: 'Alabama', label: 'Alabama', is_state: true },
  { value: 'Alaska', label: 'Alaska', is_state: true },
  { value: 'Arizona', label: 'Arizona', is_state: true },
  { value: 'Arkansas', label: 'Arkansas', is_state: true },
  { value: 'California', label: 'California', is_state: true },
  { value: 'Colorado', label: 'Colorado', is_state: true },
  { value: 'Connecticut', label: 'Connecticut', is_state: true },
  { value: 'Delaware', label: 'Delaware', is_state: true },
  {
    value: 'District Of Columbia',
    label: 'District Of Columbia',
    is_state: true,
  },
  // { value: 'Federated States Of Micronesia', label: 'Federated States Of Micronesia', is_state: true },
  { value: 'Florida', label: 'Florida', is_state: true },
  { value: 'Georgia', label: 'Georgia', is_state: true },
  // { value: 'Guam', label: 'Guam', is_state: true },
  { value: 'Hawaii', label: 'Hawaii', is_state: true },
  { value: 'Idaho', label: 'Idaho', is_state: true },
  { value: 'Illinois', label: 'Illinois', is_state: true },
  { value: 'Indiana', label: 'Indiana', is_state: true },
  { value: 'Iowa', label: 'Iowa', is_state: true },
  { value: 'Kansas', label: 'Kansas', is_state: true },
  { value: 'Kentucky', label: 'Kentucky', is_state: true },
  { value: 'Louisiana', label: 'Louisiana', is_state: true },
  { value: 'Maine', label: 'Maine', is_state: true },
  // { value: 'Marshall Islands', label: 'Marshall Islands', is_state: true },
  { value: 'Maryland', label: 'Maryland', is_state: true },
  { value: 'Massachusetts', label: 'Massachusetts', is_state: true },
  { value: 'Michigan', label: 'Michigan', is_state: true },
  { value: 'Minnesota', label: 'Minnesota', is_state: true },
  { value: 'Mississippi', label: 'Mississippi', is_state: true },
  { value: 'Missouri', label: 'Missouri', is_state: true },
  { value: 'Montana', label: 'Montana', is_state: true },
  { value: 'Nebraska', label: 'Nebraska', is_state: true },
  { value: 'Nevada', label: 'Nevada', is_state: true },
  { value: 'New Hampshire', label: 'New Hampshire', is_state: true },
  { value: 'New Jersey', label: 'New Jersey', is_state: true },
  { value: 'New Mexico', label: 'New Mexico', is_state: true },
  { value: 'New York', label: 'New York', is_state: true },
  { value: 'North Carolina', label: 'North Carolina', is_state: true },
  { value: 'North Dakota', label: 'North Dakota', is_state: true },
  // { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands', is_state: true },
  { value: 'Ohio', label: 'Ohio', is_state: true },
  { value: 'Oklahoma', label: 'Oklahoma', is_state: true },
  { value: 'Oregon', label: 'Oregon', is_state: true },
  // { value: 'Palau', label: 'Palau', is_state: true },
  { value: 'Pennsylvania', label: 'Pennsylvania', is_state: true },
  // { value: 'Puerto Rico', label: 'Puerto Rico', is_state: true },
  { value: 'Rhode Island', label: 'Rhode Island', is_state: true },
  { value: 'South Carolina', label: 'South Carolina', is_state: true },
  { value: 'South Dakota', label: 'South Dakota', is_state: true },
  { value: 'Tennessee', label: 'Tennessee', is_state: true },
  { value: 'Texas', label: 'Texas', is_state: true },
  { value: 'Vermont', label: 'Vermont', is_state: true },
  { value: 'Virgin Islands', label: 'Virgin Islands', is_state: true },
  { value: 'Virginia', label: 'Virginia', is_state: true },
  { value: 'Washington', label: 'Washington', is_state: true },
  { value: 'West Virginia', label: 'West Virginia', is_state: true },
  { value: 'Wisconsin', label: 'Wisconsin', is_state: true },
  { value: 'Wyoming', label: 'Wyoming', is_state: true },
];

export const feesByState = {
  Alabama: { fee: 375, abbr: 'AL' },
  Alaska: { fee: 375, abbr: 'AK' },
  Arizona: { fee: 625, abbr: 'AZ' },
  Arkansas: { fee: 375, abbr: 'AR' },
  California: { fee: 256, abbr: 'CA' },
  Colorado: { fee: 260, abbr: 'CO' },
  Connecticut: { fee: 375, abbr: 'CT' },
  Delaware: { fee: 204, abbr: 'DE' },
  'District of Columbia': { fee: 355, abbr: 'DC' },
  Florida: { fee: 205, abbr: 'FL' },
  Georgia: { fee: 375, abbr: 'GA' },
  // Guam: { fee: 375, abbr: 'GU' },
  Hawaii: { fee: 375, abbr: 'HI' },
  Idaho: { fee: 375, abbr: 'ID' },
  Illinois: { fee: 310, abbr: 'IL' },
  Indiana: { fee: 375, abbr: 'IN' },
  Iowa: { fee: 375, abbr: 'IA' },
  Kansas: { fee: 375, abbr: 'KS' },
  Kentucky: { fee: 375, abbr: 'KY' },
  Louisiana: { fee: 375, abbr: 'LA' },
  Maine: { fee: 375, abbr: 'ME' },
  Maryland: { fee: 375, abbr: 'MD' },
  Massachusetts: { fee: 537, abbr: 'MA' },
  Michigan: { fee: 375, abbr: 'MI' },
  Minnesota: { fee: 375, abbr: 'MN' },
  Mississippi: { fee: 375, abbr: 'MS' },
  Missouri: { fee: 375, abbr: 'MO' },
  Montana: { fee: 375, abbr: 'MT' },
  Nebraska: { fee: 375, abbr: 'NE' },
  Nevada: { fee: 335, abbr: 'NV' },
  'New Hampshire': { fee: 375, abbr: 'NH' },
  'New Jersey': { fee: 265, abbr: 'NJ' },
  'New Mexico': { fee: 375, abbr: 'NM' },
  'New York': { fee: 370, abbr: 'NY' },
  'North Carolina': { fee: 385, abbr: 'NC' },
  'North Dakota': { fee: 375, abbr: 'ND' },
  Ohio: { fee: 375, abbr: 'OH' },
  Oklahoma: { fee: 375, abbr: 'OK' },
  Oregon: { fee: 410, abbr: 'OR' },
  Other: { fee: 375, abbr: 'OTR' },
  // Palau: { fee: 375, abbr: 'PW' },
  Pennsylvania: { fee: 685, abbr: 'PA' },
  // 'Puerto Rico': { fee: 375, abbr: 'PR' },
  'Rhode Island': { fee: 285, abbr: 'RI' },
  'South Carolina': { fee: 375, abbr: 'SC' },
  'South Dakota': { fee: 375, abbr: 'SD' },
  Tennessee: { fee: 375, abbr: 'TN' },
  Texas: { fee: 885, abbr: 'TX' },
  Utah: { fee: 205, abbr: 'UT' },
  Vermont: { fee: 375, abbr: 'VT' },
  Virginia: { fee: 235, abbr: 'VA' },
  Washington: { fee: 315, abbr: 'WA' },
  'West Virginia': { fee: 375, abbr: 'WV' },
  Wisconsin: { fee: 375, abbr: 'WI' },
  Wyoming: { fee: 375, abbr: 'WY' },
};
