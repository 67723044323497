import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentPicker from 'box-ui-elements/es/elements/content-picker';
import ContentPreview from 'box-ui-elements/es/elements/content-preview';
import ContentUploader from 'box-ui-elements/es/elements/content-uploader';
import classnames from 'classnames';
import get from 'lodash-es/get';

// import { deleteDocumentUpload } from '../../redux/modules/Transaction/operations';
import savviLogo from '../../assets/images/savvi_logo.svg';
import {
  getBoxFileForDataRoom,
  getBoxFileInfo,
  getBoxTokenFolderId,
  uploadBoxFile,
} from '../../redux/modules/Cabinet/operations';
import {
  BoxToken,
  IsTempBoxTokenFetchInitialized,
} from '../../redux/modules/Cabinet/selectors';
import { CurrentCompany } from '../../redux/modules/Company/selectors';

import './FileUploader.scss';
import { createRoomHistory } from '../../redux/modules/Data/operations';
import { Button } from '../common';

class FileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      folder: null,
      isDeleteOpen: false,
      isInitialFetching: true,
      error: '',
      isPickerOpen: false,
      isReuploadOpen: false,
      isDocViewed: false,
    };
    this.uploaderRef = createRef();
    this.innerUploaderRef = createRef();
    this.previewRef = createRef();
  }

  componentDidMount() {
    const {
      access_code,
      fileId,
      getBoxTokenFolderId,
      getBoxFileForDataRoom,
      path,
      reports_feature_type_id,
      resource_id,
    } = this.props;

    if (path && !fileId) {
      getBoxTokenFolderId(path)
        .then(payload => {
          this.setState({
            isInitialFetching: false,
            folder: payload.upload_folder_id,
          });
        })
        .catch(error => this.setState({ error }));
    } else if (access_code && reports_feature_type_id && fileId) {
      getBoxFileForDataRoom(
        fileId,
        access_code,
        `?reports_feature_type_id=${reports_feature_type_id}&resource_id=${resource_id}`,
      ).then(
        payload =>
          this.setState({
            accessToken: payload.token.accessToken,
            isInitialFetching: false,
          }),
        error => this.setState({ error: error.message, isInitialFetching: false }),
      );
    } else {
      this.setState({ isInitialFetching: false });
    }
  }

  render() {
    const {
      access_code,
      account_transaction_id,
      boxToken,
      createRoomHistory,
      currentCompany,
      feature_type_id,
      filename,
      fileId,
      handleGotoCabinet,
      isDisabled,
      isLocked,
      label,
      reports_feature_type_id,
      multiEntry,
      onUpload,
      push,
      resource_id,
      resource_name,
      roomId,
      tags,
      uploadBoxFile,
      usePathAsRootFolder,
    } = this.props;

    const {
      accessToken,
      error,
      folder,
      isInitialFetching,
      isPickerOpen,
      isDocViewed,
      isReuploadOpen,
    } = this.state;
    let collection = [];
    if (fileId && fileId.includes('|')) {
      collection = fileId.split('|');
    }

    const boxRootFolderId = get(currentCompany, 'account.box_root_folder_id', '0');

    const handleResponseInterceptor = e => {
      if (e.type === 'error' || e instanceof Error) {
        this.setState({
          error: e.message || 'Unable to preview file at this time.',
        });
      } else if (access_code && reports_feature_type_id && !isDocViewed) {
        this.setState({ isDocViewed: true });
        createRoomHistory(
          access_code,
          {
            box_file_id: fileId,
            action: !e.data.download_url ? 'view' : 'download',
            customer_label: `${label} (${resource_name})`,
            file_label: filename,
          },
          roomId,
        );
      }
      return e;
    };

    if (!isInitialFetching && !boxToken && !folder && !reports_feature_type_id) {
      return (
        <div className="file-uploader-error">
          {error || 'Unable to load, please refresh and try again.'}
        </div>
      );
    }
    return (
      <>
        {isInitialFetching ? (
          <div className="loading">
            <FontAwesomeIcon icon="spinner" spin />
          </div>
        ) : (
          <>
            {!!fileId && !isPickerOpen && !isReuploadOpen && (
              <div
                className={classnames('file-uploader-content-preview', {
                  'file-uploader-content-preview--multi':
                    !fileId && (!!multiEntry || isLocked),
                  'file-uploader-content-preview--error': !!error,
                })}
              >
                {!!boxToken && !isLocked && fileId && (
                  <>
                    {collection.length === 0 && (
                      <ContentPreview
                        fileId={fileId}
                        token={boxToken}
                        responseInterceptor={handleResponseInterceptor}
                        hasHeader
                        onLoad={e => {
                          e.viewer.zoomOut(0.32);
                        }}
                      />
                    )}
                    {collection.length > 0 && (
                      <ContentPreview
                        fileId={collection[0]}
                        collection={collection}
                        token={boxToken}
                        responseInterceptor={handleResponseInterceptor}
                        hasHeader
                        onLoad={e => {
                          e.viewer.zoomOut(0.32);
                        }}
                      />
                    )}
                  </>
                )}
                {isLocked && !fileId && <p>File not saved</p>}
                {!!reports_feature_type_id && !!fileId && !!accessToken && (
                  <>
                    {collection.length === 0 && (
                      <ContentPreview
                        fileId={fileId}
                        token={accessToken}
                        responseInterceptor={handleResponseInterceptor}
                        hasHeader
                        onLoad={e => {
                          e.viewer.zoomOut(0.32);
                        }}
                      />
                    )}
                    {collection.length > 0 && (
                      <ContentPreview
                        fileId={collection[0]}
                        collection={collection}
                        token={accessToken}
                        responseInterceptor={handleResponseInterceptor}
                        hasHeader
                        onLoad={e => {
                          e.viewer.zoomOut(0.32);
                        }}
                      />
                    )}
                  </>
                )}
                {!!fileId && !!error && (
                  <p>File{multiEntry ? '(s)' : ''} saved to Company File Cabinet.</p>
                )}
                {!isDisabled && (
                  <div className="file-uploader__actions">
                    {!isLocked && !access_code && (
                      <button
                        className="file-uploader__remove-upload"
                        onClick={e => this.setState({ isPickerOpen: true })}
                        type="button"
                      >
                        <FontAwesomeIcon icon={['fal', 'file-alt']} />
                        Select different file{!!multiEntry && '(s)'}
                      </button>
                    )}
                    {!isLocked && !!access_code && (
                      <button
                        className="file-uploader__remove-upload"
                        onClick={e => this.setState({ isReuploadOpen: true })}
                        type="button"
                      >
                        <FontAwesomeIcon icon={['fal', 'file-alt']} />
                        Upload Different File{!!multiEntry ? '(s)' : ''}
                      </button>
                    )}
                    {!access_code && (
                      <>
                        <button
                          className="file-uploader__primary-action"
                          onClick={e =>
                            handleGotoCabinet(
                              collection.length > 0 ? collection[0] : fileId,
                              push,
                            )
                          }
                          type="button"
                        >
                          View in File Cabinet
                          {!!multiEntry && <span>*</span>}
                        </button>
                      </>
                    )}
                  </div>
                )}
                {!isDisabled && !access_code && !!multiEntry && (
                  <p className="file-uploader__sublabel">
                    <span>*</span>other files from a batch upload can be viewed in the
                    File Cabinet
                  </p>
                )}
              </div>
            )}
            {((!fileId && !isPickerOpen && !reports_feature_type_id) ||
              isReuploadOpen) && (
              <div
                className={classnames('file-uploader__content-uploader', {
                  disabled: isDisabled,
                  'file-uploader__content-uploader--hidden-footer': !multiEntry,
                })}
              >
                {!!boxToken && (
                  <>
                    <ContentUploader
                      fileLimit={!multiEntry ? 1 : 100}
                      onBeforeUpload={e => {
                        if (!multiEntry) {
                          setTimeout(() =>
                            this.uploaderRef.current.lastElementChild.lastElementChild.lastElementChild.click(),
                          );
                        }
                      }}
                      token={boxToken}
                      measureRef={this.uploaderRef}
                      rootFolderId={folder || boxRootFolderId}
                      onUpload={e => {
                        uploadBoxFile(e.id, {
                          tags: tags || [],
                          feature_type_id,
                          filename,
                          resource_id,
                          resource_name,
                          account_transaction_id,
                        });
                      }}
                      onComplete={e => {
                        let fileIdArr = e.map(file => file.id);
                        onUpload(fileIdArr.join('|'));
                        this.setState({ isReuploadOpen: false });
                      }}
                    />
                    {!access_code && !isDisabled && (
                      <button
                        onClick={() => this.setState({ isPickerOpen: true })}
                        type="button"
                        className="file-uploader__primary-action align-right"
                      >
                        Choose previously uploaded file{!!multiEntry ? 's' : ''}
                      </button>
                    )}
                    {!!access_code && !isDisabled && isReuploadOpen && (
                      <Button
                        buttonType="secondary"
                        size="sm"
                        onClick={() => this.setState({ isReuploadOpen: false })}
                        type="button"
                        className="align-right"
                      >
                        Cancel
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
            {isPickerOpen && boxToken && !reports_feature_type_id && (
              <div
                className={classnames('file-uploader__content-uploader', {
                  disabled: isDisabled,
                  'file-uploader__content-uploader--hidden-footer': !multiEntry,
                })}
              >
                <ContentPicker
                  autoFocus
                  canCreateNewFolder={false}
                  canSetShareAccess={false}
                  canUpload
                  contentUploaderProps={{
                    fileLimit: !multiEntry ? 1 : 100,
                    onBeforeUpload: e => {
                      if (!multiEntry) {
                        setTimeout(() =>
                          this.innerUploaderRef.current.lastElementChild.lastElementChild.lastElementChild.click(),
                        );
                      }
                    },
                    measureRef: this.innerUploaderRef,
                    onUpload: e => {
                      uploadBoxFile(e.id, {
                        tags: tags || [],
                        feature_type_id,
                        filename,
                        resource_id,
                        resource_name,
                        account_transaction_id,
                      });
                    },
                    onComplete: e => {
                      let fileIdArr = e.map(file => file.id);
                      onUpload(fileIdArr.join('|')).then(payload => {
                        this.setState({ isPickerOpen: false });
                      });
                    },
                  }}
                  isSmall
                  logoUrl={savviLogo}
                  maxSelectable={!multiEntry ? 1 : 100}
                  rootFolderId={(usePathAsRootFolder && folder) || boxRootFolderId}
                  token={boxToken}
                  measureRef={this.uploaderRef}
                  onCancel={e => this.setState({ isPickerOpen: false })}
                  onChoose={e => {
                    this.setState({ isPickerOpen: false });
                    let fileIdArr = e.map(file => file.id);
                    onUpload(fileIdArr.join('|'));
                  }}
                />
                <button
                  onClick={() => this.setState({ isPickerOpen: false })}
                  type="button"
                  className="file-uploader__primary-action"
                >
                  {fileId ? 'Cancel' : 'Back to upload file'}
                </button>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (State, ownProps) => ({
  isTempBoxTokenFetchInitialized: IsTempBoxTokenFetchInitialized(State),
  boxToken: BoxToken(State),
  currentCompany: CurrentCompany(State),
});

const mapDispatchToProps = (
  dispatch,
  { accountId, access_code, companyId, isOutsideForm },
) => ({
  createRoomHistory: (accessCode, body, roomId) =>
    dispatch(createRoomHistory(accessCode, body, roomId)),
  handleGotoCabinet: (fileId, push) => dispatch(getBoxFileInfo(fileId, push, companyId)),
  getBoxFileForDataRoom: (boxFileId, accessCode, query) =>
    dispatch(getBoxFileForDataRoom(boxFileId, accessCode, query)),
  getBoxTokenFolderId: path =>
    dispatch(
      getBoxTokenFolderId(
        path,
        isOutsideForm,
        isOutsideForm ? false : access_code,
        accountId,
      ),
    ),
  uploadBoxFile: (fileId, body) => dispatch(uploadBoxFile(fileId, body, isOutsideForm)),
});

const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(FileUploader);
