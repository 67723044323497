import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import ContentPreview from 'box-ui-elements/es/elements/content-preview';
import classnames from 'classnames';
import { capitalize, get, isArray, isEmpty, isObject, startCase } from 'lodash-es';

import { getBoxFileInfo } from '../../redux/modules/Cabinet/operations';
import { BoxToken } from '../../redux/modules/Cabinet/selectors';

import './InfoSidebar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFetchingWithHooks } from '../../redux/modules/Transaction/selectors';
import { MultiToggle } from '../../components/inputs';
import { Button } from '../../components/common';
import { DropdownAction } from '../../components/common/Dropdown/Dropdown';
import {
  createRoomLink,
  deleteRoomLink,
  swapRoomLinks,
  updateRoom,
  updateRoomLink,
} from '../../redux/modules/Data/operations';
import Modal from 'react-modal';
import { ShareLink } from '../../components/formik/ShareLinks/ShareLinks';
import { IsAccountAdmin } from '../../redux/modules/Profile/selectors';
import { getAccountProject } from '../../redux/modules/Project/operations';
import { setNotice } from '../../redux/modules/UI/actions';
import dayjs from 'dayjs';
import { ShareRoomLinkModal } from '../Room/RoomSettings';
import { BrandMeta } from '../../redux/modules/UI/selectors';

const InfoSidebar = ({
  access_code,
  account_id,
  addons,
  customDocsReviewInfo,
  dataRoomLinks = [],
  faqs,
  fees,
  handleTaskAction,
  isAssessment,
  isDataRoom,
  isEditing,
  isOutsideForm,
  isOverview,
  isProcessesShowing,
  isQuestionnaireSubmitted,
  isPortalView,
  isReviewersShowing,
  isTaskView,
  isWorkflowCounselShowing,
  open,
  outsideForms,
  params: { companyId } = {},
  projectCounsel,
  reviewers,
  reviewInfo,
  roomId,
  rooms = [],
  tasks = [],
}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const boxToken = useSelector(BoxToken);

  const [isFAQCollapsed, setIsFAQCollapsed] = useState(window.screen.height < 1400);

  if (
    (isDataRoom && faqs.length === 0 && dataRoomLinks.length === 0 && !isEditing) ||
    (isOutsideForm && isEmpty(reviewInfo) && faqs.length === 0)
  ) {
    return null;
  }

  return (
    <div className="infoSidebar__container">
      {(dataRoomLinks.length > 0 || isEditing) && (
        <DataRoomLinks
          access_code={access_code}
          account_id={account_id}
          dataRoomLinks={dataRoomLinks}
          isEditing={isEditing}
          roomId={roomId}
        />
      )}
      {!isDataRoom && (
        <div className={`infoSidebar__review ${faqs.length > 0 && 'maxHeight'}`}>
          <div
            className={`infoSidebar__reviewInner ${
              isPortalView ? 'infoSidebar__reviewInner--portal' : 0
            }`}
          >
            {rooms.length > 0 && <ConnectedRooms rooms={rooms} />}
            {outsideForms && !outsideForms?.isEmpty && (
              <SharedForms
                forms={outsideForms}
                rooms={rooms}
                isPortalView={isPortalView}
              />
            )}
            <h1>{isProcessesShowing ? 'Task Overview' : 'Review Information'}</h1>
            {isProcessesShowing && (
              <div className="infoSidebar__tasks">
                {tasks.map((task, taskIndex) => (
                  <ReviewTask
                    key={`task-${task.label}`}
                    addons={addons}
                    fees={fees}
                    handleTaskAction={handleTaskAction}
                    open={open}
                    isAssessment={isAssessment}
                    task={task}
                  />
                ))}
              </div>
            )}
            {isOverview && (
              <h4 className="infoSidebar__reviewInnerSub">
                Review initial assessment input. Will not reflect changes that occur after
                assessment submission.
              </h4>
            )}
            {isOverview && isEmpty(reviewInfo) && (
              <h4 className="infoSidebar__reviewInnerSub">
                Nothing to review. Please select a task
              </h4>
            )}
            {isTaskView && !isQuestionnaireSubmitted && tasks.length === 0 && (
              <h4 className="infoSidebar__reviewInnerSub">
                Review inputs here upon task form completion.
              </h4>
            )}
            {isOutsideForm && !reviewInfo.length === 0 && (
              <h4 className="infoSidebar__reviewInnerSub">No inputs to Review.</h4>
            )}
            {isWorkflowCounselShowing && (
              <div className="infoSidebar__reviewHeader">
                <h4>Workflow Role Info</h4>
                {projectCounsel.length === 0 && (
                  <div className="infoSidebar__reviewRow">
                    <p>No Role assigned.</p>
                  </div>
                )}
                {projectCounsel.map((counsel, parentIndex) => (
                  <div
                    className="infoSidebar__reviewRow"
                    key={`project-counsel-${parentIndex}`}
                  >
                    <h4>
                      {startCase(counsel.counsel_type)}:{' '}
                      <p>
                        {counsel.user &&
                          counsel.partner &&
                          `${counsel.user.full_name} from ${counsel.partner.name}`}
                        {!!counsel.user && !counsel.partner && counsel.user.full_name}
                        {!!counsel.partner && !counsel.user && counsel.partner.name}
                      </p>
                    </h4>
                  </div>
                ))}
              </div>
            )}
            {isReviewersShowing && (
              <div className="infoSidebar__reviewHeader">
                <h4>Reviewers Info</h4>
                <div className="infoSidebar__reviewRow">
                  <h4>
                    {reviewers.length === 0 && 'No reviewers assigned.'}
                    {reviewers.map(
                      ({ first_name = '', last_name = '', value }, reviewerIndex) => (
                        <span key={`project-reviewers-${reviewerIndex}`}>
                          {`${first_name} ${last_name}`}
                          {reviewerIndex !== reviewers.length - 1 && `, `}
                        </span>
                      ),
                    )}
                  </h4>
                </div>
              </div>
            )}
            {customDocsReviewInfo && customDocsReviewInfo.length > 0 && (
              <>
                <h1>Custom Document Info</h1>
                {customDocsReviewInfo.map((values, parentIndex) => (
                  <Fragment key={`reviewInfo-custom-docs-${parentIndex}`}>
                    {!isEmpty(values.fields) && (
                      <div className="infoSidebar__reviewHeader">
                        <h4>Input Fields</h4>
                        {Object.entries(values.fields).map(
                          ([fieldKey, fieldVal], index) => (
                            <div
                              key={`custom-doc-review-field-${parentIndex}-${index}`}
                              className="infoSidebar__reviewRow"
                            >
                              <h4>
                                {startCase(fieldKey)}: <p>{fieldVal}</p>
                              </h4>
                            </div>
                          ),
                        )}
                      </div>
                    )}
                    {values.recipients.map((recipient, index) => (
                      <div
                        className="infoSidebar__reviewHeader"
                        key={`custom-doc-review-${parentIndex}-${index}`}
                      >
                        <h4>Recipient #{index + 1}</h4>
                        <div className="infoSidebar__reviewRow">
                          {Object.entries(recipient).map(([key, value], ind) => (
                            <h4 key={`custom-doc-review-${key}-${ind}`}>
                              {startCase(key)}: <p>{value}</p>
                            </h4>
                          ))}
                        </div>
                      </div>
                    ))}
                  </Fragment>
                ))}
              </>
            )}
            {(isQuestionnaireSubmitted || isOverview || isOutsideForm) && (
              <ReviewInfo
                boxToken={boxToken}
                companyId={companyId}
                dispatch={dispatch}
                isOutsideForm={isOutsideForm}
                push={push}
                reviewInfo={reviewInfo}
              />
            )}
          </div>
        </div>
      )}
      {faqs.length > 0 && (
        <div
          className={classnames('infoSidebar__faq', {
            collapsed: isFAQCollapsed,
          })}
        >
          <div className="infoSidebar__faqHeader">
            <button type="button" onClick={() => setIsFAQCollapsed(!isFAQCollapsed)}>
              <h1>
                <span>FAQ</span>
                <FontAwesomeIcon icon={isFAQCollapsed ? 'chevron-up' : 'chevron-down'} />
              </h1>
            </button>
          </div>
          {/* <div className="infoSidebar__faqContent"> */}
          <div className="infoSidebar__faqInner">
            {faqs.map((faq, ind) => (
              <Fragment key={`sidebarfaq-${ind}`}>
                <h2>{faq.header}</h2>
                <ReactMarkdown linkTarget="_blank">{faq.body}</ReactMarkdown>
              </Fragment>
            ))}
          </div>
          {/* </div> */}
        </div>
      )}
    </div>
  );
};

const ReviewInfo = ({ boxToken, companyId, dispatch, push, reviewInfo, isOutsideForm }) =>
  Object.entries(reviewInfo).map(([resourceKey, resourceArr], index) => (
    <div className="infoSidebar__reviewHeader" key={`review-${resourceKey}-${index}`}>
      {Array.isArray(resourceArr) &&
        resourceArr.map((resource, resourceInd) => (
          <Fragment key={`resource-array-${resourceInd}`}>
            {resource.resource && (
              <h4>{`${resource.resource} (${resource['Custom Label']})`}</h4>
            )}
            {resource.resource && (
              <div className="infoSidebar__reviewRow">
                {Object.entries(resource).map(([key, value], ind) => {
                  if (
                    key !== 'resource' &&
                    key !== 'Company Name' &&
                    key !== 'Name' &&
                    key !== 'Custom Label'
                  ) {
                    let updatedVal = value;

                    if (isObject(value)) {
                      if (value.isDocument) {
                        if (!isOutsideForm) {
                          return (
                            <Fragment key={`review-info-${key}-${ind}`}>
                              <h4>{key}:</h4>
                              <div className="infoSidebar__reviewPreview">
                                {boxToken && (
                                  <ContentPreview
                                    token={boxToken}
                                    fileId={value.fileId}
                                  />
                                )}
                              </div>
                              <button
                                className="infoSidebar__reviewGotoCabinet"
                                onClick={() =>
                                  dispatch(getBoxFileInfo(value.fileId, push, companyId))
                                }
                              >
                                {value.label}
                              </button>
                            </Fragment>
                          );
                        } else {
                          return (
                            <Fragment key={`review-info-${key}-${ind}`}>
                              <h4>
                                {key}: <p>{value.value}</p>
                              </h4>
                            </Fragment>
                          );
                        }
                      }
                      if (value.isLink) {
                        return (
                          <Fragment key={`review-info-${key}-${ind}`}>
                            <h4>
                              {key}: <a href={value.path}>{value.label}</a>
                            </h4>
                          </Fragment>
                        );
                      }
                      if (isArray(value)) {
                        return (
                          <Fragment key={`review-info-link-${key}-${ind}`}>
                            <h4>{key}:</h4>
                            <div className="infoSidebar__reviewRow">
                              {value.map((e, linkIndex) => (
                                <Fragment
                                  key={`review-info-link-${e.label}}-${linkIndex}`}
                                >
                                  <h4>
                                    Link #{linkIndex + 1}:
                                    <a
                                      href={e.url.includes('http') ? e.url : '//' + e.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {e.label}
                                    </a>
                                  </h4>
                                </Fragment>
                              ))}
                            </div>
                          </Fragment>
                        );
                      }
                      if (!Object.values(value).join('')) {
                        updatedVal = 'None Given';
                      } else {
                        return (
                          <Fragment key={`review-info-${key}-${ind}`}>
                            <h4>{key}:</h4>
                            <p>{value.line_one}</p>
                            {value.line_two.length > 0 && <p>{value.line_two}</p>}
                            <p>{`${startCase(value.city)}, ${value.state} ${
                              value.zip
                            }`}</p>
                            {value.country && <p>{value.country}</p>}
                          </Fragment>
                        );
                      }
                    } else if (value.indexOf('|') !== -1) {
                      updatedVal = updatedVal.split('|').join(', ');
                    }
                    return (
                      <h4 key={`review-info-${key}-${ind}`}>
                        {key}: <p>{updatedVal || 'Not Given'}</p>
                      </h4>
                    );
                  }
                  return null;
                })}
              </div>
            )}
          </Fragment>
        ))}
    </div>
  ));

const ReviewTask = ({
  addons,
  fees,
  handleTaskAction,
  isAssessment,
  isInitCollapsed,
  open,
  task: {
    bypassed,
    deliverables,
    id,
    label,
    mode,
    state,
    status_label,
    template: { transaction_actions = {}, type, type_label } = {},
  } = {},
}) => {
  const isWhiteLabel = window.location.href.includes('paperos');
  const { Bypass, Custom, Generate, Upload } = transaction_actions;
  const { high_days, low_days } = get(deliverables, '[0].timeline[0]', {});
  const taskAddons = addons.filter(e => e.taskId === id);
  const taskFees = fees.filter(e => e.taskId === id);

  const isFetchingTransaction = useSelector(isFetchingWithHooks);

  const [isTaskCollapsed, setIsTaskCollapsed] = useState(isInitCollapsed);

  let hideRadios = false;
  let isActionsShowing = true;
  let timelineLabel = '';

  if (type === 'System' || mode === 'Upload') {
    timelineLabel = 'instant';
  } else if (low_days) {
    timelineLabel = `${low_days} - ${high_days} days`;
  }

  if (
    (!Bypass && !Generate && !Upload && !Custom) ||
    isAssessment ||
    (Object.keys(transaction_actions).length < 2 && !Bypass)
  ) {
    isActionsShowing = false;
  }

  if (!Generate && !Upload && !Custom) {
    hideRadios = true;
  }

  const toggleActions = Object.entries(transaction_actions)
    .sort((a, b) => {
      if (a[0] === 'Generate') {
        return -1;
      }
      if (
        (a[0] === 'Upload' && b[0] !== 'Generate') ||
        (a[0] === 'Skip' && b[0] === 'Custom')
      ) {
        return -1;
      }
      return 1;
    })
    .reduce((dict, [key, value]) => {
      let updatedOption = {
        label: key,
        mode: key,
        value,
        disabled: false,
      };
      if (hideRadios && (key === 'Generate' || key === 'Upload' || key === 'Custom')) {
        return dict;
      }
      if (hideRadios && key !== 'Bypass') {
        updatedOption.value = !!bypassed;
      }
      if (key === 'Bypass') {
        updatedOption.disabled = !value;
        updatedOption.label = 'Skip';
      }
      dict.push(updatedOption);
      return dict;
    }, []);

  return (
    <div className="infoSidebar__task">
      <p
        className={`infoSidebar__taskToggle${isTaskCollapsed ? ' collapsed' : ''}`}
        onClick={() => setIsTaskCollapsed(!isTaskCollapsed)}
      >
        <FontAwesomeIcon
          icon={['fal', isTaskCollapsed ? 'chevron-right' : 'chevron-down']}
        />
        {label}
      </p>
      {!isTaskCollapsed && (
        <div className="infoSidebar__taskBody">
          <div className="infoSidebar__taskRow">
            <div className="infoSidebar__taskItem">
              <h4>Status</h4>
              <p>
                {capitalize(
                  (mode === 'Upload' && state) ||
                    (state === 'completed' && 'Completed') ||
                    status_label ||
                    state ||
                    'Not Given',
                )}
              </p>
            </div>
            <div className="infoSidebar__taskItem">
              <h4>Type</h4>
              <p>{type_label || type}</p>
            </div>
            {taskAddons.length > 0 && (
              <div className="infoSidebar__taskItem wide">
                <h4>Add-ons</h4>
                {taskAddons.map((addon, addonInd) => (
                  <div key={`product-addon-${addonInd}`}>
                    <p>
                      <strong>
                        {(isWhiteLabel && 'Covered By One Tap!') ||
                          '$' + addon.amount + '/'}
                        {(isWhiteLabel && ' ') ||
                          (addon.period === 'year' && 'Yr ') ||
                          'Mo '}
                      </strong>
                      {`${addon.label} Add-on `}
                      {!open && <span className="paid">Paid</span>}
                    </p>
                  </div>
                ))}
              </div>
            )}
            {taskFees.length > 0 && (
              <div className="infoSidebar__taskItem wide">
                <h4>Fees</h4>
                {taskFees.map((fee, feeInd) => (
                  <div key={`product-fee-${feeInd}`}>
                    <p>
                      <strong>
                        {(isWhiteLabel && 'Covered By One Tap!') ||
                          (fee.is_annual && fee.amount) ||
                          '$' + fee.amount}{' '}
                      </strong>
                      {`${fee.label} Fee `}
                      {(isWhiteLabel || !open) && <span className="paid">Paid</span>}
                    </p>
                  </div>
                ))}
              </div>
            )}
            {!!timelineLabel && (
              <div className="infoSidebar__taskItem">
                <h4>Timeline</h4>
                <p>{timelineLabel}</p>
              </div>
            )}
          </div>
          {isActionsShowing && (
            <MultiToggle
              isDisabled={isFetchingTransaction}
              name="info-sidebar-task-actions"
              mode={mode}
              options={toggleActions}
              onClick={selectedOption => {
                const { mode: selectedMode, value } = selectedOption;
                if (hideRadios && mode === 'Bypass' && selectedMode === mode) {
                  handleTaskAction('Enable', id);
                } else if (selectedMode !== mode && value) {
                  handleTaskAction(selectedMode, id);
                }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export const DataRoomLinks = ({
  accessCode,
  account_id,
  dataRoomLinks = [],
  isEditing,
  roomId,
}) => {
  const dispatch = useDispatch();
  const [addLinkMeta, setAddLinkMeta] = useState({});

  return (
    <div className="infoSidebar__linksWrapper">
      <h2>
        Links
        {isEditing && (
          <Button
            size="xs"
            buttonType="link"
            onClick={() => setAddLinkMeta({ index: dataRoomLinks.length, isOpen: true })}
          >
            <FontAwesomeIcon icon="plus" />
            Add link
          </Button>
        )}
      </h2>
      <div className="infoSidebar__links">
        {dataRoomLinks.map(({ id, label, link }, index) => (
          <div className="infoSidebar__link" key={`link-${id}`}>
            <Button
              size="sm"
              buttonType="link"
              href={link.includes('http') ? link : 'https://' + link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={['fal', 'external-link']} />
              {label}
            </Button>
            {isEditing && (
              <DropdownAction
                Toggle={({ onClick }) => (
                  <Button buttonType="icon" onClick={onClick} size="xs">
                    <FontAwesomeIcon icon="ellipsis-v" />
                  </Button>
                )}
              >
                <Button
                  buttonType="secondary"
                  onClick={e =>
                    setAddLinkMeta({ id, index, isOpen: true, label, url: link })
                  }
                >
                  Edit Link
                </Button>
                <Button
                  buttonType="secondary"
                  isDisabled={index === 0}
                  onClick={e =>
                    dispatch(
                      swapRoomLinks(
                        id,
                        dataRoomLinks[index - 1].id,
                        roomId,
                        account_id,
                        accessCode,
                      ),
                    )
                  }
                >
                  Move up
                </Button>
                <Button
                  buttonType="secondary"
                  isDisabled={index + 1 === dataRoomLinks.length}
                  onClick={e =>
                    dispatch(
                      swapRoomLinks(
                        id,
                        dataRoomLinks[index + 1].id,
                        roomId,
                        account_id,
                        accessCode,
                      ),
                    )
                  }
                >
                  Move down
                </Button>
                <Button
                  buttonType="secondary"
                  isWarning
                  onClick={() => dispatch(deleteRoomLink(id, roomId, account_id))}
                >
                  Remove Link
                </Button>
              </DropdownAction>
            )}
          </div>
        ))}
      </div>
      {isEditing && (
        <AddLinkModal
          accessCode={accessCode}
          account_id={account_id}
          linkMeta={addLinkMeta}
          onClose={() => setAddLinkMeta({})}
          roomId={roomId}
        />
      )}
    </div>
  );
};

const ConnectedRooms = ({ rooms }) => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(IsAccountAdmin);
  const [isShareLinkOpen, setIsShareLinkOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState({});

  // const handleOpenShareLinkModal = room => {
  //   setSelectedRoom(room);
  //   setIsShareLinkOpen(true);
  // };
  const handleCloseShareLinkModal = () => {
    setSelectedRoom({});
    setIsShareLinkOpen(false);
  };

  return (
    <>
      <h1>Related Rooms</h1>
      <div className="infoSidebar__rooms">
        {rooms.map(room => {
          const {
            account_id,
            account_project_id,
            data_access_code,
            id,
            label,
            open,
            require_auth,
            strict,
          } = room;
          return (
            <div className="infoSidebar__room" key={`room-${id}`}>
              <div className="infoSidebar__room-label">
                <h3>{label}</h3>
                {isAdmin && (
                  <>
                    {/* <Button
                      buttonType="icon"
                      size="xs"
                      tooltip="Edit label (coming soon!)"
                      to={`/data-room/${data_access_code}`}
                    >
                      <FontAwesomeIcon icon={['fal', 'edit']} />
                    </Button> */}
                    {/* <Button
                      buttonType="icon"
                      size="xs"
                      tooltip="Email Room Link"
                      onClick={e => handleOpenShareLinkModal(id)}
                    >
                      <FontAwesomeIcon icon={['fal', 'user-plus']} />
                    </Button> */}
                    <Button
                      buttonType="icon"
                      size="xs"
                      tooltip="Go to Room Sharing & Access"
                      tooltipAlign="right"
                      to={`/data-room/${data_access_code}/settings`}
                    >
                      <FontAwesomeIcon icon={['fal', 'cog']} />
                    </Button>
                    <Button
                      buttonType="icon"
                      size="xs"
                      tooltip="Go to Room"
                      tooltipAlign="right"
                      to={`/data-room/${data_access_code}`}
                    >
                      <FontAwesomeIcon icon={['fal', 'external-link']} />
                    </Button>
                  </>
                )}
              </div>
              <div className="infoSidebar__room-tags">
                <div
                  className={`infoSidebar__room-tag ${
                    open ? 'infoSidebar__room-tag--low' : ''
                  }`}
                >
                  {open ? 'Open' : 'Closed'}
                </div>
                <div
                  className={`infoSidebar__room-tag${
                    require_auth ? ' infoSidebar__room-tag--low' : ''
                  }`}
                >
                  {require_auth ? 'Sign-in required' : 'Sign-in optional'}
                </div>
                <div
                  className={`infoSidebar__room-tag${
                    strict ? ' infoSidebar__room-tag--low' : ''
                  }`}
                >
                  {strict ? 'Invite only' : 'Invite optional'}
                </div>
                {isAdmin && (
                  <DropdownAction
                    Toggle={({ onClick }) => (
                      <Button buttonType="icon" onClick={onClick} size="xs">
                        <FontAwesomeIcon icon="ellipsis-v" />
                      </Button>
                    )}
                  >
                    <Button
                      buttonType="secondary"
                      onClick={() =>
                        dispatch(updateRoom({ open: !open }, id, null, account_id)).then(
                          e => dispatch(getAccountProject(account_project_id)),
                        )
                      }
                    >
                      {open ? 'Close room' : 'Open room link'}
                    </Button>
                    <Button
                      buttonType="secondary"
                      onClick={() =>
                        dispatch(
                          updateRoom(
                            { require_auth: !require_auth },
                            id,
                            null,
                            account_id,
                          ),
                        ).then(e => dispatch(getAccountProject(account_project_id)))
                      }
                    >{`${require_auth ? 'Optional' : 'Require'} sign-in`}</Button>
                    <Button
                      buttonType="secondary"
                      isDisabled
                      disabledTooltip="Not Currently Available"
                      onClick={() =>
                        dispatch(
                          updateRoom({ strict: !strict }, id, null, account_id),
                        ).then(e => dispatch(getAccountProject(account_project_id)))
                      }
                    >{`${strict ? 'Optional' : 'Require'} Invite`}</Button>
                  </DropdownAction>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <ShareRoomLinkModal
        access_code={selectedRoom.data_access_code}
        account_id={selectedRoom.account_id}
        isOpen={isShareLinkOpen}
        onClose={handleCloseShareLinkModal}
        roomId={selectedRoom.id}
      />
    </>
  );
};

const SharedForms = ({
  forms: { openFormTemplates, openForms, sharedForms },
  isPortalView,
  rooms,
}) => {
  const massagedOpenForms = useMemo(() => {
    const updatedForms = openForms.map(form => {
      const roomIndex = rooms.findIndex(e => e.id === form.room_id);
      if (roomIndex !== -1 && rooms[roomIndex].open) {
        form.isRoomOpen = true;
      }
      return form;
    });
    return updatedForms;
  }, [openForms, rooms]);
  return (
    <div className="infoSidebar__openForms">
      <h1>Related Forms</h1>
      {!isPortalView && openFormTemplates.map(OpenFormTemplate)}
      <div className="infoSidebar__openForms">
        {(openForms.length > 0 || sharedForms.length > 0) && (
          <div className="infoSidebar__room-subtype">Shared Forms</div>
        )}
        {!isPortalView && massagedOpenForms.map(OpenForm)}
        {sharedForms.map(sharedForm => (
          <SharedForm
            key={`outside-form-${sharedForm.id}`}
            form={sharedForm}
            rooms={rooms}
          />
        ))}
      </div>
    </div>
  );
};

function OpenFormTemplate({ access_code, name, id }) {
  const dispatch = useDispatch();

  return (
    <Fragment key={`open-form-template-${id}`}>
      <div className="infoSidebar__room-subtype">Standing Form Link</div>
      <div className="infoSidebar__room">
        <div className="infoSidebar__room-label">
          <h3>{name}</h3>
          <Button
            buttonType="icon"
            size="xs"
            tooltip="Copy New Standing Form Link"
            tooltipAlign="right"
            onClick={() => {
              const { REACT_APP_BASEURL } = process.env;
              navigator.clipboard
                .writeText(`${REACT_APP_BASEURL}/api/redirect/form/${access_code}`)
                .then(
                  () => dispatch(setNotice('Link Copied!')),
                  err => console.error('Could not copy text: ', err),
                );
            }}
          >
            <FontAwesomeIcon icon={['fal', 'copy']} />
          </Button>
          <Button
            buttonType="icon"
            size="xs"
            tooltip="Go to New Standing Form"
            tooltipAlign="right"
            to={`/api/redirect/form/${access_code}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={['fal', 'external-link']} />
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

function OpenForm({
  access_code,
  created_at,
  id,
  metadata: { finalize } = {},
  name,
  state,
  isRoomOpen,
}) {
  const dispatch = useDispatch();
  const brandMeta = useSelector(BrandMeta);
  let formUrl = `/data-room/${access_code}/outside-form/${access_code}`;
  if (!isRoomOpen) {
    formUrl = `/outside-form/${access_code}`;
  }

  return (
    <div className="infoSidebar__room" key={`outside-form-${id}`}>
      <div className="infoSidebar__room-label">
        <h3>{name}</h3>
        <Button
          buttonType="icon"
          size="xs"
          tooltip="Copy link"
          tooltipAlign="right"
          onClick={() => {
            navigator.clipboard.writeText(`${brandMeta.web_base_url}${formUrl}`).then(
              () => dispatch(setNotice('Link Copied!')),
              err => console.error('Could not copy text: ', err),
            );
          }}
        >
          <FontAwesomeIcon icon={['fal', 'copy']} />
        </Button>
        <Button
          buttonType="icon"
          size="xs"
          tooltip="Go to shared form"
          tooltipAlign="right"
          to={formUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fal', 'external-link']} />
        </Button>
      </div>
      <div className="infoSidebar__room-row">
        <div className="infoSidebar__room-item">
          <h4>Date Created</h4>
          <p>{dayjs(created_at).format('ddd, MMM YYYY hh:mm A')}</p>
        </div>
      </div>
      <div className="infoSidebar__room-row">
        <div className="infoSidebar__room-item">
          <h4>Status</h4>
          <div className="infoSidebar__room-tags">
            <div
              className={`infoSidebar__room-tag ${
                state === 'new' ? 'infoSidebar__room-tag--low' : ''
              }`}
            >
              {state === 'new' ? 'Open' : 'Completed'}
            </div>
            {finalize && (
              <div className="infoSidebar__room-tag infoSidebar__room-tag--low">
                Finalizes task on submission
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function SharedForm({ rooms, form }) {
  const dispatch = useDispatch();
  const {
    access_code,
    assigned_email,
    created_at,
    metadata: { send_email, finalize, type = 'outside_form' } = {},
    name,
    state,
  } = form;
  let emailStatusLabel = 'Email Sent';
  if (type === 'data_request' && !send_email) {
    emailStatusLabel = 'Email Not Sent';
  }
  let isDealRoomShowing = false;
  rooms.forEach(room => {
    if (room.open) {
      isDealRoomShowing = true;
    }
  });
  return (
    <div className="infoSidebar__room">
      <div className="infoSidebar__room-label">
        <h3>{name}</h3>
        <DropdownAction
          className="infoSidebar__dropdown"
          isFlat
          right
          Toggle={toggleProps => (
            <Button
              size="xs"
              buttonType="link"
              className="infoSidebar__share"
              {...toggleProps}
            >
              <FontAwesomeIcon icon={['fal', 'chevron-down']} />
              Share Form
            </Button>
          )}
        >
          <div className="infoSidebar__dropdownHeader">
            <h4>Share Form Options</h4>
            <div className="infoSidebar__divider" />
          </div>
          <Button
            buttonType="secondary"
            size="sm"
            onClick={() => {
              const { REACT_APP_BASEURL } = process.env;
              navigator.clipboard
                .writeText(`${REACT_APP_BASEURL}/outside-form/${access_code}`)
                .then(
                  () => dispatch(setNotice('Link Copied!')),
                  err => console.error('Could not copy text: ', err),
                );
            }}
          >
            Copy Shared Form Link
          </Button>
          <Button
            buttonType="secondary"
            isDisabled={!isDealRoomShowing}
            size="sm"
            onClick={() => {
              const { REACT_APP_BASEURL } = process.env;
              navigator.clipboard
                .writeText(
                  `${REACT_APP_BASEURL}/data-room/${access_code}/outside-form/${access_code}`,
                )
                .then(
                  () => dispatch(setNotice('Link Copied!')),
                  err => console.error('Could not copy text: ', err),
                );
            }}
          >
            Copy Deal Room with Shared Form Link
          </Button>
          <Button
            buttonType="secondary"
            size="sm"
            to={`/outside-form/${access_code}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to Shared Form
          </Button>
          <Button
            buttonType="secondary"
            size="sm"
            isDisabled={!isDealRoomShowing}
            to={`/data-room/${access_code}/outside-form/${access_code}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to Deal Room with Shared Form
          </Button>
        </DropdownAction>
      </div>
      <div className="infoSidebar__room-row">
        <div className="infoSidebar__room-item">
          <h4>Date Created</h4>
          <p>{dayjs(created_at).format('ddd, MMM YYYY hh:mm A')}</p>
        </div>
        <div className="infoSidebar__room-item">
          <h4>Connected Email</h4>
          <p>{assigned_email || 'None connected'}</p>
        </div>
      </div>
      <div className="infoSidebar__room-row">
        <div className="infoSidebar__room-item">
          <h4>Status</h4>
          <div className="infoSidebar__room-tags">
            <div
              className={`infoSidebar__room-tag ${
                state === 'new' ? 'infoSidebar__room-tag--low' : ''
              }`}
            >
              {state === 'new' ? 'Open' : 'Completed'}
            </div>
            <div
              className={`infoSidebar__room-tag ${
                type === 'outside_form' || send_email ? 'infoSidebar__room-tag--low' : ''
              }`}
            >
              {emailStatusLabel}
            </div>
            {finalize && (
              <div className="infoSidebar__room-tag infoSidebar__room-tag--low">
                Finalizes task on submission
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export const AddLinkModal = ({
  accessCode,
  account_id,
  linkMeta: { isOpen, id, index, label = '', url = '' },
  onClose,
  roomId,
}) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState({ label, url });
  const [error, setError] = useState(false);

  useEffect(() => {
    setInput({ label, url });
  }, [label, url]);

  const handleSetLink = updatedLink => {
    if (isEmpty(updatedLink)) {
      dispatch(deleteRoomLink(id, roomId, account_id, accessCode));
    } else {
      setInput(updatedLink);
    }
  };

  const handleUpdate = () => {
    let { url, label } = input;
    if (url && !label) {
      setInput({ label: url, url });
    }
    if (!url) {
      setError(true);
    } else if (!id) {
      setError(false);
      dispatch(
        createRoomLink({ label: label, link: url }, roomId, account_id, accessCode),
      ).then(onClose, onClose);
    } else {
      setError(false);
      dispatch(
        updateRoomLink({ label: label, link: url }, id, roomId, account_id, accessCode),
      ).then(onClose, onClose);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterClose={ev => {
        document.body.classList.remove('ReactModal__Body--open');
      }}
      className="infoSidebar__linkModal"
      overlayClassName="infoSidebar__linkModalOverlay"
    >
      <FontAwesomeIcon
        className="infoSidebar__linkModalExit"
        onClick={onClose}
        icon={['fal', 'times']}
      />
      <h2>{id ? 'Update' : 'Add'} Room Link</h2>
      <ShareLink
        accountId={account_id}
        error={error}
        handleSetLink={handleSetLink}
        index={index}
        link={{ label, url }}
        name="add-room-link"
        questionLabel=""
      />
      <div className="room__modalActions">
        <Button buttonType="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button onClick={handleUpdate}>{id ? 'Update' : 'Add'} Link</Button>
      </div>
    </Modal>
  );
};

InfoSidebar.propTypes = {
  sideHelp: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

InfoSidebar.defaultProps = {
  isVisible: true,
  toggleMenu: () => {},
};

export default InfoSidebar;
