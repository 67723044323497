// export const GET_CUSTOM_DOCUMENT_REQUEST =
//   'savvi/document/GET_CUSTOM_DOCUMENT_REQUEST';
// export const GET_CUSTOM_DOCUMENT_SUCCESS =
//   'savvi/document/GET_CUSTOM_DOCUMENT_SUCCESS';
// export const GET_CUSTOM_DOCUMENT_FAILED = 'savvi/document/GET_CUSTOM_DOCUMENT_FAILED';
// export const UPDATE_CUSTOM_DOCUMENT_REQUEST =
//   'savvi/document/UPDATE_CUSTOM_DOCUMENT_REQUEST';
// export const UPDATE_CUSTOM_DOCUMENT_SUCCESS =
//   'savvi/document/UPDATE_CUSTOM_DOCUMENT_SUCCESS';
// export const UPDATE_CUSTOM_DOCUMENT_FAILED =
//   'savvi/document/UPDATE_CUSTOM_DOCUMENT_FAILED';
// export const CREATE_CUSTOM_DOCUMENT_REQUEST =
//   'savvi/document/CREATE_CUSTOM_DOCUMENT_REQUEST';
// export const CREATE_CUSTOM_DOCUMENT_SUCCESS =
//   'savvi/document/CREATE_CUSTOM_DOCUMENT_SUCCESS';
// export const CREATE_CUSTOM_DOCUMENT_FAILED =
//   'savvi/document/CREATE_CUSTOM_DOCUMENT_FAILED';
// export const ADD_CUSTOM_DOCUMENT = 'savvi/document/ADD_CUSTOM_DOCUMENT';
// export const SET_CUSTOM_DOCUMENT = 'savvi/document/SET_CUSTOM_DOCUMENT';

export const SET_DOCUMENTS_BY_TRANSACTION = 'savvi/document/SET_DOCUMENTS_BY_TRANSACTION';
export const SET_DOCUMENT_REVIEWER_REQUEST =
  'savvi/document/SET_DOCUMENT_REVIEWER_REQUEST';
export const SET_DOCUMENT_REVIEWER_SUCCESS =
  'savvi/document/SET_DOCUMENT_REVIEWER_SUCCESS';
export const SET_DOCUMENT_REVIEWER_FAILED = 'savvi/document/SET_DOCUMENT_REVIEWER_FAILED';

export const UPLOAD_DOCUMENT_REQUEST = 'savvi/document/UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'savvi/document/UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILED = 'savvi/document/UPLOAD_DOCUMENT_FAILED';

export const POST_REDLINING_VERSION_REQUEST =
  'savvi/document/POST_REDLINING_VERSION_REQUEST';
export const POST_REDLINING_VERSION_SUCCESS =
  'savvi/document/POST_REDLINING_VERSION_SUCCESS';
export const POST_REDLINING_VERSION_FAILED =
  'savvi/document/POST_REDLINING_VERSION_FAILED';

export const GET_PROJECT_DOCUMENTS_REQUEST =
  'savvi/document/GET_PROJECT_DOCUMENTS_REQUEST';
export const GET_PROJECT_DOCUMENTS_SUCCESS =
  'savvi/document/GET_PROJECT_DOCUMENTS_SUCCESS';
export const GET_PROJECT_DOCUMENTS_FAILED = 'savvi/document/GET_PROJECT_DOCUMENTS_FAILED';
export const SET_DOCUMENTS_BY_PROJECT = 'savvi/document/SET_DOCUMENTS_BY_PROJECT';

export const GET_TRANSACTION_DOCUMENTS_REQUEST =
  'savvi/document/GET_TRANSACTION_DOCUMENTS_REQUEST';
export const GET_TRANSACTION_DOCUMENTS_SUCCESS =
  'savvi/document/GET_TRANSACTION_DOCUMENTS_SUCCESS';
export const GET_TRANSACTION_DOCUMENTS_FAILED =
  'savvi/document/GET_TRANSACTION_DOCUMENTS_FAILED';

export const GET_DOCUMENTS_REQUEST = 'savvi/document/GET_DOCUMENTS_REQUEST';
export const GET_DOCUMENTS_SUCCESS = 'savvi/document/GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_FAILED = 'savvi/document/GET_DOCUMENTS_FAILED';
export const SET_DOCUMENTS = 'savvi/document/SET_DOCUMENTS';
export const SET_DOCUMENT_ERROR = 'savvi/document/SET_DOCUMENT_ERROR';
export const CLEAR_DOCUMENT_ERROR = 'savvi/document/CLEAR_DOCUMENT_ERROR';

export const DELETE_DOCUMENT_UPLOAD_REQUEST =
  'savvi/document/DELETE_DOCUMENT_UPLOAD_REQUEST';
export const DELETE_DOCUMENT_UPLOAD_SUCCESS =
  'savvi/document/DELETE_DOCUMENT_UPLOAD_SUCCESS';
export const DELETE_DOCUMENT_UPLOAD_FAILED =
  'savvi/document/DELETE_DOCUMENT_UPLOAD_FAILED';

export const GET_DOCUMENT_RECIPIENT_LINK_REQUEST =
  'savvi/document/GET_DOCUMENT_RECIPIENT_LINK_REQUEST';
export const GET_DOCUMENT_RECIPIENT_LINK_SUCCESS =
  'savvi/document/GET_DOCUMENT_RECIPIENT_LINK_SUCCESS';
export const GET_DOCUMENT_RECIPIENT_LINK_FAILED =
  'savvi/document/GET_DOCUMENT_RECIPIENT_LINK_FAILED';
export const SET_DOCUMENT_RECIPIENTS_LINKS =
  'savvi/document/SET_DOCUMENT_RECIPIENTS_LINKS';

export const RESEND_LINK_EMAIL_REQUEST = 'savvi/formation/RESEND_LINK_EMAIL_REQUEST';
export const RESEND_LINK_EMAIL_FAILED = 'savvi/formation/RESEND_LINK_EMAIL_FAILED';
export const RESEND_LINK_EMAIL_SUCCESS = 'savvi/formation/RESEND_LINK_EMAIL_SUCCESS';

export const GET_ALL_DOCUMENTS_REQUEST = 'savvi/document/GET_ALL_DOCUMENTS_REQUEST';
export const GET_ALL_DOCUMENTS_SUCCESS = 'savvi/document/GET_ALL_DOCUMENTS_SUCCESS';
export const GET_ALL_DOCUMENTS_FAILED = 'savvi/document/GET_ALL_DOCUMENTS_FAILED';
export const SET_ALL_DOCUMENTS = 'savvi/document/SET_ALL_DOCUMENTS';

export const GET_USER_DOCUMENTS_REQUEST = 'savvi/document/GET_USER_DOCUMENTS_REQUEST';
export const GET_USER_DOCUMENTS_SUCCESS = 'savvi/document/GET_USER_DOCUMENTS_SUCCESS';
export const GET_USER_DOCUMENTS_FAILED = 'savvi/document/GET_USER_DOCUMENTS_FAILED';
export const SET_USER_DOCUMENTS = 'savvi/document/SET_USER_DOCUMENTS';
