import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { setCompany } from '../../redux/modules/Company/operations';
import { getAccountId } from '../../redux/modules/Formation/selectors';
import { Button } from '../common';
import AssignTodo from '../AssignTodo';

import './TasksSection.scss';
import { performTaskAction } from '../../redux/modules/Project/operations';
import handlePath from '../../utils/handlePath';
import dayjs from 'dayjs/esm';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

const sortStrings = (a, b, sortIsDesc) =>
  sortIsDesc ? b.localeCompare(a) : a.localeCompare(b);

const sortByDate = (aDate, bDate, sortIsDesc) => {
  return sortIsDesc
    ? new Date(aDate).valueOf() - new Date(bDate).valueOf()
    : new Date(bDate).valueOf() - new Date(aDate).valueOf();
};

const TasksSection = ({
  handleDeleteProject,
  isHeightUnset,
  isSectionOpenInit,
  isAllTasks,
  isHome,
  isMyDashboard,
  isWarning,
  moduleId,
  sectionLabel,
  transactions,
}) => {
  const dispatch = useDispatch();
  const currentAccountId = useSelector(getAccountId);
  const [sortIsDesc, setSortIsDesc] = useState(false);
  const [sortSlug, setSortSlug] = useState('');
  const [isSectionOpen, setIsOpen] = useState(isSectionOpenInit);
  const [selectedTodo, setSelectedTodo] = useState({});
  const [isEditTodoOpen, setIsEditTodoOpen] = useState(false);

  const myTasks = useMemo(() => {
    const sortedTasks = transactions.sort((a, b) => {
      if (sortSlug === 'name') {
        return sortStrings(a.label, b.label, sortIsDesc);
      }
      if (sortSlug === 'project_name') {
        return sortStrings(a.project_name, b.project_name, sortIsDesc);
      }
      if (sortSlug === 'status') {
        return sortStrings(a.taskStatus, b.taskStatus, sortIsDesc);
      }
      if (sortSlug === 'type') {
        return sortStrings(a.taskType, b.taskType, sortIsDesc);
      }
      if (sortSlug === 'company_name') {
        return sortStrings(a.account_name, b.account_name, sortIsDesc);
      }
      if (sortSlug === 'date') {
        return sortByDate(a.created_at, b.created_at, sortIsDesc);
      }
      // Default to date_updated;
      return sortByDate(a.updated_at, b.updated_at, sortIsDesc);
    });
    return sortedTasks;
  }, [sortIsDesc, sortSlug, transactions]);

  const handleSortClick = clickedFilter => {
    if (clickedFilter === sortSlug) {
      if (sortIsDesc) {
        setSortSlug('');
      }
      setSortIsDesc(!sortIsDesc);
    } else {
      setSortIsDesc(false);
      setSortSlug(clickedFilter);
    }
  };

  const sortIcon = ['fal', sortIsDesc ? 'sort-amount-up' : 'sort-amount-down'];

  return (
    <div className="tasksSection">
      <h2 className="tasksSection__header">
        <FontAwesomeIcon icon={['fal', 'ballot-check']} />
        {sectionLabel}
        <span>{` (${myTasks.length > 0 ? myTasks.length : 'None'})`}</span>
      </h2>
      <div
        className={classnames('tasksSection__list', {
          unsetHeight: isHeightUnset,
          isMyDashboard,
        })}
      >
        <h4
          className="tasksSection__listHeaderLabel first"
          onClick={() => handleSortClick('name')}
        >
          Name
          {sortSlug === 'name' && <FontAwesomeIcon icon={sortIcon} />}
        </h4>
        <h4
          className="tasksSection__listHeaderLabel"
          onClick={() => handleSortClick('project_name')}
        >
          Workflow Name
          {sortSlug === 'project_name' && <FontAwesomeIcon icon={sortIcon} />}
        </h4>
        {isMyDashboard && (
          <h4
            className="tasksSection__listHeaderLabel"
            onClick={() => handleSortClick('company_name')}
          >
            Parent Workspace
            {sortSlug === 'company_name' && <FontAwesomeIcon icon={sortIcon} />}
          </h4>
        )}
        <h4
          className="tasksSection__listHeaderLabel"
          onClick={() => handleSortClick('date')}
        >
          Date Created
          {sortSlug === 'date' && <FontAwesomeIcon icon={sortIcon} />}
        </h4>
        {myTasks.map(
          (props, index) =>
            (isSectionOpen || index < 4) && (
              <TaskRow
                key={`actionItems-${props.id}-${index}`}
                currentAccountId={currentAccountId}
                dispatch={dispatch}
                handleDeleteProject={handleDeleteProject}
                isAllTasks={isAllTasks}
                isHome={isHome}
                isMyDashboard={isMyDashboard}
                moduleId={moduleId}
                setSelectedTodo={setSelectedTodo}
                setIsEditTodoOpen={setIsEditTodoOpen}
                {...props}
              />
            ),
        )}
        {myTasks.length > 4 && (
          <Button
            buttonType="link"
            className="tasksSection__listToggle"
            onClick={() => setIsOpen(!isSectionOpen)}
            size="sm"
          >
            {!isSectionOpen
              ? `Show ${myTasks.length - 4} more tasks`
              : 'Show fewer tasks'}
            <FontAwesomeIcon icon={isSectionOpen ? 'caret-up' : 'caret-down'} />
          </Button>
        )}
      </div>
      <AssignTodo
        isModalOpen={isEditTodoOpen}
        isMyDashboard={isMyDashboard}
        isNew
        todoMeta={selectedTodo}
        toggleModal={() => {
          setIsEditTodoOpen(false);
          setSelectedTodo({});
        }}
      />
    </div>
  );
};

const TaskRow = props => {
  const {
    account,
    account_id,
    created_at,
    dispatch,
    currentAccountId,
    filename,
    id,
    isAllTasks,
    isHome,
    isMyDashboard,
    label,
    moduleId,
    module_id,
    project_id,
    // project_is_deletable,
    project_name,
    project_template_id,
    setSelectedTodo,
    setIsEditTodoOpen,
    state,
    // updatedActionLabel,
    taskStatus,
    template = {},
  } = props;
  const { push } = useHistory();
  const { search } = useLocation();
  const { companyId } = useParams();

  let path = {
    pathname: `/workbench/${module_id || moduleId}/${project_id}/${id}/initialize`,
    search,
    state: { isClicked: true },
  };
  const stateText = taskStatus || state || 'Needs Review';

  const handleDeleteTask = e => {
    e.stopPropagation();
    const message = `Are you sure you want to delete ${label || 'this task'}?`;
    if (window.confirm(message)) {
      const parentView =
        (isAllTasks && 'Tasks') ||
        (isHome && 'Home') ||
        (isMyDashboard && 'MyDashboard') ||
        'Workbench';
      dispatch(performTaskAction('delete', id, null, parentView));
    }
  };

  return (
    <div
      className={`tasksSection__listRow ${isAllTasks && 'isAllTasks'}`}
      onClick={ev => {
        if (!ev.defaultPrevented) {
          if (isMyDashboard) {
            dispatch(setCompany(account_id, path, account_id === currentAccountId));
          } else {
            push(handlePath(path, companyId));
          }
        }
      }}
    >
      <h4 className="tasksSection__listItem first">
        {label || 'Unknown name'}
        {isAllTasks && (
          <span
            className={taskStatus === 'Open' || taskStatus === 'Pending' ? 'isMint' : ''}
          >
            {stateText}
          </span>
        )}
      </h4>
      <h4
        onClick={ev => {
          const projectPath = {
            pathname: `/workbench/${module_id || moduleId}/${project_id}/0/initialize`,
            search,
            state: { isClicked: true },
          };
          ev.preventDefault();
          if (isMyDashboard) {
            dispatch(
              setCompany(account_id, projectPath, account_id === currentAccountId),
            );
          } else {
            push(handlePath(projectPath, companyId));
          }
        }}
        className="tasksSection__listItem tasksSection__listItem--link"
      >
        {project_name}
      </h4>
      {isMyDashboard && <h4 className="tasksSection__listItem">{account}</h4>}
      <div className="tasksSection__listItem last">
        <h4>{dayjs(created_at).format('L')}</h4>
        <div className="tasksSection__listActions">
          {taskStatus !== 'Completed' && (
            <Button
              buttonType="icon"
              onClick={e => {
                e.stopPropagation();
                setSelectedTodo({
                  account_id,
                  id,
                  initTodoVals: {
                    label: `Complete "${filename || label}" Task`,
                  },
                  project_template_id,
                });
                setIsEditTodoOpen(true);
              }}
              size="sm"
              tooltip="Assign"
            >
              <FontAwesomeIcon icon={['fal', 'user-plus']} />
            </Button>
          )}
          {!!template.is_deletable && (
            <Button
              buttonType="icon"
              isWarning
              size="sm"
              onClick={handleDeleteTask}
              tooltip="Delete Task"
            >
              <FontAwesomeIcon icon={['fal', 'trash-alt']} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

TasksSection.propTypes = {
  handleDeleteProject: PropTypes.func,
  isHeightUnset: PropTypes.bool,
  isSectionOpenInit: PropTypes.bool,
  isMyDashboard: PropTypes.bool,
  isWarning: PropTypes.bool,
  moduleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sectionLabel: PropTypes.string,
  transactions: PropTypes.array,
};

TasksSection.defaultProps = {
  handleDeleteProject: () => {},
  isHeightUnset: true,
  isSectionOpenInit: false,
  isMyDashboard: false,
  isWarning: false,
  moduleId: -1,
  transactions: [],
};

export default TasksSection;
