import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash-es';

import logo from '../../assets/images/wordmark-white.svg';
import oneTapLogo from '../../assets/images/one_tap_wordmark.png';
import Button from '../../components/common/Button';
import ConsistentTopbar from '../../components/ConsistentTopbar';
import {
  requestVerificationEmail,
  checkVerificationStatus,
  exchangeChallenge,
} from '../../redux/modules/User/operations';
import { ApiErrors, getAuthUser } from '../../redux/modules/User/selectors';

import './VerifyCode.scss';
import { useHistory, useLocation, useParams } from 'react-router';
import { clearErrors } from '../../redux/modules/User/actions';
import InputVerification from '../../components/inputs/InputVerification';
import handlePath from '../../utils/handlePath';
import dayjs from 'dayjs';
import { getEmailVerification } from '../../redux/modules/User/utils';

// `A verification code to ${templateDescription[template_key]} has been sent to ${email}`
const templateDescriptions = {
  'verification-email': 'verify your account',
  'email-change-code': "replace your account's primary email",
  'reset-password': 'reset your password',
  'magic-link': 'login to Savvi without a password',
  'account-invite': 'invite you to a Savvi account',
  'partner-invite': 'invite you to a Savvi partner group',
};

function useInterval(callback, delay) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

const VerifyCode = ({ hideVerify }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const params = useParams();
  const { template_key = 'verification-email' } = params;
  const location = useLocation();
  const { state: { email, redirectLocation } = {} } = location;

  const authErrors = useSelector(ApiErrors);
  const user = useSelector(getAuthUser);

  const [codeInput, setCodeInput] = useState('');
  const [codeInputError, setCodeInputError] = useState('');
  const [loading, setLoading] = useState(false);
  const [verificationMeta, setVerificationMeta] = useState(() =>
    getEmailVerification(template_key),
  );

  const isWhiteLabel = window.location.href.includes('paperos');

  const isExpired =
    isEmpty(verificationMeta) || dayjs().diff(verificationMeta.sent_at, 'h') >= 24;

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useInterval(
    () => {
      const storedVerification = getEmailVerification(template_key);
      if (isEmpty(storedVerification)) {
        return;
      }
      setVerificationMeta(storedVerification);
      dispatch(
        checkVerificationStatus(
          storedVerification.id,
          storedVerification.receipt,
          template_key,
        ),
      ).then(e => {
        if (e.status === 'valid') {
          setLoading(true);
          dispatch(exchangeChallenge(e.id, { receipt: verificationMeta.receipt })).then(
            decodedToken => {
              setLoading(false);
              hideVerify();
              push(redirectLocation || handlePath('/company-select', 0));
            },
            error => setLoading(false),
          );
        }
      });
    },
    !isExpired && verificationMeta.id && isEmpty(authErrors) && !loading ? 30000 : null,
  );

  const handleSubmit = val => {
    dispatch(clearErrors());
    if (validateCodeInput(val)) {
      setLoading(true);
      dispatch(exchangeChallenge(verificationMeta.id, { code: val }, template_key)).then(
        e => {
          setLoading(false);
          hideVerify();
          push(redirectLocation || handlePath('/company-select', 0));
        },
        error => {
          setLoading(false);
        },
      );
    }
  };

  const validateCodeInput = val => {
    if (val.length !== 8) {
      setCodeInputError('must be 8 characters');
      return false;
    } else {
      setCodeInputError('');
      return true;
    }
  };

  return (
    <div className="verify-code__container">
      <ConsistentTopbar />
      <div
        className="verify-code__logo"
        onClick={() => push(handlePath('/company-select', 0))}
      >
        <img src={isWhiteLabel ? oneTapLogo : logo} alt="savvi-logo" />
        {isWhiteLabel && <h4 className="verify-code__paperos">powered by PaperOS</h4>}
      </div>
      <form
        className={`verify-code__form ${isExpired ? 'verify-code__form--expired' : ''}`}
      >
        <h2 className="verify-code__heading">
          <small className="verify-code__sub-heading">
            {isExpired &&
              "No verification info found. Use the verification link sent to your email, or if that doesn't work your code may have expired and needs to be resent to"}
            {!isExpired &&
              `A code to ${templateDescriptions[template_key]} has been sent to`}
            {!user.email && !email && ' your email.'}
          </small>
          {(!!user.email || !!email) && (
            <div className="verify-code__email">
              <b>{user.email || email}</b>
            </div>
          )}
          <small className="verify-code__sub-heading">
            {isExpired && (
              <Button
                buttonType="link"
                onClick={() => {
                  setLoading(true);
                  dispatch(clearErrors());
                  dispatch(
                    requestVerificationEmail({
                      identifier: {
                        type: 'email',
                        value: user.email,
                      },
                      template: template_key,
                      state: { redirectLocation },
                    }),
                  ).then(
                    e => {
                      setVerificationMeta(e);
                      setLoading(false);
                    },
                    error => setLoading(false),
                  );
                }}
              >
                resend verification code
              </Button>
            )}
            {!isExpired && 'Please enter your verification code below.'}
          </small>
        </h2>
        {!isExpired && (
          <>
            <InputVerification
              inputClass="-js-verify__input-verification-code"
              loading={loading}
              error={codeInputError}
              label={'Verification Code'}
              name={'verification-code'}
              value={codeInput}
              onChange={val => setCodeInput(val)}
              onBlur={handleSubmit}
            />
            {!isEmpty(authErrors) && (
              <div className="verify-code__form-alert">
                {Object.values(authErrors).join(', ')}
              </div>
            )}
            <div className="verify-code__action">
              <p>
                Your verification code will expire in 24 hours. If you can't find your
                verification email or your verification code is invalid then{' '}
                <Button
                  buttonType="link"
                  onClick={() => {
                    setLoading(true);
                    dispatch(clearErrors());
                    dispatch(
                      requestVerificationEmail({
                        identifier: {
                          type: 'email',
                          value: user.email,
                        },
                        template: template_key,
                        state: { redirectLocation },
                      }),
                    ).then(
                      e => {
                        setVerificationMeta(e);
                        setLoading(false);
                      },
                      error => setLoading(false),
                    );
                  }}
                >
                  resend verification code
                </Button>
              </p>
            </div>
          </>
        )}
      </form>
      <div className="verify-code__text">
        <p>
          For help with any issues please contact us at{' '}
          <a href="mailto:support@savvi.legal">support@savvi.legal</a>
        </p>
      </div>
      <Button
        buttonType="link"
        to={{
          pathname: '/terms',
          state: { background: location },
        }}
        size="sm"
      >
        {' '}
        Terms &amp; Conditions
      </Button>
    </div>
  );
};

export default VerifyCode;
