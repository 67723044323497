import { get, isEmpty } from 'lodash-es';
import { createSelector, defaultMemoize } from 'reselect';

import { MyTransactions } from '../Transaction/selectors';

export const isCompanyFetching = defaultMemoize(state =>
  get(state, 'Company.isFetching', false),
);

export const IsCompanyTokenRefreshing = defaultMemoize(state =>
  get(state, 'Company.isCompanyTokenRefreshing', false),
);

export const IsMultiCompany = defaultMemoize(
  state => Object.keys(get(state, 'Company.companies.byIds', {})).length > 1,
);

export const MyCompanies = defaultMemoize(state =>
  Object.values(get(state, 'Company.companies.byIds', {})),
);

const included_states = [
  'document.sent',
  'document.viewed',
  'document.waiting_approval',
  'document.waiting_pay',
];
export const MyFormattedCompanies = createSelector(MyCompanies, companies => {
  let sortedCompanies = companies
    .map(company => {
      // filter only documents that fall under the 'Missing Signatures' category.
      company.documents = company.documents.filter(e =>
        included_states.includes(e.status),
      );
      company.partner =
        ((!company.partner || company.partner === 'N/A') && '--') || company.partner;
      // for Select dropdown.
      company.label = company.name;
      company.value = company.name;

      return company;
    })
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  return sortedCompanies;

  // let groupedCompanies = [{ name: '', order: null, entities: [] }];

  // companies.forEach(company => {
  //   if (company.group) {
  //     const groupIndex = groupedCompanies.findIndex(
  //       group => group.name === company.group,
  //     );
  //     if (groupIndex !== -1) {
  //       let updatedGroup = groupedCompanies[groupIndex];

  //       updatedGroup.entities.push(company);
  //       if (updatedGroup.order > company.order) {
  //         updatedGroup.order = company.order;
  //         updatedGroup.lowestOrderCompanyId = company.id;
  //       }
  //       groupedCompanies[groupIndex] = updatedGroup;
  //     } else {
  //       groupedCompanies.push({
  //         name: company.group,
  //         order: company.order,
  //         lowestOrderCompanyId: company.id,
  //         entities: [company],
  //       });
  //     }
  //   } else {
  //     groupedCompanies[0].entities.push(company);
  //   }
  //   groupedCompanies.sort((a, b) => b.groupOrder > a.groupOrder);
  // });
  // return groupedCompanies;

  // const earliestPartnerCompanyIndex = sortedCompanies.findIndex(e => e.partner_id);
  // if (earliestPartnerCompanyIndex !== -1) {
  //   // Default sorting logic:
  //   // 1. sort by earliest created.
  //   // 2. group companies by partner.
  //   let companiesByPartner = [];
  //   sortedCompanies.forEach(company => {
  //     const partnerArrIndex = companiesByPartner.findIndex(
  //       e => company.partner_id && e[0]?.partner_id === company.partner_id,
  //     );
  //     if (partnerArrIndex === -1) {
  //       const lastIndex = companiesByPartner.length - 1;
  //       if (
  //         lastIndex === -1 ||
  //         company.partner_id ||
  //         companiesByPartner[lastIndex][0]?.partner_id
  //       ) {
  //         companiesByPartner.push([company]);
  //       } else {
  //         companiesByPartner[lastIndex].push(company);
  //       }
  //     } else {
  //       companiesByPartner[partnerArrIndex].push(company);
  //     }
  //   });
  //   let groupedCompanies = [];

  //   companiesByPartner.forEach(partnerArr => {
  //     groupedCompanies = [
  //       ...groupedCompanies,
  //       ...partnerArr.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)),
  //     ];
  //   });
  //   return groupedCompanies;
  // }

  // return sortedCompanies;
});

export const IsCompaniesEmpty = defaultMemoize(state =>
  isEmpty(get(state, 'Company.companies.byIds', {})),
);

export const getCompanyDataTables = defaultMemoize(state =>
  Object.values(state.Company?.companyDataTables || {}).map(e => ({
    ...e,
    label: e.question_label,
    value: e.id,
  })),
);

const getCurrentCompanyID = defaultMemoize(state =>
  get(state, 'Auth.account.accountId', ''),
);

export const CurrentCompany = createSelector(
  MyCompanies,
  getCurrentCompanyID,
  (companies, currentCompanyID) =>
    get(
      companies,
      `[${companies.findIndex(e => e.account_id === currentCompanyID)}]`,
      {},
    ),
);

export const CompaniesActionableDocuments = createSelector(MyCompanies, companies =>
  companies.reduce((dict, e) => {
    if (e.documents.length > 0) {
      e.documents.forEach(doc => {
        let included_states = [
          'document.sent',
          'document.viewed',
          'document.waiting_approval',
          'document.waiting_pay',
        ];

        if (included_states.includes(get(doc, 'status', '')) && doc.account_task_id) {
          dict.push({ account: e, ...doc });
        }
      });
    }
    return dict;
  }, []),
);

export const CompaniesDocuments = createSelector(
  MyCompanies,
  MyTransactions,
  (companies, transactions) => {
    let filteredDocs = [];
    companies.forEach(e => {
      if (e.documents.length > 0) {
        e.documents.forEach(doc => {
          let skip_states = [
            'document.sent',
            'document.viewed',
            'document.waiting_approval',
            'document.waiting_pay',
          ];

          if (skip_states.includes(get(doc, 'status', ''))) {
            filteredDocs = [...filteredDocs, { companyMeta: e, ...doc }];
          }
        });
      }
    });
    const documents = filteredDocs.reduce(
      (dict, e) => {
        let document = { ...e };
        let isRedlining = false;
        if (!e.account_task_id) {
          return dict;
        }
        transactions.some(transaction => {
          let taskIndex = transaction.tasks.findIndex(
            task => task.id + '' === e.savvi_task_id,
          );
          if (taskIndex !== -1) {
            isRedlining = e.revision_count > 0;
            document = {
              ...document,
              ...transaction,
              taskIndex,
            };
            return true;
          }
          return false;
        });
        if (isRedlining) {
          dict.editableDocs.push(document);
        } else {
          dict.missingSignatureDocs.push(document);
        }
        return dict;
      },
      { editableDocs: [], missingSignatureDocs: [] },
    );
    return documents;
  },
);
